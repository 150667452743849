import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const GridItem = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={className}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default GridItem;