import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmSignUp, resendSignUp, setShowAccountVerifyForm } from "../../redux/actions/authActions";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormHelperText, IconButton, InputAdornment, InputLabel, Link, TextField, Typography } from "@mui/material";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Close, Email, Lock } from "@mui/icons-material";
import OtpInput from "../../components/OtpInput/OtpInput";

const AccountVerifyModal = () => {
    const showAccountVerifyForm = useSelector(state => state.auth.showAccountVerifyForm);
    const accountVerifyData = useSelector(state => state.auth.accountVerifyData);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const verifyFormSchema = Yup.object({
        code: Yup.string().required("Code is required").min(6, "Invalid code").max(6, "Invalid code"),
        email: Yup.string().email("Invalid Email")
    });

    const { values, handleChange, handleBlur, handleSubmit, validateField, setFieldError, setFieldTouched, setFieldValue, errors, touched } = useFormik({
        initialValues: {
            code: '',
            email: accountVerifyData?.email ?? ''
        },
        enableReinitialize:true,
        validationSchema: verifyFormSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            let payload = {
                ...accountVerifyData,
                code: values.code,
                email: values.email
            }
            //console.log("confirmSignUp Payload:", payload)
            const success = await dispatch(confirmSignUp(payload));
            if (success) {
                resetForm();
            }
            setIsLoading(false);
        }
    });

    const sendCode = async () => {

        await setFieldTouched("email", true, true);
        if (!values?.email) {
            setFieldError("email", "Email is required");
            return;
        }
        if (values.email && !errors?.email) {
            dispatch(resendSignUp(values.email))
        }
    }

    const handleClose = () => {
        //dispatch(setShowAccountVerifyForm(false));
    }
    const closeModal = () => {
        dispatch(setShowAccountVerifyForm(false));
    }

    return (
        <>
            <Dialog onClose={handleClose} open={showAccountVerifyForm} fullWidth maxWidth="xs" PaperProps={{ sx: { width: '100%', m: 0, alignSelf: { xs: 'flex-end', md: 'center' }, borderRadius: 5 } }}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    {accountVerifyData?.type == "verify-signup" ?
                        <>
                            <Typography>Enter Verification Code</Typography>
                            <Typography variant="caption">Code was sent to your registered email</Typography>
                        </>
                        : accountVerifyData?.type == "verify-account" ?
                            <>
                                <Typography>Confirm Account</Typography>
                            </>
                            : null
                    }
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {accountVerifyData?.type == "verify-account" ?
                        <Box sx={{ mx: 'auto', minHeight: 75, display: 'flex' }}>
                            <TextField
                                label="Email"
                                variant="standard"
                                fullWidth
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                helperText={touched?.email && errors?.email}
                                error={touched?.email && errors?.email ? true : false}
                                autoComplete='off'
                            />
                            <Box sx={{ p: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={sendCode}
                                    disabled={isLoading || !values?.email || errors?.email ? true : false}
                                    sx={{ borderRadius: 2, minWidth: 120 }}
                                >
                                    Send Code
                                </Button>
                            </Box>
                        </Box>
                        : null
                    }
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        {/*
                        <TextField
                            label="Code"
                            variant="standard"
                            fullWidth
                            name="code"
                            onChange={handleChange}
                            value={values.code}
                            helperText={touched?.code && errors?.code}
                            error={touched?.code && errors?.code ? true : false}
                        //inputProps={{ style: { textAlign: 'center' }}}
                        />
                        */
                        }
                        <InputLabel >Enter the code receive on email</InputLabel>
                        <Box sx={{ py: 2, mb: 2 }}>
                            <OtpInput
                                value={values.code}
                                onChange={(code) => setFieldValue("code", code)}
                            />
                            <FormHelperText error={touched?.code && errors?.code ? true : false}>
                                {touched?.code && errors?.code}
                            </FormHelperText>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 1, mx: 'auto', display: 'flex', justifyContent: 'space-around' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading || !values.email || errors?.code ? true : false }
                            endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                            sx={{ borderRadius: 2, minWidth: 120 }}
                        >
                            Verify
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AccountVerifyModal;