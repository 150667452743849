import { TextField, Input, FormHelperText, Select, MenuItem, InputAdornment, CircularProgress, Icon } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Config } from "../../config/regions";



const PhoneNumberInput = ({
  label = "",
  value = "",
  name = "",
  variant = "standard",
  size = "medium",
  onChange = () => { },
  error = false,
  helperText = "",
  fullWidth = true,
  InputProps = {},
  readOnly = false,
  phoneInputProps = {} }) => {

  const [TextFieldComponent, setTextFieldComponent] = useState(null)
  const [CountryFieldComponent, setCountryFieldComponent] = useState(null)
  const [countrySelectOpen, setCountrySelectOpen] = React.useState(false);

  useEffect(() => {
    const CustomTextFieldInput = React.forwardRef((props, ref) => {

      return (
        <TextField
          {...props}
          inputRef={ref}
          size={size}
          label={label}
          variant={variant}
          name={name}
          fullWidth={fullWidth}
          InputProps={{
            ...props,
            ...InputProps,
            readOnly: readOnly ? readOnly : null
          }}
        />
      );
    });
    setTextFieldComponent(CustomTextFieldInput);

    const CustomCountryFieldInput = React.forwardRef((props, ref) => {
      //console.log(props);
      return (
        <>

          <Select
            inputRef={ref}
            size={size}
            variant={variant}

            onChange={(e) => props.onChange(e.target.value)}
            renderValue={() => ""}
            startAdornment={
              <InputAdornment position="start" onClick={() => !readOnly ? setCountrySelectOpen(true) : null}>
                {props.iconComponent({ country: props?.value, label: props?.value })}
              </InputAdornment>
            }
            name={props?.name}
            value={props?.value}
            onFocus={props?.onFocus}
            onBlur={props?.onBlur}
            disabled={props?.disabled}

            readOnly={readOnly ? readOnly : props?.readOnly}
            tabIndex={props?.tabIndex}
            className={props?.className}
            sx={{ alignSelf: 'stretch' }}
            open={countrySelectOpen}
            onOpen={() => setCountrySelectOpen(true)}
            onClose={() => setCountrySelectOpen(false)}
          >
            {props?.options?.length && props.options.map((option, idx) =>
              <MenuItem key={idx} value={option.value}>{option.label}</MenuItem>
            )}

          </Select>
        </>

      );
    });
    setCountryFieldComponent(CustomCountryFieldInput);

  }, [size, label, name, variant, fullWidth, countrySelectOpen])


  return (
    <>

      <FormControl fullWidth={fullWidth}>
        <div style={{ color: "black" }}>
          {TextFieldComponent &&
            <PhoneInput
              inputComponent={TextFieldComponent}
              countrySelectComponent={CountryFieldComponent}
              onChange={onChange}
              variant={variant}
              value={value}
              {...phoneInputProps}
              defaultCountry={Config.defaultCountryCode}
            />
          }
        </div>
        {helperText &&
          <FormHelperText
            error={error}
            variant={variant}
          >{helperText}
          </FormHelperText>
        }
      </FormControl>
    </>
  )

}

export default PhoneNumberInput;
