import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  bool,
  objectOf,
  any,
} from 'prop-types';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material'
import MicOffIcon from '@mui/icons-material/MicOff'
import PersonIcon from '@mui/icons-material/Person';

const Participant = forwardRef((props, itemRef) => {

  const { zoomSession, isLocalParticipant, participant, pictureInPicture } = props;

  const handleRenderVideo = (participant) => {
    try {
      if (participant?.bVideoOn) {
        zoomSession.renderVideo(document.querySelector(`#participant-canvas-${participant.userIdentity}`), participant.userId, 1920, 1080, 0, 0, 3)
      } else if (participant?.bVideoOn === false) {
        zoomSession.stopRenderVideo(document.querySelector(`#participant-canvas-${participant.userIdentity}`), participant.userId)
      }
    }
    catch (e) {
      console.log("handleRenderVideo : ", participant?.bVideoOn, e,);
    }
  }
  const handleShareView = (participant) => {
    try {
      if (participant?.sharerOn) {
        console.log("startShareView");
        let canvasElem = document.querySelector(`#participant-share-canvas-${participant.userIdentity}`);
        if (canvasElem) {
          document.querySelector(`#participant-share-card-${participant.userIdentity}`).style.display = 'block';
          zoomSession.startShareView(canvasElem, participant.userId)
        }
      } else if (participant?.sharerOn === false) {
        document.querySelector(`#participant-share-card-${participant.userIdentity}`).style.display = 'none';
        zoomSession.stopShareView()
      }
    }
    catch (e) {
      console.log("starhandleShareView : ", participant?.sharerOn, e,);
    }
  }

  useEffect(() => {
    if (!isLocalParticipant) {
      handleRenderVideo(participant);
      handleShareView(participant);
    }
  }, [participant]);

  const styles = {
    cardPictureinPicture: {
      position: 'absolute',
      width: 250,
      height: "auto",
      maxWidth: "30vw",
      maxHeight: "40vh",
      right: 0,
      top: 65,
      display: 'flex',
      flexDirection: 'column',
      borderWidth: 1,
      borderColor: '#555',
      zIndex: 101,
    },
    cardMainStream: {
      position: 'relative',
      maxHeight: '90vh',
      minWidth: 300,
      flex: 1,
      borderWidth: 1,
      borderColor: '#555',
      zIndex: 100,
    },
    cardScreenShare: {
      display: 'none',
      position: 'relative',
      maxHeight: '90vh',
      minWidth: '90vw',
      flex: 1,
      borderWidth: 1,
      borderColor: '#555',
      zIndex: 100,
      backgroundColor: 'black',
      order: -1 
    },
    participantName: {
      position: 'absolute',
      top: 0, zIndex: 201,
      textAlign: 'center',
      p: 1,
      width: '100%',
      backgroundColor: '#00000080',
      color: '#fff',
      fontSize: 14,
    },
    videoElement: {
      position: 'relative',
      zIndex: 200,
      minWidth: 150,
      width: '100%',
      height: 'auto'
    },
    canvasElement: {
      width: '100%',
      height: 'auto',
      maxHeight: '80vh'
      //aspectRatio: 16 / 9
    },
    shareCanvasElem: {
      width: '100%',
      height: 'auto',
      maxHeight: '80vh'
    },
    shareVideoElem: {
      width: '100%',
      height: 'auto',
      maxHeight: '80vh'
    }
  }

  return (
    <>
      <Card
        id={participant?.userIdentity}
        sx={[
          { borderRadius: 3, m: 2 },
          pictureInPicture || isLocalParticipant ? styles.cardPictureinPicture : styles.cardMainStream
        ]}
      >
        {
          participant?.userIdentity ?
            <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 0 auto', backgroundColor: 'black' }}>
              <Typography sx={styles.participantName}>
                {participant?.displayName}
              </Typography>
              <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <div>
                  {
                    !participant?.bVideoOn &&
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100, height: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <PersonIcon sx={{ color: '#555', height: "100%", width: '100%', maxWidth: 250, maxHeight: 250 }} />
                      </Box>
                    </Box>
                  }
                  {isLocalParticipant ?
                    <>
                      <video id="self-view-video" style={styles.videoElement} muted></video>
                      {/*<canvas id="self-view-canvas" width="1920" height="1080" style={styles.canvasElement}></canvas>*/}
                    </>
                    :
                    <>
                      {/*<video id={`participant-video-${participant.userIdentity}`} style={styles.videoElement}></video>*/}
                      {<canvas id={`participant-canvas-${participant.userIdentity}`} width={1920} height={1080} style={styles.canvasElement} ></canvas>}
                    </>
                  }
                </div>
              </Box>
              <Box sx={{ position: 'absolute', left: 0, top: 0, zIndex: 201 }}>
                {participant?.muted !== false &&
                  <Box sx={{ m: 1, color: "red" }}>
                    <MicOffIcon />
                  </Box>
                }
              </Box>
            </Box >
            :
            <Skeleton sx={{ m: 2, flex: '1 0 auto' }} />
        }
      </Card >
      <Card
        id={`participant-share-card-${participant.userIdentity}`}
        sx={[
          { borderRadius: 3, m: 2 },
          styles.cardScreenShare,
        ]}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 0 auto', backgroundColor: 'black' }}>
          <Typography sx={styles.participantName}>
            {participant?.displayName} is sharing
          </Typography>
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            {isLocalParticipant ?
              <video
                id={`participant-share-video-${participant.userIdentity}`}
                width={1920}
                height={1080}
                style={styles.shareVideoElem}
              >

              </video>
              :
              <canvas
                id={`participant-share-canvas-${participant.userIdentity}`}
                width={1920}
                height={1080}
                style={styles.shareCanvasElem}
              >
              </canvas>
            }
          </Box>
        </Box>
      </Card>
    </>
  )
});

Participant.propTypes = {
  isLocalParticipant: bool,
  participant: objectOf(any).isRequired,
  micOff: bool,
  videocamOff: bool,
}

export default Participant