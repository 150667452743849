import { Grid, Typography } from "@mui/material";
import GridItem from '../../components/Grid/GridItem';
const NotFound = () => {
  return (
    <Grid className="justify-center min-h-screen mt-32">
      <GridItem sx={{ textAlign: "center" }}>
        <Typography variant="h3" component="h3" className="text-3xl uppercase">Oops!</Typography>
        <Typography fontWeight={900} variant="h1" component="h1" className="text-7xl text-shadow" style={{ textShadow: "-8px 0px 0px #daa520" }}>
          404
        </Typography>
        <Typography variant="body1" component="div" className="text-sm uppercase" justifyContent="center" alignItems="center">
          Sorry, an error has occurred. The requested page was not found!
        </Typography>
      </GridItem>
    </Grid>
  )
}
export default NotFound; 