import { useEffect, useState } from "react";
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { LIVE_UPDATES_URL } from "../config/api";

const useSocket = (URL, CHANNEL) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        if (CHANNEL) {
            try {
                const sock = new SockJS(URL);
                const stompClient = Stomp.over(sock, { debug: false });

                // Connect to the WebSocket server
                stompClient.connect({}, (frame) => {
                    console.log('Connected to WebSocket server');
                    // Subscribe to the "chat" topic
                    stompClient.subscribe(CHANNEL, (message) => {
                        const newMessage = JSON.parse(message?.body);
                        setData(newMessage);
                    }, error => {
                        console.log('Subscribe: error: ' + error)
                    }, () => {
                        console.log('Subscribe, On complete')
                    });
                },
                    error => {
                        console.log('Websocket Connect: error: ' + error)
                    }
                );
                
                // Cleanup function to close the WebSocket connection
                return () => {
                    try {
                        stompClient.disconnect();
                        console.log('Disconnected from WebSocket server');
                    } catch (e) {
                        console.log(e);
                    }
                };
            } catch (e) {
                console.log(e);
            }
        }
    }, [CHANNEL]);


    return data
}

export default useSocket;