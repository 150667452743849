import queueImage from '../../../assets/images/landing/hero-5-img2.png';

const content = {
    "Health & Wellness": {
        title: "Health & wellness scheduling software to reduce admin work and grow your business",
        subTitle: "Streamline your center’s operations with wellness software that helps automate appointment scheduling, customer and therapist/doctor management, marketing, payments, and more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Fitness & Sports": {
        title: "Discover the easiest way to manage & grow your gym & fitness business",
        subTitle: "Quezone's fitness and gym scheduling software takes care of online booking, appointment reminders, trainer and facility management, marketing, payments, and more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Professional Services": {
        title: "Save time, win new clients, and accelerate business growth with Quezone",
        subTitle: "Eliminate scheduling back-and-forth, manage client data, boost employee productivity, accept online payments, and automate marketing with our professional services software",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Salon & Beauty": {
        title: "All-in-one salon scheduling software to grow and manage your salon business",
        subTitle: "Quezone's salon booking software saves you time by automating appointment scheduling, salon management, marketing, payments, and much more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Business Operations": {
        title: "Business Operations scheduling software to reduce admin work and grow your business",
        subTitle: "Streamline your center’s operations with wellness software that helps automate appointment scheduling, customer and therapist/doctor management, marketing, payments, and more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Spa": {
        title: "All-in-one spa scheduling software to manage and grow your spa business",
        subTitle: "The best spa software and app with easy spa management, online scheduling, appointment reminders, marketing, payments, customer management, and more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Education": {
        title: "Education scheduling software to reduce admin work, increase efficiency, and deliver better results",
        subTitle: "Save valuable teaching time with easy online appointments, class scheduling, student management, payments, automated reminders, and web conferencing!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Tutoring Services": {
        title: "#1 tutor scheduling software to run and grow your tutoring business",
        subTitle: "Free up time spent over calls/emails to schedule sessions and classes. Automate scheduling, reduce no shows, and run your tutoring business more efficiently",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Automotive": {
        title: "Automotive scheduling software to reduce admin work and grow your business",
        subTitle: "Streamline your center’s operations with wellness software that helps automate appointment scheduling, customer and therapist/doctor management, marketing, payments, and more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Pet Services": {
        title: "Provide quality services your clients trust & their pets love!",
        subTitle: "Pet booking software with scheduling automation, marketing features, and inbuilt CRM to simplify admin tasks and personalize pet care experiences",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Government & Public Sector": {
        title: "Automate daily operations to save time & serve citizens better with fast, frictionless experiences",
        subTitle: "Our appointment booking software helps public offices optimize their front desk as well as back-office processes which boosts employee productivity and citizen satisfaction",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },
    "Home Services & Cleaning": {
        title: "Avoid messy schedules & book more with Quezone!",
        subTitle: "Cleaning booking software that takes care of your business with scheduling automation, efficient cleaner management, advanced marketing suite, & much more!",
        titleImage: queueImage,
        sections: [
            {
                title: "title ",
                subTitle: "subTitle",
                content: ""
            }
        ]
    },

}

export default content;