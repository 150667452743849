
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import PrimaryLayout from "../../../layout/Layout"
import Room from "./Room"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { customerCheckin, fetchBookingDetails, fetchOrganizationById, fetchZoomAccessData } from "../../../redux/actions/apiActions";

import ZoomVideo from '@zoom/videosdk'
import { HeadsetMic } from "@mui/icons-material";
import { handleError } from "../../../redux/actions/commonActions";
import moment from "moment-timezone";

const ZoomVideoCall = () => {

  const [zoomVideo, setZoomVideo] = useState(null);
  const [zoomSession, setZoomSession] = useState(null);

  const [isRefreshing, setIsRefreshing] = useState(true);
  const orgDetails = useSelector(state => state.api.orgDetails);
  const bookingDetails = useSelector(state => state.api.bookingDetails);
  const zoomAccessData = useSelector(state => state.api.twilioAccessData);

  const dispatch = useDispatch();

  const { eventId, providerTimeStamp } = useParams();

  const joinRoom = async () => {
    if (!bookingDetails?.id) return;
    try {
      setIsRefreshing(true);
      console.log("join Room");
      if (zoomVideo && zoomAccessData && zoomAccessData.accessToken && zoomAccessData.sessionName) {
        const { id, customerGivenName, providerName, customerId, providerId } = bookingDetails;
        let { accessToken, sessionName, sessionKey, userId, userRole } = zoomAccessData;

        await zoomVideo.init('en-US', 'Global', { patchJsMedia: true })
        await zoomVideo.join(sessionName, accessToken, userRole ? providerName : customerGivenName);
        const zoomSession_ = zoomVideo.getMediaStream();
        setZoomSession(zoomSession_);

        if (bookingDetails?.status == "UNSPECIFIED" && moment().unix() > (bookingDetails?.startSec - 3600) && !providerTimeStamp) {
          dispatch(customerCheckin(bookingDetails?.id, bookingDetails?.customerId))
        }
      }
      setIsRefreshing(false)
    } catch (err) {
      console.log(zoomAccessData)
      console.log(err)
      if (err?.reason) {
        err['reason'] = `[${err?.type ? err?.type + '],  ' : "Something went wrong!, "} ${err['reason']}`;
      }
      dispatch(handleError(err));
      setZoomSession(null);
      setIsRefreshing(false);
    }
  }

  const onReturnToLobby = () => {
    setZoomSession(null);
  }

  useEffect(() => {
    if (zoomAccessData && bookingDetails?.id && !zoomSession) {
      //joinRoom();
    }
  }, [bookingDetails, zoomAccessData])

  useEffect(() => {

    //fetch organization details
    if (bookingDetails?.organizationId && bookingDetails?.organizationId !== orgDetails?.id) {
      dispatch(fetchOrganizationById(bookingDetails?.organizationId));
    }
    //get twillio access data 
    if (bookingDetails?.id) {
      const { id, customerGivenName, providerName, customerId, providerId } = bookingDetails;
      const isProvider_ = providerTimeStamp ? true : false;
      //let userId = isProvider_ ? providerName + ";;" + providerId : (customerGivenName ?? "") + ";;" + customerId;
      let userId = isProvider_ ? providerId : customerId;
      let payload = { eventId: id, userId: userId };
      dispatch(fetchZoomAccessData(payload));
    }
  }, [bookingDetails]);


  useEffect(() => {
    if (eventId) {
      dispatch(fetchBookingDetails(eventId));
    }
    const zoomVideo = ZoomVideo.createClient();
    setZoomVideo(zoomVideo);

    setTimeout(() => setIsRefreshing(false), 1000);
  }, [])


  return (
    <PrimaryLayout>
      <Box>
        {!zoomSession ?
          <Box display="flex" justifyContent="center" sx={{ mt: '15%' }}>
            <Button
              variant="contained"
              color='primary'
              onClick={joinRoom}
              disabled={!zoomAccessData || !bookingDetails?.id ? true : false}
              endIcon={isRefreshing ? <CircularProgress size={20} color="inherit" /> : <HeadsetMic />}
            >
              Join Call
            </Button>
          </Box>
          :
          <Room
            returnToLobby={onReturnToLobby}
            zoomVideo={zoomVideo}
            zoomSession={zoomSession}
          />
        }
      </Box>
    </PrimaryLayout>
  )
}
export default ZoomVideoCall;