
import { Avatar, Button, Card, CardActions, CardContent, Chip, Typography, styled, Box } from "@mui/material"
import { useEffect, useState } from "react";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAvailabilitiesByScheduleId } from "../../redux/actions/apiActions";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import SlotPicker from "../../components/SlotPicker/SlotPicker";
import { DoubleArrow, LocationOn, LocationOnOutlined, Public, VideoCall } from "@mui/icons-material";
import { defaultProviderImageUrl, defaultServiceImageUrl } from "../../config/constants";
import { setAssesmentData, setSelectedAvailability, setShowUserForm } from "../../redux/actions/commonActions";
import LocationModal from "../../components/LocationModal/LocationModal";
import CustomFixedButton from "../../components/CustomButtons/CustomFixedButton";
import SurveyModal from "../../components/SurveyModal/SurveyModal";
import moment from "moment-timezone";
import { formatPrice } from "../../utils/misc";
import RatingsAndReviews from "./RatingsAndReviews";


const RibbonContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "green",
    position: "absolute",
    color: theme.palette.primary.contrastText,
    width: 180,
    zIndex: 3,
    textAlign: "center",
    padding: 3,
    "&::before": {
        position: "absolute",
        zIndex: -1,
        display: "block",
        border: "5px solid #2980b9",
    },
    "&::after": {
        position: "absolute",
        zIndex: -1,
        display: "block",
        border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 35,
    marginLeft: -40,
}));
const RibbonText = styled(Typography)(({ theme }) => ({
    fontSize: 16
}));

const ScheduleCard = ({ serviceData, scheduleData, providerList, isLoading }) => {

    const [selectedSlot, setSelectedSlot] = useState(null);
    const availabilities = useSelector(state => state.api.availabilities[scheduleData.id]);
    const availabilitiesLoading = useSelector(state => state.api.availabilitiesLoading[scheduleData.id]);
    const [isCounterTypeService, setIsisCounterTypeService] = useState(false);
    const [providerData, setProviderData] = useState({});

    const dispatch = useDispatch();

    const [showLocation, setShowLocation] = useState(false);
    const [showAdditionalInfoModal, setShowAdditionalInfoModal] = useState(false);
    const [locationCoords, setLocationCoords] = useState(null);

    const openLocationModal = () => {
        if (scheduleData?.geoLocation?.coordinates) {
            let coordinates = scheduleData?.geoLocation?.coordinates;
            if (coordinates?.latitude && coordinates?.longitude) {
                setShowLocation(true);
                setLocationCoords(coordinates);
            }
        }
    }

    const onCloseLocationModal = () => {
        setShowLocation(false);
        setLocationCoords(null);
    }

    const onSelectSlot = (slot) => {
        setSelectedSlot(prev => prev?.startSec !== slot?.startSec ? slot : null)
    }
    const onJoinQueue = (slot) => {
        setSelectedSlot(prev => prev?.startSec !== slot?.startSec ? slot : null)
        onClickSlotConfirm(slot);
    }

    const onClickSlotConfirm = (slot) => {
        const selectedSlot_ = slot ?? selectedSlot;
        const data = { scheduleData, selectedSlot: selectedSlot_ }
        dispatch(setSelectedAvailability(selectedSlot_));
        if (scheduleData?.surveyId) {
            setShowAdditionalInfoModal(true);
        } else {
            dispatch(setShowUserForm(data));
            setSelectedSlot(null);
        }
    }

    const onCompleteAdditionalInfo = (surveyData) => {
        if (surveyData.data) {
            setShowAdditionalInfoModal(false);
            let assesmentData = {
                eventId: null,
                participantId: null,
                questionAnswers: JSON.stringify(surveyData.data),
                status: "COMPLETED",
                surveyId: scheduleData.surveyId
            }
            dispatch(setAssesmentData(assesmentData));
            const data = { scheduleData, selectedSlot }
            dispatch(setShowUserForm(data));
            setSelectedSlot(null);
        }
    }

    useEffect(() => {
        if (scheduleData?.id) {
            dispatch(fetchAvailabilitiesByScheduleId(scheduleData?.id))
        }
    }, [scheduleData])

    useEffect(() => {
        if (scheduleData?.providerId && providerList?.length) {
            let providerData_ = providerList.find(provider => provider.id === scheduleData?.providerId);
            setProviderData(providerData_);
        }
    }, [scheduleData, providerList])

    useEffect(() => {
        if (serviceData?.id) {
            let isCounterTypeService_ = serviceData?.counters && serviceData?.counters?.length > 0 ? true : false;
            setIsisCounterTypeService(isCounterTypeService_);
        }
    }, [serviceData])

    useEffect(() => {
        if (serviceData?.mode === 'CALLBACK') {
            /* Load survey/Form for taking Callback Contact details*/
        }
    }, [availabilities])


    return (
        <>
            {isLoading ?
                <SkeletonCard cardType="schedule" />
                :
                <Card sx={{ borderRadius: 5, position: 'relative' }}>
                    {scheduleData?.payment && scheduleData?.payment?.amount ?
                        <RibbonContainer sx={{ boxShadow: 1 }}>
                            <RibbonText>{formatPrice(scheduleData?.payment?.amount ?? 0, scheduleData?.payment?.currency ?? "")} /-</RibbonText>
                        </RibbonContainer>
                        : null
                    }
                    <CardContent>
                        <GridContainer alignItems='flex-start'>
                            <GridItem xs={12} md={4} >
                                <div className="justify-center py-5">
                                    {isCounterTypeService ?
                                        <>
                                            <Avatar
                                                sx={{ width: 100, height: 100, '& img': { objectFit: 'contain'}}}
                                                alt={""}
                                                src={serviceData?.image?.fileUrl ? serviceData.image.fileUrl : defaultServiceImageUrl}
                                                className="mx-auto"
                                            />
                                            <p className="text-center whitespace-pre-wrap mx-auto mt-3">
                                                {`${serviceData?.name}`}
                                            </p>
                                            {serviceData?.description ?
                                                <p className="text-center whitespace-pre-wrap mx-auto mt-1">
                                                    {`${serviceData?.description}`}
                                                </p>
                                                : null}

                                        </>
                                        :
                                        <>
                                            <Avatar
                                                sx={{ width: 100, height: 100, '& img': { objectFit: 'contain'}}}
                                                alt={""}
                                                src={providerData?.providerInformation?.image?.fileUrl ? providerData.providerInformation?.image.fileUrl : defaultProviderImageUrl}
                                                className="mx-auto mb-3"
                                            />
                                            <p className="text-center whitespace-pre-wrap mx-auto ">{`${providerData?.designation ?? ''} ${providerData?.givenName ?? ''} ${providerData?.familyName ?? ''}`}</p>
                                            {providerData?.providerInformation?.qualifications ?
                                                <p className="text-center whitespace-pre-wrap mx-auto text-sm font-light" >{`${providerData?.providerInformation?.qualifications}`}</p>
                                                : null
                                            }
                                            {providerData?.providerInformation?.description ?
                                                <p className="text-center whitespace-pre-wrap mx-auto mt-1 text-sm font-extralight">
                                                    {`${providerData?.providerInformation?.description}`}
                                                </p>
                                                : null}
                                            {serviceData?.enableReviews &&
                                                <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mx: 'auto' }}>
                                                    <RatingsAndReviews type="provider" details={providerData} />
                                                </Box>
                                            }
                                        </>
                                    }
                                    <div className="text-center mx-auto mt-5">
                                        {scheduleData?.description && scheduleData?.description.includes("TAGS;") && scheduleData?.description?.split(';')?.length ?
                                            scheduleData?.description?.split(';').map((tag, i) => i > 0 && tag.trim() &&
                                                <div style={{ padding: 3 }} className="text-center mx-auto" key={i}>
                                                    <Chip color={"info"} variant="outlined" label={tag} />
                                                </div>
                                            )
                                            : null}
                                    </div>
                                    {scheduleData?.isOnline &&
                                        <div className="text-center mx-auto mt-1">
                                            <Button sx={{ textTransform: "none" }} startIcon={<VideoCall />}>
                                                {scheduleData?.onlineMeetingProvider == "Quezone" ? "Online Meeting" 
                                                : scheduleData?.onlineMeetingProvider == "MsTeams" ? "MS Teams" 
                                                : scheduleData?.onlineMeetingProvider == "Skype" ? "Skype" 
                                                :'Online Meeting'
                                              }
                                            </Button>
                                        </div>
                                    }
                                    <div className="text-center mx-auto mt-3">
                                        <Button sx={{ textTransform: "none" }} onClick={openLocationModal}>
                                            <LocationOn fontSize="small" /><p>{` ${scheduleData?.geoLocation?.fullAddress}`}</p>
                                        </Button>
                                    </div>
                                    <div className="text-center mx-auto">
                                        <Button sx={{ textTransform: "none" }}>
                                            <Public fontSize="small" /><p>{` ${scheduleData.timezoneId}`}{(moment.tz(scheduleData?.timezoneId).zoneAbbr() && false ? " - " + moment.tz(scheduleData?.timezoneId).zoneAbbr() : '')}</p>
                                        </Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8} className="pt-10">
                                {
                                    availabilitiesLoading || false ?
                                        <SkeletonCard cardType="availabilities" />
                                        :
                                        <>
                                            <SlotPicker
                                                availabilities={availabilities}
                                                onSelectSlot={onSelectSlot}
                                                onJoinQueue={onJoinQueue}
                                                selectedSlot={selectedSlot}
                                                timezoneId={scheduleData?.timezoneId}
                                                mode={serviceData?.mode}
                                            />
                                        </>
                                }
                            </GridItem>
                        </GridContainer>
                    </CardContent>
                    <CardActions disableSpacing>
                        {selectedSlot?.startSec && serviceData?.mode !== "QUEUE" ?
                            <GridContainer justifyContent="center">
                                <CustomFixedButton variant="contained" color="primary" style={{ borderRadius: 5, width: '100%' }} onClick={() => onClickSlotConfirm(selectedSlot)} endIcon={<DoubleArrow />}>
                                    Confirm Booking
                                </CustomFixedButton>
                            </GridContainer>
                            : null
                        }
                    </CardActions>
                </Card>
            }
            <LocationModal
                show={showLocation}
                onClose={onCloseLocationModal}
                coordinates={locationCoords}
                title={<> <LocationOnOutlined /> {scheduleData?.geoLocation?.fullAddress ?? ''}</>}
            />
            <SurveyModal
                show={showAdditionalInfoModal}
                onClose={() => setShowAdditionalInfoModal(false)}
                title="Provide Additional Information"
                surveyId={scheduleData?.surveyId}
                completeText="PROCEED TO BOOKING"
                onComplete={onCompleteAdditionalInfo}
            />
        </>
    )
}
export default ScheduleCard