import {
    createStore, applyMiddleware, compose, combineReducers,
  } from 'redux';
  import thunk from 'redux-thunk';
  import { persistStore } from 'redux-persist';
  import auth from './reducers/authReducers';
  import api from './reducers/apiReducers';
  import theme from './reducers/themeReducers';
  import common from './reducers/commonReducers';

  
  const rootReducer = combineReducers({
    auth,
    api,
    theme,
    common   
  });
  
  const appReducer = (state, action) => {
    let newState = state;
    if (action.type === 'RESET_APP') {
      newState = undefined;
    }
    return rootReducer(newState, action);
  };
  
  let middleWare = [];
  if (process.env.NODE_ENV === 'production') {
    middleWare = [
      ...middleWare,
      thunk,
    ];
  } else {
    middleWare = [
      ...middleWare,
      thunk,
    ];
  }
  
  const store = createStore(
    appReducer,
    compose(
      applyMiddleware(...middleWare),
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    ),
  );
  const persistor = persistStore(store);
  
  export default { store, persistor };