import Landing from "../pages/Landing/Landing";
import OrganizationList from "../pages/OrganizationList/OrganizationList";
import Organization from "../pages/Organization/Organization";
import ServiceList from "../pages/ServiceList/ServiceList";
import ProductList from "../pages/ProductList/ProductList";
import Service from "../pages/Service/Service";
import Product from "../pages/Product/Product";
import BookingDetails from "../pages/BookingDetails/BookingDetails";
import AssessmentFeedback from "../pages/SurveyForms/AssessmentFeedback";
import TermsCondition from "../pages/TermsAndCondition/TermsCondition";
import NotFound from "../pages/CommonPage/NotFound";
import BookingHistory from "../pages/BookingHistory/BookingHistory";
import TwilioVideoCall from "../pages/VideoCall/Twilio/TwilioWebRTCview";
import ZoomVideoCall from "../pages/VideoCall/Zoom/ZoomWebRTCview";
import Profile from "../pages/Profile/Profile";
import SegmentPage from "../pages/Landing/SubPages/SegmentPages";
import HelpDesk from "../pages/HelpDesk/HelpDesk";


const rootRoutes = [

    {
        exact: true,
        strict: true,
        path: '/',
        name: 'Landing Page',
        element: <Landing />,
    },
    {
        exact: true,
        strict: true,
        path: '/p/organizations',
        name: 'Organizations Page',
        element: <OrganizationList />,
    },
    {
        exact: true,
        strict: true,
        path: '/:orgRef',
        name: 'Organization Page',
        element: <Organization />,
    },
    {
        exact: true,
        strict: true,
        path: '/p/services/:orgRef',
        name: 'Services Page',
        element: <ServiceList />,
    },
    {
        exact: true,
        strict: true,
        path: '/:orgRef/booking/services/:serviceId',
        name: 'Service Page',
        element: <Service />,
    },
    {
        exact: true,
        strict: true,
        path: '/:orgRef/booking/waitlist/instant/:scheduleId',
        name: 'Service Page',
        element: <Service />,
    },
    {
        exact: true,
        strict: true,
        path: '/:orgRef/booking/instant/:scheduleId',
        name: 'Service Page',
        element: <Service />,
    },
    {
        exact: true,
        strict: true,
        path: '/p/service/:orgRef/:serviceId',
        name: 'Service Page',
        element: <Service />,
    },
    {
        exact: true,
        strict: true,
        path: '/p/products/:orgRef',
        name: 'Products Page',
        element: <ProductList />,
    },
    {
        exact: true,
        strict: true,
        path: '/p/product/:orgRef/:productId',
        name: 'Product Page',
        element: <Product />,
    },
    {
        exact: true,
        strict: true,
        path: '/event/:eventId',
        name: 'Booking Details Page',
        element: <BookingDetails />,
    },
    {
        exact: true,
        strict: true,
        path: '/:orgRef/event/:eventId',
        name: 'Booking Details Page',
        element: <BookingDetails />,
    },
    {
        exact: true,
        strict: true,
        path: '/p/bookings',
        name: 'Booking History',
        element: <BookingHistory />,
    },
    {
        exact: true,
        strict: true,
        path: '/videocall/:eventId',
        name: 'Video Call Provider',
        element: <ZoomVideoCall />,
    },
    {
        exact: true,
        strict: true,
        path: '/videocall/:eventId/provider/:providerTimeStamp',
        name: 'Video Call',
        element: <ZoomVideoCall />,
    },
    {
        exact: true,
        strict: true,
        path: '/survey/event/:eventId',
        name: 'Assessment Form Incomplete',
        element: <AssessmentFeedback />,
    },
    {
        exact: true,
        strict: true,
        path: '/feedback/:feedbackId/event/:eventId',
        name: 'Feedback Form',
        element: <AssessmentFeedback />,
    },
    {
        exact: true,
        strict: true,
        path: '/termsandconditions',
        name: 'Terms & Conditions Page',
        element: <TermsCondition />,
    },
    {
        exact: true,
        strict: true,
        path: '*',
        name: 'notfoundPage',
        element: <NotFound/>,        
       
    },
    {
        exact: true,
        strict: true,
        path: '/p/profile',
        name: 'ProfilePage',
        element: <Profile/>,
    },
    {
        exact: true,
        strict: true,
        path: '/p/help-desk',
        name: 'HelpDeskPage',
        element: <HelpDesk/>,
    },
    {
        exact: true,
        strict: true,
        path: '/s/:segment',
        name: 'SegmentPage',
        element: <SegmentPage/>,
    }
]
export default rootRoutes;