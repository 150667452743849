import { Box, Card, IconButton, Tooltip } from "@mui/material"
import { useEffect, useState } from "react";
import CallEndIcon from '@mui/icons-material/CallEnd'
import MicIcon from '@mui/icons-material/Mic'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import MicOffIcon from '@mui/icons-material/MicOff'
import { useDispatch } from "react-redux";
import { showAlert } from "../../../redux/actions/commonActions";

const ActionBar = ({ zoomVideo, zoomSession, onLeave = () => { } }) => {

    const dispatch = useDispatch();

    const [mute, setMute] = useState(false);
    const [videocamOff, setVideocamOff] = useState(true);
    const [shareOn, setShareOn] = useState(false);
    const [startedAudio, setStartedAudio] = useState(false);
    const [startedVideo, setStartedVideo] = useState(false);
    const [startedShare, setStartedShare] = useState(false);

    const leaveRoom = () => {
        zoomVideo.leave();
        onLeave();
    }

    const startVideo = async () => {
        try {
            if (zoomSession.isRenderSelfViewWithVideoElement() && false) {
                await zoomSession.startVideo({ videoElement: document.getElementById('self-view-video'), originalRatio: true });
            } else {
                await zoomSession.startVideo({ videoElement: document.getElementById('self-view-video'), originalRatio: true });
                //await zoomSession.renderVideo(document.getElementById('self-view-canvas'), zoomVideo.getCurrentUserInfo().userId, 1920, 1080, 0, 500, 2);
            }
            setStartedVideo(true);
        } catch (e) {
            console.log("Start Video Error!!", e);
            if(e?.reason){
                dispatch(showAlert("error", e?.reason))
            }
            setVideocamOff(true);
        }
    }

    const startShare = async () => {
        try {
            const participant = zoomVideo.getCurrentUserInfo();
            if (zoomSession.isStartShareScreenWithVideoElement() && false) {
                await zoomSession.startShareScreen(document.querySelector(`#participant-share-video-${participant.userIdentity}`))
                document.querySelector(`#participant-share-card-${participant.userIdentity}`).style.display = 'block';
            } else {
                await zoomSession.startShareScreen(document.querySelector(`#participant-share-video-${participant.userIdentity}`))
                document.querySelector(`#participant-share-card-${participant.userIdentity}`).style.display = 'block';
            }
            setStartedShare(true);
        } catch (e) {
            console.log("Start Share Error!!", e);
            if(e?.reason){
                dispatch(showAlert("error", e?.reason))
            }
            setShareOn(false);
        }
    }
    const stopShare = async () => {
        zoomSession.stopShareScreen();
        document.querySelector(`#participant-share-card-${zoomVideo.getCurrentUserInfo().userIdentity}`).style.display = 'none';
    }

    // Handle Mic on/ Off 
    useEffect(() => {
        try {
            if (zoomSession) {
                console.log('Changing Mute from : ', mute);
                if (mute) {
                    if (startedAudio) {
                        zoomSession.muteAudio();
                    }
                } else {
                    if (!startedAudio) {
                        zoomSession.startAudio();
                        setStartedAudio(true);
                    } else {
                        zoomSession.unmuteAudio();
                    }
                }
            }
        } catch (e) {
            console.log("Mic toggle Error!!", e);
            if(e?.reason){
                dispatch(showAlert("error", e?.reason))
            }
            setMute(prev => !prev);
        }
    }, [mute, zoomSession]);

    //Handle Cam on/off
    useEffect(() => {
        try {
            if (zoomSession) {
                console.log('Changing Video from : ', videocamOff);
                if (videocamOff) {
                    if (startedVideo) {
                        zoomSession.stopVideo()
                    }
                } else {
                    startVideo()
                }
            }
        } catch (e) {
            console.log("Cam toggle Error!!", e);
            if(e?.reason){
                dispatch(showAlert("error", e?.reason))
            }
            setVideocamOff(prev => !prev);
        }
    }, [videocamOff, zoomSession]);

    //Handle Share on/off
    useEffect(() => {
        try {
            if (zoomSession) {
                console.log('Changing Share from : ', videocamOff);
                if (shareOn) {
                    startShare();
                } else {
                    if (startedShare) {
                        stopShare();
                    }
                }
            }
        } catch (e) {
            console.log("Share toggle Error!!", e);
            if(e?.reason){
                dispatch(showAlert("error", e?.reason))
            }
            setShareOn(prev => !prev);
        }
    }, [shareOn, zoomSession]);


    useEffect(() => {
        if (zoomVideo) {
            zoomVideo.on('passively-stop-share', (payload) => {
                console.log('passively-stop-share (action)', payload);
                setShareOn(false);
            })
        }
    }, [zoomVideo])



    return (
        <Box sx={{ position: 'fixed', bottom: 5, left: 0, right: 0, mx: 'auto', maxWidth: 'sm', zIndex: 500, }}>
            <Card sx={{ display: 'flex', p: 1, px: 3, justifyContent: 'space-around', m: 1, borderRadius: 5, }}>
                <Tooltip title={mute ? 'Unmute microphone' : 'Mute microphone'} >
                    <IconButton onClick={() => setMute(prev => !prev)} size="large"  >
                        {mute ? <MicOffIcon /> : <MicIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={videocamOff ? 'Enable videocam' : 'Disable videocam'} >
                    <IconButton onClick={() => setVideocamOff(prev => !prev)} size="large" >
                        {videocamOff ? <VideocamOffIcon /> : <VideocamIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={shareOn ? 'Disable screen share' : 'Enable screen share'} >
                    <IconButton onClick={() => setShareOn(prev => !prev)} size="large" >
                        {shareOn ? <ScreenShareIcon /> : <StopScreenShareIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title='Leave call'>
                    <IconButton id="leaveRoom" onClick={leaveRoom} size="large" color="error" variant="contained" >
                        <CallEndIcon />
                    </IconButton>
                </Tooltip>
            </Card>
        </Box>
    )
}

export default ActionBar;