import { Box, Card, Chip, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageCarousel from "../../components/Carousel/ImageCarousel";
import { defaultServiceImageUrl } from "../../config/constants";
import RatingsAndReviews from "./RatingsAndReviews";

const ServiceCard = ({ isLoading = false }) => {

    const serviceDetails = useSelector(state => state.api.serviceDetails);
    const serviceDetailsLoading = useSelector(state => state.api.serviceDetailsLoading);

    const [serviceImages, setServiceImages] = useState([]);
    const [serviceVideoUrls, setServiceVideoUrls] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (serviceDetails?.id) {
            let serviceImages_ = [];
            serviceImages_ = serviceDetails?.image ? [serviceDetails.image] : [];
            serviceImages_ = serviceDetails?.displayImages?.length ?
                [...serviceImages_, ...serviceDetails?.displayImages]
                : serviceImages_;
            serviceImages_ = serviceImages_.filter(item => item?.fileUrl) //fiter images with valid url
            setServiceImages(serviceImages_.length ? serviceImages_ : [{ fileUrl: defaultServiceImageUrl }]);
            console.log("serviceImages_", serviceImages_)

            let serviceVideoUrls_ = serviceDetails?.videoUrl ? [serviceDetails?.videoUrl] : [];
            setServiceVideoUrls(serviceVideoUrls_);

        }
    }, [serviceDetails])

    useEffect(() => {
        setShowLoader(serviceDetailsLoading || isLoading);
    }, [serviceDetailsLoading, isLoading])

    return (
        <>
            {
                showLoader ?
                    <>
                        <Card sx={{ borderRadius: { xs: 0, md: 5 }, mt: { xs: 0, md: 9.5 }, position: 'relative', width: '100%' }}>
                            <Skeleton variant="rounded" animation="wave" sx={{ maxWidth: "100%", height: 200 }} />
                            <Box sx={{ p: 2, }}>
                                <Skeleton variant="text" animation="wave" sx={{ maxWidth: 250, height: 30 }} />
                                <Box sx={{}}>
                                    <Skeleton variant="text" animation="wave" />
                                    <Skeleton variant="text" animation="wave" sx={{ maxWidth: 200 }} />
                                </Box>
                                <Skeleton variant="text" animation="wave" sx={{ maxWidth: 150, height: 40 }} />
                            </Box>
                        </Card>
                    </>

                    : serviceDetails ?
                        <>
                            <Card sx={{ borderRadius: { xs: 0, md: 5 }, mt: { xs: 0, md: 9.5 }, position: 'relative', width: '100%' }}>
                                <ImageCarousel
                                    images={[...serviceImages.map((item, index) => item?.fileUrl)]}
                                    imageStyle={{ maxHeight: '200px', objectFit: 'contain' }}
                                    videos={serviceVideoUrls}
                                />
                                <Box sx={{ p: 2, mb: 1 }}>
                                    <Typography variant="h6">{serviceDetails.name}</Typography>
                                    <Box sx={{ pb: 1 }}>
                                        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>{serviceDetails.description}</Typography>
                                    </Box>
                                    <Box sx={{ pb: 2 }}>
                                        <Chip
                                            label={`Average Session : ${serviceDetails.duration} minutes`}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                    {serviceDetails?.enableReviews && 
                                        <RatingsAndReviews type="service" details={serviceDetails} />
                                    }
                                </Box>
                            </Card>
                        </>
                        : null
            }
        </>

    )
}

export default ServiceCard;



