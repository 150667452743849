import { Box, TextField } from "@mui/material";
import ReactOtpInput from 'react-otp-input';

const OtpInput = ({ value, onChange = () => { } }) => {

    return (
        <ReactOtpInput
            value={value}
            onChange={onChange}
            numInputs={6}
            renderSeparator={<Box sx={{ p: 1 }} />}
            renderInput={(props) =>
                <TextField
                    variant="outlined"
                    inputProps={{ ...props, style: { padding: 0, textAlign: 'center', }, type:"tel", inputMode: 'numeric', pattern: '[0-9]*' }}
                    InputProps={{ sx: { fontWeight: 'bold', py: 2, fontSize: 20, borderRadius: 3, maxWidth: 80 } }}
                />}
            containerStyle={{ justifyContent: 'center' }}
            inputType="number"
            shouldAutoFocus={true}
        />
    );

}

export default OtpInput;