import React, { useState, useEffect, useRef } from 'react'
import {
  func,
  objectOf,
  any,
} from 'prop-types'
import {
  Box
} from '@mui/material'

import { useDispatch } from 'react-redux'
import { showAlert } from '../../../redux/actions/commonActions'
import ActionBar from './ActionBar';
import ServiceInfo from './ServiceInfo';
import Participant from './Participant'

const Room = (props) => {

  const { zoomVideo, zoomSession, returnToLobby } = props;
  const [remoteParticipants, setRmoteParticipants] = useState([]);

  const dispatch = useDispatch();

  const refreshAllParticipantData = () => {
    const participants = zoomVideo.getAllUser();
    setRmoteParticipants(participants);
  }


  useEffect(() => {
    if (zoomVideo) {
      /* Listeners */
      zoomVideo.on('user-added', (payload) => {
        // user joined
        console.log('user-added', payload);
        dispatch(showAlert("info", `${payload.map(item => item.displayName).join()} has joined the room.`, { sx: { mb: 7 } }, { sx: { borderRadius: 3 } }));
        refreshAllParticipantData();
      })

      zoomVideo.on('user-updated', (payload) => {
        // user updated, like unmuting and muting
        console.log('user-updated', payload);
        refreshAllParticipantData();
      })

      zoomVideo.on('user-removed', (payload) => {
        // user left
        console.log('user-removed', payload);
        dispatch(showAlert("info", `${payload.map(item => item.displayName).join()} has left the room.`, { sx: { mb: 7 } }, { sx: { borderRadius: 3 } }));
        refreshAllParticipantData();
      })

      zoomVideo.on('connection-change', (payload) => {
        // session ended by host
        console.log('connection-change', payload);
      })

      zoomVideo.on('peer-video-state-change', (payload) => {
        console.log('peer-video-state-changee', payload);
        refreshAllParticipantData();
        /*
        if (payload.action === 'Start') {
          // a user turned on their video, render it
          zoomSession.renderVideo(document.querySelector(`#participant-canvas-${payload.userIdentity}`), payload.userId, 1920, 1080, 0, 0, 3)
        } else if (payload.action === 'Stop') {
          // a user turned off their video, stop rendering it
          zoomSession.stopRenderVideo(document.querySelector(`#participant-canvas-${payload.userIdentity}`), payload.userId)

        }
        */
      })

      zoomVideo.on('passively-stop-share', (payload) => {
        console.log('passively-stop-share', payload);
        refreshAllParticipantData();
      });

      zoomVideo.on('active-share-change', (payload) => {
        console.log('active-share-change', payload);
        refreshAllParticipantData();
        /*
        if (payload.state === 'Active') {
          stream.startShareView(document.querySelector(`#participant-share-canvas-${payload.userId}', payload.userId)
        } else if (payload.state === 'Inactive') {
          stream.stopShareView()
        }
        */
      })
    }
  }, [zoomVideo]);

  useEffect(() => {
    if (zoomSession) {
      /* Stream already joined user's video */
      const participants = zoomVideo.getAllUser();
      setRmoteParticipants(participants);
      /*
      setTimeout(() => {
        participants.forEach((user) => {
          if (user.bVideoOn) {
            zoomSession.renderVideo(document.querySelector(`#participant-canvas-${user.userIdentity}`), user.userId, 1920, 1080, 0, 0, 3)
          }
        })
      }, 1000,);
      */
      //zoomSession.startAudio();
    }
  }, [zoomSession])

  useEffect(() => {
    console.log("remote participants changed", remoteParticipants)
    console.log("zoomSession", zoomSession)
  }, [remoteParticipants])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box direction='column' justifyContent='space-between' alignItems='center'>
        <Box justifyContent='center' alignItems='center' style={{ maxHeight: '80vh' }} >
          <Box style={{ display: "flex", flexWrap: 'wrap' }} >
            {remoteParticipants.map((participant, index) =>
              <Participant
                zoomSession={zoomSession}
                participant={participant}
                isLocalParticipant={zoomVideo.getCurrentUserInfo().userIdentity == participant.userIdentity ? true : false}
                key={index}
              />
            )
            }
            {
              (remoteParticipants.length <= 1) &&
              <ServiceInfo />
            }
          </Box>
        </Box>
        <ActionBar zoomVideo={zoomVideo} zoomSession={zoomSession} onLeave={returnToLobby} />
      </Box>
    </Box>
  )
}

Room.propTypes = {
  zoomVideo: objectOf(any).isRequired,
  returnToLobby: func.isRequired,
}

export default Room 