import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
    SET_THEME_MODE
} from '../actions/themeActions';

const initState = {
    mode:null
};
const persistConfig = {
    key: 'theme',
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    blacklist: [],
};
const reducer = (state = initState, action) => {
    switch (action.type) {
        case SET_THEME_MODE:
            return {
                ...state,
                mode: action.payload,
            };
        default:
            return state;
    }
};

export default persistReducer(persistConfig, reducer);