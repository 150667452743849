import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid, TextField, Card, Button, Box, CircularProgress, FormHelperText } from '@mui/material';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import PrimaryLayout from "../../layout/Layout";
import * as Yup from 'yup';
import { updateProfile } from "../../redux/actions/apiActions";
import { useEffect, useState } from "react";
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput';


const Profile = () => {
  const userData = useSelector(state => state.auth.userData);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  const profileSchema = Yup.object({
    givenName: Yup.string().required("First Name is required").min(3, "Atleast 3 characters required"),
    familyName: Yup.string(),
    telephone: Yup.string().required("Phone number is required").matches(phoneRegExp, 'Phone number is not valid'),
  });
  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      id: userData?.id,
      givenName: userData?.givenName,
      familyName: userData?.familyName,
      fullName: userData?.fullName,
      telephone: userData?.telephone,
      email: userData?.email,
      address: {
        city: userData?.address?.city,
        state: userData?.address?.state,
        country: userData?.address?.country,
        district: userData?.address?.district,
        postCode: userData?.address?.postCode,
        streetAddress: userData?.address?.streetAddress
      },
      ...userData
    },
    validationSchema: profileSchema,

    onSubmit: async values => {
      setIsLoading(true);
      await dispatch(updateProfile(values));
      setIsLoading(false);
    }
  
  });
  useEffect(() => {
    if(!userData?.id)
    {
    navigate(-1);
    }

  }, [userData]);

  return (
    <>
      <PrimaryLayout showQZLogo>
        <GridContainer justifyContent="center" alignItems="center" spacing={2}>
          <GridItem lg={8} md={10}>
            <Card component="form" sx={{ padding: "25px 30px", marginTop: "30px", borderRadius: 5 }}>
              <Typography sx={{ fontSize: 24, marginBottom: 3 }}>Profile Details</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="email"
                    className="text-base"
                    label="Email (disabled)"
                    name="email"
                    variant="standard"
                    fullWidth
                    onChange={handleChange}
                    value={values.email}
                    disabled
                    inputProps={{ 
                  style:{ fontSize: 17 }}}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneNumberInput
                    label="Phone Number"
                    name="telephone"
                    variant="standard"
                    fullWidth
                    onChange={telephone => setFieldValue('telephone', telephone)}
                    value={values.telephone}
                    helperText={touched.telephone && errors.telephone}
                    error={touched.telephone && errors.telephone ? true : false}
                    inputProps={{ 
                     style: { fontSize: 17 }
                      }}
                  readOnly={userData?.userType === "GUEST"  ? true:false}
              
                   />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    value={values.givenName}
                    name="givenName"
                    helperText={touched.givenName && errors.givenName}
                    error={touched.givenName && errors.givenName ? true : false}
                    inputProps={{ style: { fontSize: 17 },
                    readOnly:userData?.userType === "GUEST" 
                  }}

                  />
                </Grid>
                {userData?.userType !== "GUEST" ?
                  <>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    variant="standard"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    value={values.familyName}
                    name="familyName"
                    helperText={touched.familyName && errors.familyName}
                    error={touched.familyName && errors.familyName ? true : false}
                    inputProps={{ style: { fontSize: 17 } }}
                  />
                </Grid>
               

                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Address"
                        variant="standard"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={values.address.streetAddress}
                        name="address.streetAddress"
                        inputProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="City"
                        variant="standard"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={values.address.city}
                        name="address.city"
                        inputProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="District"
                        variant="standard"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={values.address.district}
                        name="address.district"
                        inputProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="State"
                        variant="standard"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={values.address.state}
                        name="address.state"
                        inputProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Country"
                        variant="standard"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={values.address.country}
                        name="address.country"
                        inputProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Postal Code"
                        variant="standard"
                        type="number"
                        fullWidth
                        onChange={handleChange}
                        value={values.address.postCode}
                        name="address.postCode"
                        inputProps={{ style: { fontSize: 17 } }}
                      />
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ ml: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}>
                        UPDATE PROFILE
                      </Button>
                    </Grid>
                    </>: null}
              </Grid>
            </Card>
          </GridItem>
        </GridContainer>

      </PrimaryLayout>
    </>


  )
}
export default Profile;