import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordSubmit, requestForgotPassword, setShowForgotPasswordForm } from "../../redux/actions/authActions";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Close, Email, Lock } from "@mui/icons-material";

const ForgotPasswordModal = () => {
    const showForgotPwForm = useSelector(state => state.auth.showForgotPasswordForm);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const forgotPwFormSchema = Yup.object({
        code: Yup.string().required("Code is required").min(6, "Invalid code").max(12, "Invalid code"),
        email: Yup.string().email("Invalid Email"),
        password: Yup.string().required("Password is required")
            .matches(/^.*[a-z].*$/, 'Must contain atleast one lowercase alphabet')
            .matches(/^.*[A-Z].*$/, 'Must contain atleast one uppercase alphabet')
            .matches(/^.*[0-9].*$/, 'Must contain atleast one number')
            .matches(/^.*[!@#$%^&*_\-+=`~].*$/, 'Must contain a special character !@#$`...')
            .matches(/^.{8,}$/, 'Must have atleast 8 characters'),
        confirmPassword: Yup.string()
            .required('Please re-enter your password')
            .oneOf([Yup.ref('password'), null], "The passwords entered doesn't match"),
    });

    const { values, handleChange, handleBlur, handleSubmit, setFieldError, setFieldTouched, errors, touched } = useFormik({
        initialValues: {
            code: '',
            email: '',
            password : '',
            confirmPassword: ''
        },
        validationSchema: forgotPwFormSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            let payload = {
                code: values.code,
                email: values.email,
                newPassword: values.password
            }
            //console.log("forgotPasswordSubmit Payload:", payload)
            const success = await dispatch(forgotPasswordSubmit(payload));
            if (success) {
                resetForm();
            }
            setIsLoading(false);
        }
    });

    const sendCode = async () => {

        await setFieldTouched("email", true, true);
        if (!values?.email) {
            setFieldError("email", "Email is required");
            return;
        }
        if (values.email && !errors?.email) {
            dispatch(requestForgotPassword(values.email))
        }
    }

    const handleClose = () => {
        dispatch(setShowForgotPasswordForm(false));
    }
    const closeModal = () => {
        dispatch(setShowForgotPasswordForm(false));
    }

    return (
        <>
            <Dialog onClose={handleClose} open={showForgotPwForm} fullWidth maxWidth="xs" PaperProps={{ sx: { borderRadius: 5 } }}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    <Typography>Reset Password</Typography>
                    <Typography variant="caption">Enter your email and click "send code" button. A verification code will be sent to your email </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mx: 'auto', minHeight: 75, display: 'flex' }}>
                        <TextField
                            label="Email"
                            variant="standard"
                            fullWidth
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched?.email && errors?.email}
                            error={touched?.email && errors?.email ? true : false}
                            autoComplete='off'
                        />
                        <Box sx={{ p: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={sendCode}
                                disabled={isLoading || !values?.email || errors?.email ? true : false}
                                sx={{ borderRadius: 2, minWidth: 120 }}
                            >
                                Send Code
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <TextField
                            label="Code"
                            variant="standard"
                            fullWidth
                            name="code"
                            onChange={handleChange}
                            value={values.code}
                            helperText={touched?.code && errors?.code}
                            error={touched?.code && errors?.code ? true : false}
                            autoComplete='off'
                        //inputProps={{ style: { textAlign: 'center' }}}
                        />
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <TextField
                            label="New Password"
                            variant="standard"
                            fullWidth
                            name="password"
                            type="password"
                            onChange={handleChange}
                            value={values.password}
                            helperText={touched?.password && errors?.password}
                            error={touched?.password && errors?.password ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete='new-password'
                        />
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <TextField
                            label="Confirm Password"
                            variant="standard"
                            fullWidth
                            name="confirmPassword"
                            type="password"
                            onChange={handleChange}
                            value={values.confirmPassword}
                            helperText={touched?.confirmPassword && errors?.confirmPassword}
                            error={touched?.confirmPassword && errors?.confirmPassword ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete='new-password'
                        />
                    </Box>
                    <Box sx={{ mt: 1, mx: 'auto', display: 'flex', justifyContent: 'space-around' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading}
                            endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                            sx={{ borderRadius: 2, minWidth: 120 }}
                        >
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ForgotPasswordModal;