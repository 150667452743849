import {Config} from './regions';

export const QZ_COGNITO_IDENTITY_KEY = Config.QZ_COGNITO_IDENTITY_KEY;
export const AUTH_METHOD = Config.AUTH_METHOD;
export const GOOGLE_ID =  Config.GOOGLE_ID;
export const GOOGLE_SECRET = Config.GOOGLE_SECRET;
export const GOOGLE_GEO_API_KEY = Config.GOOGLE_GEO_API_KEY;
export const GOOGLE_CAPTCHA_SITE_KEY = Config.GOOGLE_CAPTCHA_SITE_KEY;
export const GOOGLE_CAPTCHA_SECRET = Config.GOOGLE_CAPTCHA_SECRET;
export const PROVIDER = Config.PROVIDER;
export const FB_APP_ID = Config.FB_APP_ID;
export const FB_API_VERSION = Config.FB_API_VERSION;

export const AWS_CONFIG = Config.AWS_CONFIG; 
