import React from "react";
import { Grid, Typography } from "@mui/material";
// import GridContainer from '../../components/Grid/GridContainer';
// import GridItem from '../../components/Grid/GridItem';


const TermsContent = ({ children }) => {
  return (
    <Grid style={{ padding: 10 }}>
      <Typography variant="h5" gutterBottom align="center" style={{ marginTop: '1em' }}>
        QUEZONE TERMS OF SERVICE, APP LICENCE AND PRIVACY POLICY
      </Typography>

      <Typography variant="subtitle1" gutterBottom align="center">
        Chii Systems Pty Limited ABN 46 156 194 777
      </Typography>

      <Typography variant="body1" component="div">
        BY ACCEPTING CHII SYSTEMS’ OFFER OF THIS APP AND DOWNLOADING IT, YOU AGREE TO THE CHII SYSTEMS
        <strong>TERMS OF LICENCE</strong> OF THE APP, <strong>TERMS OF SERVICE</strong>, AND <strong>PRIVACY POLICY</strong>.
        THESE ARE SET OUT IN FULL BELOW OR YOU MAY VISIT THE QUEZONE WEBSITE AT
        <a href="https://www.quezone.com.au" target="_blank" rel="noopener noreferrer">HTTPS://WWW.QUEZONE.COM.AU</a>.
        THE USE OF THE QUEZONE QUEUE MANAGEMENT APP REQUIRES YOU TO ENTER PERSONAL INFORMATION ABOUT YOURSELF, INCLUDING YOUR NAME,
        EMAIL ADDRESS, AND MOBILE PHONE NUMBER FOR RECEIVING CONFIRMATION MESSAGES AND STATUS UPDATES FROM YOUR QUEZONE SERVICE PROVIDER.
        CHII SYSTEMS RESPECTS YOUR PRIVACY AND WILL USE THIS INFORMATION IN ACCORDANCE WITH CHII SYSTEMS’ <strong>PRIVACY POLICY</strong>.
        BEFORE CHECKING THE “<strong>ACCEPT</strong>” CHECKBOX, PLEASE CAREFULLY READ ALL OF THESE TERMS AND CONDITIONS.
        THIS IS THE BASIS UPON WHICH CHII SYSTEMS AGREES TO PROVIDE THE QUEZONE SERVICE AND PERMITS THE QUEZONE APP TO BE USED BY YOU AND IT INCLUDES RESTRICTIONS ON CHII SYSTEMS’ LIABILITY IN RESPECT OF BOTH.
        PLEASE NOTE: QUEZONE SERVICE PROVIDERS DO NOT REPRESENT CHII SYSTEMS.
        THEY ARE LICENSED BY CHII SYSTEMS TO USE THE QUEZONE SOFTWARE SERVICE IN THE MANAGEMENT OF THEIR OWN, INDEPENDENT BUSINESSES.
        CHII SYSTEMS IS ALSO NOT RESPONSIBLE FOR THE WORKING OR FAILURE OF EQUIPMENT AND COMMUNICATIONS NETWORKS THAT YOU OR THEY MAY USE IN CONJUNCTION WITH THE QUEZONE APP AND SERVICE.
        AS A RESULT, CHII SYSTEMS DOES NOT HAVE ANY RESPONSIBILITY OR LIABILITY TO YOU IF QUEZONE SERVICE PROVIDERS FAIL TO USE QUEZONE EFFECTIVELY OR IF AS A RESULT OF ANY SUCH MATTER ANY MESSAGES DO NOT REACH YOU CORRECTLY, ON TIME OR AT ALL.
      </Typography>

      <Typography variant="subtitle1" gutterBottom className="mt-4" sx={{ marginTop: 5 }}>
        1. App License and Use of Service
      </Typography>

      <Typography variant="body1" component="div">
        The Quezone app is licensed, not sold, to you and it and the Quezone service may only be used upon the terms of this Licence,
        and Chii Systems reserves all rights with respect to the Quezone app. Use of the Quezone app and service is subject to the following conditions:

        (a) Chii Systems’ licence to you of the app and the right to use the Quezone service is personal.
        You may not give the Quezone app or transfer any rights under this licence or provide access to the Quezone app or service to any other person.

        (b) YOU MAY NOT MAKE ANY COMMERCIAL USE OF THE QUEZONE APP OR SERVICE – THAT IS,
        YOU MAY NOT OFFER ACCESS TO EITHER TO ANY OTHER PERSON FOR THE PURPOSE OF CONDUCTING A BUSINESS FOR YOURSELF OR FOR ANY OTHER PERSON.

        (c) You must provide all equipment, software communications necessary to use the Quezone app and service,
        including a compatible computer and/or mobile phone as specified by Chii Systems from time to time.

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 5 }}>
          <b>1. License and Terms of Use</b>
        </Typography>

        (a) By downloading, installing, or using the Quezone app and service ("the app"), you agree to be bound by the terms and conditions of this license.

        (b) You may not make any commercial use of the Quezone app or service. You cannot offer access to others for conducting a business.

        (c) You must provide all necessary equipment, software, and communication services to use the Quezone app and service as specified by Chii Systems.

        (d) You are responsible for any fees, including internet connection or mobile fees, when accessing the Quezone service.

        (e) Personal information required for using the Quezone service will be held and used in accordance with Chii Systems' Privacy Policy.

        (f) You must keep your login information secure and not share it with others. Notify Chii Systems immediately in case of any security breach.

        (g) Chii Systems reserves the right to remove or reclaim any usernames at any time and for any reason.

        (h) You must not interfere with the proper functioning of the Quezone service or use unauthorized software or connections.

        (i) The Quezone app contains confidential information of Chii Systems and must not be modified or reverse engineered.

        (j) All rights in the copyright and trademarks used in association with the Quezone app and service are retained by Chii Systems.

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 5 }}>
          <b>2. Termination</b>
        </Typography>

        This license will terminate automatically if you fail to comply with its provisions. You may terminate the license by destroying the app.

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 5 }}>
          <b>3. Limited Warranty</b>
        </Typography>

        Chii Systems will make reasonable efforts to ensure the Quezone service remains operational. The app is warranted to operate according to specifications.
        Chii Systems does not warrant that the app will be error-free but will make efforts to correct any identified errors.

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 5 }}>
          <b>4. Disclaimer of Warranty</b>
        </Typography>

        Except as provided in Section 3, Chii Systems disclaims all guarantees, warranties, and conditions regarding the Quezone app and service. The app is provided "as is" without warranty.

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 5 }}>
          <b>5. Consumer Law Rights</b>
        </Typography>

        Statutes may grant you certain rights, including guarantees of acceptable quality and fitness for a purpose. Please refer to applicable laws for your rights.

        <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 5 }}>
          <b>6. General</b>
        </Typography>

        This License constitutes the entire agreement between you and Chii Systems. If any provision is unenforceable, it will be enforced to the maximum extent permissible, and the remainder of the License will continue in full force and effect.


      </Typography>


    </Grid>
  )
}
export default TermsContent;