import axios from 'axios';
import {Config} from './regions';

export let BASE_URL = process.env.NODE_ENV === 'production'
  ?  Config.BASE_URL
  : Config.BASE_URL;

 export const LIVE_UPDATES_URL = `${BASE_URL}/messageBoard`;
 
axios.defaults.baseURL = `${BASE_URL}/api`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

