import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function ButtonField(props) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    return (
        <Button
            color="slotPicker"
            variant="outlined"
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            sx={{ textTransform: "none", borderRadius: 3 }}
        >
            {label ?? 'Pick a date'}
        </Button>
    );
}

ButtonField.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inputProps: PropTypes.shape({
        'aria-label': PropTypes.string,
    }),
    InputProps: PropTypes.shape({
        endAdornment: PropTypes.node,
        startAdornment: PropTypes.node,
    }),
    label: PropTypes.node,
    setOpen: PropTypes.func,
};

function ButtonDatePicker(props) {
    const [open, setOpen] = React.useState(false);

    return (
        <DatePicker
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen } }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}

ButtonDatePicker.propTypes = {
    /**
     * Overridable component slots.
     * @default {}
     */
    slots: PropTypes.any,
};

export default ButtonDatePicker;