import { AppBar, Box, Button, Hidden, IconButton, Toolbar, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ArrowBack, Person } from '@mui/icons-material'
import Sidebar from './components/Sidebar';
import qzLogo from '../assets/images/quezone-logo.png';
import { useTheme } from '@emotion/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import GeoLocation from './components/GeoLocation';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationById } from '../redux/actions/apiActions';
import AuthScreens from '../pages/Auth/AuthScreens';
import SnackbarAlert from '../components/Alert/SnackbarAlert';

const PrimaryLayout = ({
    children,
    showLocation = true,
    showQZLogo = false,
    showOrgLogo = true,
    title = "",
    showBackButton = false,
    appBarProps = {},
}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [hideAppbar, setHideAppbar] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const { orgRef } = useParams();
    const { state } = useLocation()
    const orgDetails = useSelector(state => state.api.orgDetails);
    const dispatch = useDispatch();

    const onClickLogo = () => {
        if (showQZLogo) {
            navigate('/');
        } else if (orgRef) {
            navigate(`/${orgRef}`);
        } else if (orgDetails?.orgRef) {
            navigate(`/${orgDetails?.orgRef}`);
        }
    }

    useEffect(() => {
        console.log("Loaded PrimaryLayout ");
    }, []);

    useEffect(() => {
        /*
        if (orgRef && orgDetails?.orgRef !== orgRef) {
            dispatch(fetchOrganizationById(orgRef));
        }
        */
    }, [orgRef])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const hideAppBar_ = queryParams.get('hideAppBar');
        if (hideAppBar_) {
            setHideAppbar(true);
        }
    }, [])

    return (
        <div className="h-96">
            {hideAppbar ?
                <></>
                :
                <AppBar position="sticky" color="secondary" {...appBarProps} >
                    <Toolbar>
                        {showBackButton ?
                            <>
                                <IconButton color="inherit" aria-label="go back" onClick={() => navigate(-1)}>
                                    <ArrowBack />
                                </IconButton>
                            </>
                            : null
                        }
                        {showQZLogo ?
                            <Button color="inherit" onClick={onClickLogo}>
                                <img src={qzLogo} alt={`Quezone`} className="object-contain h-8 rounded-md" />
                            </Button>
                            : showOrgLogo && orgDetails?.logo?.fileUrl ?
                                <Button color="inherit" onClick={onClickLogo}>
                                    <img src={orgDetails.logo.fileUrl} alt={orgDetails.name} className="object-contain h-8 rounded-md" />
                                </Button>
                                :
                                <></>
                        }
                        {title ? <Hidden smDown><Typography variant="overline" sx={{ px: 1 }}>{title}</Typography></Hidden> : null}
                        <Box sx={{ flexGrow: 1 }} />
                        {showLocation ? <GeoLocation /> : null}
                        <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpenDrawer(!openDrawer)}>
                            <Person />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            }
            <Sidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} showLocation={!showLocation} />
            <AuthScreens />
            <SnackbarAlert />
            <main>{children}</main>
        </div>
    )
}

export default PrimaryLayout;