import { useDispatch, useSelector } from "react-redux";
import AccountVerifyModal from "./AccountVerifyModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import LoginModal from "./LoginModal"
import SignUpModal from "./SignUpModal"
import { useEffect } from "react";
import axios from "axios";
import { refreshCurrentSession, userLogout } from "../../redux/actions/authActions";
import { Auth, Hub } from "aws-amplify";
import moment from "moment-timezone";

const AuthScreens = () => {

    const authToken = useSelector(state => state.auth.authToken);
    const sessionData = useSelector(state => state.auth.sessionData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
        }
    }, [authToken]);

    useEffect(() => {
        dispatch(refreshCurrentSession());
    }, []);

    useEffect(() => {
        const waitTime = 5 * 60 * 1000;  //checks every 5 minute
        const guestUserDataString = localStorage.getItem('guestUserData');
        const guestUserData = guestUserDataString ? JSON.parse(guestUserDataString) : null

        const expireTime = sessionData?.idToken?.payload?.exp || guestUserData?.expiresOn;
        const interval = setInterval(() => {
            if (expireTime) {
                const now = moment().unix()
                console.log("Session expires in ", expireTime - now)
                if ((expireTime - now) < waitTime) {
                    dispatch(refreshCurrentSession());
                }
            }
        }, waitTime);
        return () => clearInterval(interval);
    }, [sessionData]);

    return (
        <>
            <LoginModal />
            <SignUpModal />
            <AccountVerifyModal />
            <ForgotPasswordModal />
        </>
    )
}

export default AuthScreens;