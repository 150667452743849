import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const HorizontalItems = ({ children }) => {

    //const childrenArray = React.Children.toArray(props.children);

    const chipContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScrollLeft = () => {
        chipContainerRef.current.scrollTo({
            left: scrollPosition - 200,
            behavior: 'smooth',
        });
    };

    const handleScrollRight = () => {
        chipContainerRef.current.scrollTo({
            left: scrollPosition + 200,
            behavior: 'smooth',
        });
    };

    const handleScroll = (e) => {
        const position = e.target.scrollLeft;
        setScrollPosition(position);
    }


    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1, justifyContent: 'center' }}>
            {scrollPosition <= 0 ?
                <Box sx={{ width: 40, height: 40 }} /> :
                <IconButton
                    onClick={handleScrollLeft}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
            }

            <Box
                ref={chipContainerRef}
                onScroll={handleScroll}
                sx={{ display: 'flex', gap: '0px', flexWrap: 'nowrap', overflowX: 'auto', scrollbarWidth: 'none', '&::-webkit-scrollbar': { width: 0, height: 0, display: 'none' } }}
            >
                {children}
            </Box>
            {scrollPosition >= (chipContainerRef.current?.scrollWidth - chipContainerRef.current?.clientWidth) ?
                <Box sx={{ width: 40, height: 40 }} /> :
                <IconButton
                    onClick={handleScrollRight}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            }
        </Box>
    );
};

export default HorizontalItems;