import { useState } from "react";
import { useEffect } from "react";
import { setPromptFlowMember, setShowLocationSelector, showAlert } from "../../redux/actions/commonActions";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import getDistBetweenCordinates from "../../utils/getDistBetweenCordinates";

const LocationFilterForExistingUsers = (props) => {

    const { locationList = [], onConfirmLocation = () => { } } = props;
    const [showExistingUserPrompt, setShowExistingUserPrompt] = useState(false);
    const [promptEnabled, setPromptEnabled] = useState(false);

    const geoLocState = useSelector(state => state?.common?.geoLocationData);
    const showLocationSelector = useSelector(state => state?.common?.showLocationSelector);
    const isPromptFlowMember = useSelector(state => state?.common?.isPromptFlowMember);
    const orgDetails = useSelector(state => state.api.orgDetails);
    const queryParams = useSelector(state => state.common.queryParams);

    const dispatch = useDispatch();

    const findNearestLocationsByRadius = (geoLocation, distanceRadius = 200) => {
        return locationList.reduce((nearestLocs, currentLoc) => {
            const distanceToCurrentLoc = getDistBetweenCordinates(currentLoc?.coordinates, geoLocation?.coords);
            if (distanceToCurrentLoc !== null && distanceToCurrentLoc <= distanceRadius) {
                return [...nearestLocs, { ...currentLoc, distance: distanceToCurrentLoc }];
            }
            dispatch(showAlert("info", `Services available within a ${distanceRadius} km radius of your location are now being displayed.`, { autoHideDuration: 3000 }));
            return nearestLocs;
        }, []).sort((a, b) => a?.distance - b?.distance);
    }


    const findClosestLocation = (geoLocation) => {
        //check for pin code match 
        let matchedLocation = locationList.filter(item => item?.postCode?.trim() == geoLocation?.pinCode?.trim());
        //console.log("matchedLocation", matchedLocation);
        if (matchedLocation?.length) {
            dispatch(showAlert("info", `Services available in your area (PIN code: ${geoLocation?.pinCode}) have been displayed.`, { autoHideDuration: 3000 }));
            return matchedLocation;
        }
        //check for state match & return locations within the same state
        matchedLocation = locationList.filter(item => item?.state?.toLowerCase().trim() == geoLocation?.address?.state?.toLowerCase().trim());
        //console.log("matchedLocation", matchedLocation, locationList, geoLocation);
        if (matchedLocation?.length && matchedLocation?.length <= 5) {
            dispatch(showAlert("info", `Services available across your state (${geoLocation?.address?.state}) have been displayed.`, { autoHideDuration: 3000 }));
            return matchedLocation;
        }
        //find nearest locations within 200 km;
        let nearestLocations = findNearestLocationsByRadius(geoLocation, 200);
        if (nearestLocations?.length && nearestLocations?.length <= 5) {
            return nearestLocations;
        }
        //find nearest locations within 100 km;
        nearestLocations = findNearestLocationsByRadius(geoLocation, 100);
        if (nearestLocations?.length) {
            return nearestLocations;
        }
    }

    useEffect(() => {
        //console.log("locationList Changed", locationList);
        if (promptEnabled == "true") {
            console.log("promptEnabled || locationList ", promptEnabled, locationList)
            if (locationList?.length > 0 && isPromptFlowMember == null && !showLocationSelector) {
                setShowExistingUserPrompt(true);
            }
            else if (isPromptFlowMember && locationList?.length > 0 && geoLocState?.pinCode && !showLocationSelector) {
                const closestLocation = findClosestLocation(geoLocState);
                onConfirmLocation(closestLocation);
            }
        }
    }, [locationList, promptEnabled])

    useEffect(() => {
        console.log("geoLocState Changed", geoLocState);
        if (isPromptFlowMember && geoLocState && geoLocState?.pinCode && locationList?.length && !showLocationSelector) {
            const closestLocation = findClosestLocation(geoLocState);
            onConfirmLocation(closestLocation);
        }
    }, [geoLocState, showLocationSelector])

    useEffect(() => {
        if (queryParams) {
            console.log("Mounted Location Filter for existing user", queryParams)
            const prompt = queryParams?.prompt;
            if (prompt === "true") {  // Show prompt flow only if prompt parameter value is true
                setPromptEnabled(prompt);
            }
        }
    }, [queryParams])

    const onCancelDialog = () => {
        setShowExistingUserPrompt(false);
        dispatch(setPromptFlowMember(false));
    }
    const onConfirmDialog = () => {
        setShowExistingUserPrompt(false);
        dispatch(setShowLocationSelector({ isMandatory: true, disableAutoDetectButton: true }));
        dispatch(setPromptFlowMember(true));
    }

    const promptTitle = orgDetails?.custwebStringOverrides?.locationFilterPromptTitle ? orgDetails?.custwebStringOverrides?.locationFilterPromptTitle : '';
    const promptMessage = orgDetails?.custwebStringOverrides?.locationFilterPromptMessage ? orgDetails?.custwebStringOverrides?.locationFilterPromptMessage : `Are you an existing member of ${orgDetails.name}?`;

    return (
        <>
            {(promptEnabled === null || promptEnabled === 'true') &&
                <ConfirmationDialog
                    show={showExistingUserPrompt}
                    title={<Typography variant="subtitle2">{promptTitle}</Typography>}
                    content={promptMessage}
                    onClose={() => setShowExistingUserPrompt(false)}
                    onConfirm={onConfirmDialog}
                    onCancel={onCancelDialog}
                    cancelLabel="No"
                    confirmLabel="Yes"
                    autoFocus={false}
                />
            }
        </>
    )
}

export default LocationFilterForExistingUsers;