import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
    SET_AUTH_TOKEN,
    SET_REFRESH_TOKEN,
    SET_SESSION_DATA,
    SET_SHOW_FORGOTPW_FORM,
    SET_SHOW_LOGIN_FORM,
    SET_SHOW_SIGNUP_FORM,
    SET_USERDATA,
    SET_USERDATA_LOADING,
    SET_SHOW_ACCOUNT_VERIFY_FORM,
    SET_ACCOUNT_VERIFY_DATA,
} from '../actions/authActions';

const initState = {
    userData: {},
    authToken: null,
    refreshToken: null,
    userDataLoading: false,
    sessionData : null,
    showLoginForm: false,
    showSignUpForm : false,
    showForgotPasswordForm: false,
    showAccountVerifyForm:false,
    accountVerifyData:null 
};
const persistConfig = {
    key: 'auth',
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    blacklist: [],
};
const reducer = (state = initState, action) => {
    switch (action.type) {
        case SET_USERDATA:
            return {
                ...state,
                userData: action.payload,
            };
        case SET_USERDATA_LOADING:
            return {
                ...state,
                userDataLoading: action.payload,
            };
        case SET_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload,
            };
        case SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload,
            };
        case SET_SESSION_DATA:
            return {
                ...state,
                sessionData: action.payload,
            };
        case SET_SHOW_LOGIN_FORM:
            return {
                ...state,
                showLoginForm: action.payload,
            };
        case SET_SHOW_SIGNUP_FORM:
            return {
                ...state,
                showSignUpForm: action.payload,
            };
        case SET_SHOW_FORGOTPW_FORM:
            return {
                ...state,
                showForgotPasswordForm: action.payload,
            };
        case SET_SHOW_ACCOUNT_VERIFY_FORM:
            return {
                ...state,
                showAccountVerifyForm: action.payload,
            };
        case SET_ACCOUNT_VERIFY_DATA:
            return {
                ...state,
                accountVerifyData: action.payload,
            };
        default:
            return state;
    }
};

export default persistReducer(persistConfig, reducer);
