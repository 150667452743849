import { useEffect } from "react";
import useGeoLocation from "../../hooks/useLocation";
import { Button, IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeoLocationByCoordinates, setShowLocationSelector } from "../../redux/actions/commonActions";
import LocationSelelctor from "./LocationSelector";

const GeoLocation = ({ onSideBar = false }) => {

    const [geoLocationData, fetchGeoLocation] = useGeoLocation();
    const geoLocState = useSelector(state => state?.common?.geoLocationData);
    const isPromptFlowMember = useSelector(state => state?.common?.isPromptFlowMember);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!geoLocState) {
            fetchGeoLocation();
        }
    }, []);


    return (
        navigator.geolocation && isPromptFlowMember !== false ?
            <>
                {onSideBar ?
                    <ListItemButton onClick={() => dispatch(setShowLocationSelector(true))} >
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={geoLocState?.address?.county ?? ""} />
                    </ListItemButton >
                    :
                    <Button color="inherit" onClick={() => dispatch(setShowLocationSelector(true))}>
                        {geoLocState?.address?.county ?
                            <div className='text-sm px-2 capitalize'>{geoLocState.address.county}</div>
                            : geoLocState?.address?.town ?
                                <div className='text-sm px-2 capitalize'>{geoLocState.address.town}</div>
                                : geoLocState?.address?.city ?
                                    <div className='text-sm px-2 capitalize'>{geoLocState.address.city}</div>
                                    : null}
                        <LocationOn />
                    </Button>
                }
                <LocationSelelctor />
            </>

            :
            <></>
    )

}
export default GeoLocation;