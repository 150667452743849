import { Button } from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIsVisible } from "../../hooks/useIsVisible";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const CustomFixedButton = (props) => {
    const { children } = props;
    const [position, setPosition] = useState("relative");
    const ref = useRef(null);
    const isVisible = useIsVisible(ref);
    //const {height, width} = useWindowDimensions();

    /*
    useEffect(() => {
        const onScroll = () => {
            let rect = ref.current.getBoundingClientRect();
            console.log(height,rect)
            setPosition( rect.y > height ? "fixed" : "relative" );
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }, []);
    */

    const changeButtonPosition = () => {
        setPosition(isVisible ? "relative" : "fixed")
    }

    useEffect(() => {
        changeButtonPosition();
    }, [isVisible]);

    return (
        <>
            <div ref={ref} >
                <Button {...props} sx={{ position: position, zIndex: 1, bottom: 0, left:0, right:0, transitionDuration: "1s" }} >{children}</Button>
            </div>
        </>
    )
}

export default CustomFixedButton