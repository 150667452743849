import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizations } from '../../redux/actions/apiActions';
import PrimaryLayout from '../../layout/Layout';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Icon, Paper, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import queueImage from '../../assets/images/landing/hero-5-img2.png';
import businessImage from '../../assets/images/landing/businesses_2.jpg';
import customerImage from '../../assets/images/landing/clients-e1418520215326.jpg';
import appStoreImage from '../../assets/images/landing/app_store.png';
import playStoreImage from '../../assets/images/landing/android_compressed.png';
import freePlanImage from '../../assets/images/landing/baby.png';
import growthPlanImage from '../../assets/images/landing/boy.png';
import proPlanImage from '../../assets/images/landing/pro-2.png';
import enterprisePlanImage from '../../assets/images/landing/enter.png';

import { useNavigate } from 'react-router-dom';
import { Config } from '../../config/regions';
import SearchBar from '../../components/SearchBar/SearchBar';

import './style.css';
import ToggleTabSwitch from '../../components/CustomSwitch/ToggleTabSwitch';
import Footer from './Footer';


const Landing = () => {

  const dispatch = useDispatch();
  const organizationList = useSelector(state => state.api.organizationList);
  const organizationListLoading = useSelector(state => state.api.organizationListLoading);
  const navigate = useNavigate();

  const [billingType, setBillingType] = useState("monthly");


  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [])


  const handleSearch = (e, selected) => {
    let orgRef = selected?.orgRef;
    if (orgRef) {
      navigate(`/${orgRef}`);
    }
  }

  const onClickRegister = () => {

    let onboardingUrl = Config.onboardingUrl + '/register';
    window.open(onboardingUrl);
  }
  const onClickJoinNow = () => {
    navigate("/p/organizations");
  }
  const onClickGetApp = (appType, platform) => {
    let url = '#';
    if (appType == "customer") {
      url = platform == "apple" ? "https://apps.apple.com/in/app/quezone/id1532363003" : "https://play.google.com/store/apps/details?id=com.custweb.quezone";
    } else if (appType == "business") {
      url = platform == "apple" ? "https://apps.apple.com/in/app/quezone-provider/id1572243889" : "https://play.google.com/store/apps/details?id=com.provider.quezone";
    }
    window.open(url);
  }
  const navigateToSegmentPage = (segment) => {
    navigate(`s/${segment}`);
  }

  const industrySegments = [
    { name: "Health & Wellness", icon: "volunteer_activism" },
    { name: "Fitness & Sports", icon: "fitness_center" },
    { name: "Professional Services", icon: "home_repair_service" },
    { name: "Business Operations", icon: "business_center" },
    { name: "Salon & Beauty", icon: "content_cut" },
    { name: "Spa", icon: "spa" },
    { name: "Education", icon: "school" },
    { name: "Tutoring Services", icon: "cast_for_education" },
    { name: "Automotive", icon: "airport_shuttle" },
    { name: "Pet Services", icon: "pets" },
    { name: "Government & Public Sector", icon: "public" },
    { name: "Home Services & Cleaning", icon: "home_work" },
  ]
  const features = [
    {
      icon: "highlight_alt",
      title: "Online Booking System",
      desc: "Remove friction from your booking process by minimizing the communication gap with your customers. Your Quezone's online booking page will allow them to view real-time availability for services so that they can pick a time of their own convenience easily"
    },
    {
      icon: "calendar_month",
      title: "Calendar Scheduler",
      desc: "View appointments and staff schedules for any day/week/month at a glance. Sync with your personal and professional calendars like Google Cal, iCal, Outlook & more",
    },
    {
      icon: "notification_important",
      title: "Appointment Alerts",
      desc: "Receive instant notifications via sms, email, or straight in the app when an appointment is booked, rescheduled, or canceled. Your customers also receive automated appointment alerts so that they stay on top of their bookings without having to dial-in",
    },
    {
      icon: "headset_mic",
      title: "Virtual Appointment Scheduling",
      desc: "Offer virtual appointments and online consultations to get business even when customers can’t come to your store. Our MS Teams integration or Built-in Meeting Platform automatically creates and shares virtual appointment details with the attendees when they book an appointment with you",
    },
    /*
    {
      icon: "",
      title: "",
      desc: "",
    },
    */
  ]
  const faq = [
    {
      question: "What is online booking system?",
      answer: "An online booking system allows the customers to book appointments with you or book your services through an online platform. Such a system allows the business owner/admin to control and oversee the availability of their services and the bookable hours that are available to the customers. An online scheduling software comes with an inbuilt reminder system that helps sending out automated email/SMS reminders to customers. Appointy is an advanced and free booking system that also allows the online booking portal to be customized to mirror your brand. ",
    },
    {
      question: "Does Quezone offer client and admin appointment scheduling apps?",
      answer: "Yes, we offer both. In the client app, clients can easily book, reschedule, or cancel appointments with you 24/7. In the admin app, the business owner/admin can stay on top of their business by keeping a check on their schedule – by creating, editing, or deleting appointments, setting bookable hours, checking booking history, running reports, etc."
    },
    {
      question: "How can I manage all the bookings that are made in the booking software?",
      answer: "You can have complete control over all the bookings that are made in the system. You can set flexible business hours at both the staff and service levels, set a limit on the number of bookings that can be made per client, and choose how much in advance clients can book, reschedule, or cancel their appointments."
    },
    {
      question: "Does Quezone also have a WordPress plugin?",
      answer: "Yes. Quezone extends its feature set to WordPress through its WordPress plugin."
    },
    {
      question: "Can I add Quezone to my website",
      answer: "Yes, Quezone can help you get more customers by letting you add an iFrame or button widget on your website. You can also integrate our js library or wordpress plugin to your website"
    },
    {
      question: "Can different staff memebers can have their own login?",
      answer: "Yes, Quezone lets your staff members manage their bookings and schedule by enabling separate staff logins. The system allows the admin to automatically track staff schedules, productivity, etc"
    },
    {
      question: "Can I integrate Whatsapp business account?",
      answer: "We provide whasapp chatbot service for booking and managing appointments. Your customers can book appointments through whatsapp once your whatsapp number is integrated"
    }
  ]

  const pricingPlans = [
    {
      name: "Free",
      image: freePlanImage,
      pricePerMonth: 0,
      pricePerYear: 0,
      currency: "INR",
      buttonLabel: "Sign up now !!",
      buttonAction: onClickRegister,
      features:
        <>
          <li>Calendar integration</li>
          <li>Automatic reminders</li>
          <li>Mobile app</li>
          <li>100 appointments per month</li>
        </>
    },
    {
      name: "Growth",
      image: growthPlanImage,
      pricePerMonth: 1999,
      pricePerYear: 20000,
      currency: "INR",
      buttonLabel: "Sign up now !!",
      buttonAction: onClickRegister,
      features:
        <>
          <li>Website integration</li>
          <li>Virtual Appointments</li>
          <li>Whatsapp Bot Integration</li>
          <li>Unlimited Appointments</li>
        </>
    },
    {
      name: "Professional",
      image: proPlanImage,
      pricePerMonth: 3999,
      pricePerYear: 40000,
      currency: "INR",
      buttonLabel: "Sign up now !!",
      buttonAction: onClickRegister,
      features:
        <>
          <li>Website integration</li>
          <li>Virtual Appointments</li>
          <li>Whatsapp Bot Integration</li>
          <li>Unlimited Appointments</li>
        </>
    },
    {
      name: "Enterprise",
      image: enterprisePlanImage,
      pricePerMonth: null,
      pricePerYear: null,
      currency: "",
      buttonLabel: "Sign up now !!",
      buttonAction: onClickRegister,
      features:
        <Box sx={{mt:5}}>
          <li>Unlimited Customization</li>
          <li>Website integration</li>
          <li>Virtual Appointments</li>
          <li>Whatsapp Bot Integration</li>
          <li>Unlimited Appointments</li>
        </Box>
    },
  ]

  const colors = {
    primary: "#d70001",//"#0066ff",//"#d70001",
    primaryBg: "#ffe6e6",//"#e6f0ff",//"#ffe6e6",
  }

  const commonStyles = {
    iconContainer: {
      my: 1,
      p: 1,
      mx: 'auto',
      backgroundColor: colors.primaryBg,
      width: 70,
      height: 70,
      borderRadius: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }

  return (
    <PrimaryLayout showQZLogo>
      <Paper elevation={0} className="texture-shattered"  >
        <GridContainer justifyContent="center" style={{ minHeight: '95vh' }}>
          <GridItem md={6} lg={4}>
            <GridContainer justifyContent="center" alignItems="center" style={{ height: '100%', padding: '10px 5px' }}>
              <GridItem>
                <Box
                  sx={{
                    p: 2,
                    animation: 'fadeIn 1s ease-in-out forwards', // Animation name, duration, and easing
                    '@keyframes fadeIn': {
                      from: { opacity: 0 },
                      to: { opacity: 1 },
                    },
                    position: 'relative',
                    zIndex: 999
                  }}>
                  <Typography variant="h5" sx={{ fontWeight: '400' }}>
                    Ultimate Solution for Queue & Appointments
                  </Typography>
                  <Typography variant="body1">
                    Manage your appointments or queues through Quezone queue management system
                  </Typography>
                  <SearchBar
                    containerProps={{ sx: { my: 5, zIndex: 9999 } }}
                    sx={{ mt: '5px' }}
                    cardProps={{
                      elevation: 5,
                      sx: { borderRadius: 5, p: 1.5, px: 3, maxWidth: 'md', mx: 'auto', zIndex: 999 }
                    }}
                    autoComplete
                    autoCompleteProps={{
                      sx: { flex: 1 },
                      options: organizationList.sort((a, b) => (a?.name?.toLowerCase()?.trim() > b?.name?.toLowerCase()?.trim() ? 1 : a?.name?.toLowerCase()?.trim() < b?.name?.toLowerCase()?.trim() ? -1 : 0)),
                      onChange: handleSearch,
                      getOptionLabel: (option) => option.name,
                      noOptionsText: "No matching organization"
                    }}
                    textFieldProps={{
                      placeholder: "Search for organization",
                      sx: { borderRadius: 5 },
                    }}
                  />
                </Box>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem md={6} lg={4}>
            <GridContainer justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <Box sx={{
                animation: 'fadeInFromRight 1s ease-out', // Adjust the duration and easing function as needed
                '@keyframes fadeInFromRight': {
                  from: { opacity: 0, transform: 'translateX(100%)' },
                  to: { opacity: 1, transform: 'translateX(0%)' },
                },
              }}>
                <img src={queueImage} alt="Queue Image" className={""} />
              </Box>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper >
      {/* industry segments */}
      <Paper sx={{ py: 5, minHeight: '70vh' }} elevation={0} >
        <Box sx={{ p: 5, textAlign: 'center' }}>
          <Typography variant="h5" sx={{ p: 1, fontWeight: '700' }}>Quezone can serve almost any industry segment </Typography>
          <Typography variant="body" sx={{ opacity: 1 }}>Our appointment booking system is fit for all service-based local businesses, multi-location enterprises, franchises, and more </Typography>
        </Box>
        <GridContainer justifyContent="center" >
          <GridItem xs={12} md={10} lg={10} xl={8}>
            <GridContainer justifyContent="space-between" >
              {industrySegments.map((item, index) =>
                <GridItem xs={6} sm={4} md={3} lg={2} key={index}>
                  <Box sx={{
                    my: 5, textAlign: 'center',
                    animation: 'fadeInFromRight 0.25s ease-out', // Adjust the duration and easing function as needed
                    '@keyframes fadeInFromRight': {
                      from: { opacity: 0, transform: 'translateX(100%)' },
                      to: { opacity: 1, transform: 'translateX(0%)' },
                    },
                  }}
                    onClick={() => navigateToSegmentPage(item.name)}
                  >
                    <Box sx={commonStyles.iconContainer}>
                      <Icon sx={{ fontSize: 35, color: colors.primary }}>{item.icon}</Icon>
                    </Box>
                    <Typography variant="caption">{item.name}</Typography>
                  </Box>
                </GridItem>
              )
              }
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      {/* Features */}
      <Paper sx={{ py: 5, minHeight: '70vh' }} elevation={0} className="texture-shattered"  >
        <Box sx={{ p: 5, textAlign: 'center' }}>
          <Typography variant="h5" sx={{ p: 1, fontWeight: '700' }}>Quezone Features </Typography>
          <Typography variant="body" sx={{ opacity: 1 }}>Advanced booking features to help you put your business on autopilot</Typography>
        </Box>
        <GridContainer justifyContent="center" >
          <GridItem xs={12} md={10} lg={10} xl={8}>
            <GridContainer justifyContent="space-between" >
              {features.map((item, index) =>
                <GridItem xs={12} sm={12} md={6} lg={3} key={index}>
                  <Card sx={{
                    p: 2, mx: 1, my: 5, textAlign: 'center',
                    borderRadius: 3,
                    animation: 'fadeInFromRight 0.25s ease-out', // Adjust the duration and easing function as needed
                    '@keyframes fadeInFromRight': {
                      from: { opacity: 0, transform: 'translateX(100%)' },
                      to: { opacity: 1, transform: 'translateX(0%)' },
                    },
                  }}>
                    <Box sx={commonStyles.iconContainer}>
                      <Icon sx={{ fontSize: 35, color: colors.primary }}>{item.icon}</Icon>
                    </Box>
                    <Typography variant="overline">{item.title}</Typography>
                    <Box>
                      <Typography variant="caption" sx={{ color: "text.secondary" }}>{item.desc}</Typography>
                    </Box>
                  </Card>
                </GridItem>
              )
              }
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      {/* FAQ */}
      <Paper sx={{ py: 5, minHeight: '70vh' }} elevation={0} >
        <Box sx={{ p: 5, textAlign: 'center' }}>
          <Typography variant="h5" sx={{ p: 1, fontWeight: '700' }}>Frequently asked questions </Typography>
        </Box>
        <GridContainer justifyContent="center" >
          <GridItem xs={12} md={10} lg={10} xl={8}>
            <GridContainer justifyContent="space-between" >
              {faq.map((item, index) =>
                <GridItem xs={12} sm={12} md={6} key={index}>
                  <Box sx={{
                    m: 1,
                    animation: 'fadeInFromRight .25s ease-out', // Adjust the duration and easing function as needed
                    '@keyframes fadeInFromRight': {
                      from: { opacity: 0, transform: 'translateX(100%)' },
                      to: { opacity: 1, transform: 'translateX(0%)' },
                    },
                  }}>
                    <Accordion
                      sx={{ borderStyle: 'solid', borderColor: '#ddd', borderWidth: 1, borderRadius: "15px !important" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography>{item.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography sx={{ color: "text.secondary" }}>{item.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      {/* Pricing */}
      <Paper sx={{ py: 5, minHeight: '70vh' }} elevation={0} className="texture-shattered"  >
        <Box sx={{ p: 5, textAlign: 'center' }}>
          <Typography variant="h5" sx={{ p: 1, fontWeight: '700' }}>Simple and transparent pricing </Typography>
          <Typography variant="body" sx={{ opacity: 1 }}>No contracts. No credit cards. Cancel anytime</Typography>
        </Box>
        <GridContainer justifyContent="center" >
          <GridItem xs={10} md={10} lg={10} xl={8}>
            <Typography sx={{ fontSize: 14, textAlign: 'center', color: 'primary.main' }}>Billed</Typography>
            <GridContainer justifyContent="center" sx={{ zIndex: 999, }} >
              <ToggleTabSwitch
                onChange={(e, value) => { setBillingType(value) }}
                value={billingType}
                indicatorColor="secondary"
                sx={{ minHeight: 30, bgcolor: "primary.main", p: '1px', borderRadius: 10, borderWidth: 1, borderColor: '#fff', zIndex: 51 }}
                TabIndicatorProps={{ sx: { backgroundColor: '#fff' } }}
                tabs={[
                  {
                    label: "Monthly",
                    value: "monthly",
                    sx: { color: '#fff', textTransform: "none" }
                  },
                  {
                    label: "Yearly",
                    value: "yearly",
                    sx: { color: '#fff', textTransform: "none" }
                  }
                ]}
              />
            </GridContainer>
            <GridContainer justifyContent="center" sx={{ zIndex: 50 }} >
              {pricingPlans.map((item, index) =>
                <GridItem xs={12} sm={12} md={3} lg={3} key={index}>
                  <Card square sx={{
                    mx: "1px", mt: "-20px", mb: 5, textAlign: 'center',
                    borderRadius: 0,
                  }}>
                    <Box sx={{ mx: 'auto' }}>
                      <img src={item.image} style={{ margin: "auto" }} alt="Plan-Image" />
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="overline" sx={{ fontSize: 18 }}>{item.name}</Typography>
                      {item?.pricePerMonth !== null && item?.pricePerYear !== null ?
                        <>
                          <Box>
                            <Typography variant="overline" sx={{ fontSize: 14, px: 1 }}>
                              {item.currency}
                            </Typography>
                            <Typography variant="overline" sx={{ fontSize: 24, fontWeight: '600', color: "primary.main", }}>
                              {billingType == "monthly" ? item.pricePerMonth : item.pricePerYear}
                            </Typography>
                          </Box>
                          <Typography variant="caption" sx={{ color: "text.secondary" }}>
                            {billingType == "monthly"  ? " per month" : " per year" }
                          </Typography>
                        </>
                        : null
                      }
                      <Box sx={{ my: 2 }}>
                        <Button onClick={item.buttonAction}>
                          {item.buttonLabel}
                        </Button>
                      </Box>
                      <Box sx={{ textAlign: 'left', px: 2, mx: "auto" }}>
                        {item?.features ?? ''}
                      </Box>
                    </Box>
                  </Card>
                </GridItem>
              )
              }
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      <Paper>
        <GridContainer justifyContent="center" style={{ backgroundColor: '#d70001', minHeight: '15vh' }}>
        </GridContainer>
      </Paper>
      <Paper>
        <GridContainer justifyContent="center" style={{ minHeight: '70vh' }}>
          <GridItem xs={12} md={6} style={{ backgroundColor: '#f39c11' }}>
            <GridContainer justifyContent="center" alignItems="center" style={{ padding: '3rem 0', height: '100%' }}>
              <GridItem>
                <Typography variant="h5" className="text-center"  >FOR CUSTOMERS </Typography>
                <img src={customerImage} alt="Customer Icon" className="h-56 w-56 rounded-full my-5 mx-auto" />
                <Typography variant="body1" >Getting in line will never be the same again </Typography>
                <GridContainer justifyContent="center" className="my-5">
                  <Button color="success" variant="contained" onClick={onClickJoinNow}>
                    JOIN A QUEUE
                  </Button>
                </GridContainer>
                <GridContainer justifyContent="space-around" style={{ padding: '1rem 0' }}>
                  <GridItem>
                    <img src={appStoreImage} alt="Appstore" width="100" onClick={() => onClickGetApp("customer", "apple")} />
                  </GridItem>
                  <GridItem>
                    <img src={playStoreImage} alt="Playstore" width="100" onClick={() => onClickGetApp("customer", "google")} />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} md={6} style={{ backgroundColor: '#e84c3d' }}>
            <GridContainer justifyContent="center" alignItems="center" style={{ padding: '3rem 0', height: '100%' }}>
              <GridItem>
                <Typography variant="h5" className="text-center">FOR BUSINESSES</Typography>
                <img src={businessImage} alt="Business Icon" className="h-56 w-56 rounded-full my-5 mx-auto" />
                <Typography variant="body1">Easy setup. Get started for free</Typography>
                <GridContainer justifyContent="center" className="my-5">
                  <Button color="success" variant="contained" onClick={onClickRegister}>
                    REGISTER NOW
                  </Button>
                </GridContainer>
                <GridContainer justifyContent="space-around" style={{ padding: '1rem 0' }}>
                  <GridItem>
                    <img src={appStoreImage} alt="Appstore" width="100" onClick={() => onClickGetApp("business", "apple")} />
                  </GridItem>
                  <GridItem>
                    <img src={playStoreImage} alt="Playstore" width="100" onClick={() => onClickGetApp("business", "google")} />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
      {/* Footer */}
      <Footer />
    </PrimaryLayout >
  )
}

export default Landing; 