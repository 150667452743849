import { Email, Lock } from "@mui/icons-material";
import { Box, Button, CircularProgress, InputAdornment, TextField, Link } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { setShowForgotPasswordForm, setShowSignUpForm, userLogin } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";


const LoginWithPassword = () => {

    const userData = useSelector(state => state.auth.userData);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const loginFormSchema = Yup.object({
        email: Yup.string().email().required("Email is required"),
        password: Yup.string().required("Password is required").min(8, "Password should be atleast 8 characters"),
    });

    const { values, handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            email: userData?.email ?? '',
            password: '',
        },
        validationSchema: loginFormSchema,
        enableReinitialize: true,
        onSubmit: async values => {
            setIsLoading(true);
            await dispatch(userLogin(values));
            setIsLoading(false);
        }
    });

    const onClickSignup = () => {
        dispatch(setShowSignUpForm(true));
    }
    const onClickForgotPw = () => {
        dispatch(setShowForgotPasswordForm(true));
    }

    return (
        <>
            <Box sx={{ mx: 'auto', minHeight: 75 }}>
                <TextField
                    label="Email"
                    variant="standard"
                    fullWidth
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched?.email && errors?.email}
                    error={touched?.email && errors?.email ? true : false}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Email />
                            </InputAdornment>
                        ),
                    }}
                    type="email"
                    autoComplete="email"
                />
            </Box>
            <Box sx={{ mx: 'auto', minHeight: 75 }}>
                <TextField
                    label="Password"
                    variant="standard"
                    fullWidth
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched?.password && errors?.password}
                    error={touched?.password && errors?.password ? true : false}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                    }}
                    autoComplete="current-password"
                />
            </Box>
            <Box sx={{ mt: 1, mx: 'auto', display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                    sx={{ borderRadius: 2, minWidth: 120 }}
                >
                    Login
                </Button>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Link href="#" variant="body2" onClick={onClickForgotPw}>
                        Forgot Password?
                    </Link>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link href="#" variant="body2" onClick={onClickSignup}>
                        Don't have an account? Sign up
                    </Link>
                </Box>
            </Box>
        </>
    )

}

export default LoginWithPassword;