import React, { HTMLAttributes, KeyboardEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  Card,
  Dialog,
  DialogProps,
  Fade,
  Grow,
  IconButton,
  Slide,
  Typography,
  Zoom
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const styles = {
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 64px)', // 64px = theme.spacing(8)
  },
  prevButton: {
    position: 'fixed',
    left: 8, // theme.spacing(2)
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#ffffff80',
    boxShadow: 7,
    zIndex: 9999
  },
  nextButton: {
    position: 'fixed',
    right: 8, // theme.spacing(2)
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#ffffff80',
    boxShadow: 7,
    zIndex: 9999
  },
  titleCard: {
    position: 'absolute',
    bottom: 32, // theme.spacing(4)
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 16, // theme.spacing(2)
  },
};

export const LightboxBase = ({
  images,
  TransitionComponent = Fade,
  onPrevious,
  onNext,
  activeImage,
  onKeyDown,
  ...props
}) => {

  const imageRef = useRef(null);

  const handlePreviousButtonClick = useCallback(() => onPrevious(), []);
  const handleNextButtonClick = useCallback(() => onNext(), []);

  const handleKeyDown = useCallback((e) => {
    switch (e.which) {
      case 37:
        onPrevious();
        onKeyDown && onKeyDown(e);
        break;
      case 39:
        onNext();
        onKeyDown && onKeyDown(e);
        break;
      default:
        onKeyDown && onKeyDown(e);
    }
  }, []);

  return (
    <Dialog maxWidth="xl" {...props} onKeyDown={handleKeyDown} sx={{'& .MuiDialog-paper': {margin:'8px'}}}>
      {images.map(({ className, ...image }, index) => {
        if (index !== activeImage) {
          return null;
        }
        return (
          <TransitionComponent key={image.src} in={activeImage === index}>
            <div>
              <TransformWrapper>
                <TransformComponent>
                  <img
                    ref={imageRef}
                    {...image}
                    style={{ ...styles.image }}
                  />
                </TransformComponent>
              </TransformWrapper>
              {image.title && (
                <Card sx={styles.titleCard} variant="outlined">
                  <Typography variant="caption">{image.title}</Typography>
                </Card>
              )}

            </div>
          </TransitionComponent>
        );
      })}
      <IconButton sx={styles.prevButton} onClick={handlePreviousButtonClick}>
        <ChevronLeft />
      </IconButton>
      <IconButton sx={styles.nextButton} onClick={handleNextButtonClick} >
        <ChevronRight />
      </IconButton>
    </Dialog>
  );
};

export const Lightbox = (props) => {
  const { images, initialImage, onPrevious, onNext, activeImage, ...restProps } = props;

  const [selected, setSelected] = useState(initialImage || 0);

  const handlePreviousImageRequest = useCallback(() => {
    setSelected((sel) => (sel + images.length - 1) % images.length);
  }, [images]);

  const handleNextImageRequest = useCallback(() => {
    setSelected((sel) => (sel + 1) % images.length);
  }, [images]);

  useEffect(() => {
    if (!activeImage) {
      setSelected(initialImage || 0);
    }
  }, [initialImage])

  return (
    <LightboxBase
      images={images}
      onPrevious={onPrevious || handlePreviousImageRequest}
      onNext={onNext || handleNextImageRequest}
      activeImage={activeImage || selected}
      {...restProps}
    />
  );
};

export default Lightbox;
