import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isSameDay } from "../../../utils/misc";
import { LIVE_UPDATES_URL } from "../../../config/api";
import useSocket from "../../../hooks/useSocket";
import { fetchCustomerServiceStatus } from "../../../redux/actions/apiActions";

const ServiceInfo = () => {

    const { eventId, providerTimeStamp } = useParams();

    const eventDetails = useSelector(state => state.api.bookingDetails);
    const bookingDetailsLoading = useSelector(state => state.api.bookingDetailsLoading);
    const serviceStatus = useSelector(state => state.api.customerServiceStatus);

    const dispatch = useDispatch();

    const [isProvider, setIsProvider] = useState(false);
    const [isSameDayBooking, setIsSameDayBooking] = useState(null);
    const [socketChannel, setSocketChannel] = useState(null);

    const socketData = useSocket(LIVE_UPDATES_URL, socketChannel);

    // For showing Event details when provider is not connected 
    useEffect(() => {
        if (eventDetails) {
            const isSameDayBooking_ = isSameDay(eventDetails?.startSec);
            setIsSameDayBooking(isSameDayBooking_);
        } else {
            setIsSameDayBooking(null);
        }

        //Fetch Realtime monitoring data
        if (eventDetails?.startSec && isSameDay(eventDetails?.startSec)) {
            dispatch(fetchCustomerServiceStatus(eventDetails?.id));
        }
        //selecting channel for websecket subscription
        if (eventDetails?.queueDetails) {
            if (eventDetails?.queueDetails?.queueType === "Provider") {
                setSocketChannel(`/liveupdates/board/queue/${eventDetails.providerId}`)
            }
            else if (["CHECKED_IN", "STARTED"].includes(eventDetails?.status) &&
                eventDetails?.queueDetails?.queueIdentificationValue) {
                setSocketChannel(`/liveupdates/board/queue/${eventDetails?.queueDetails?.queueIdentificationValue}`)
            }
            else {
                setSocketChannel(`/liveupdates/service/${eventDetails?.serviceId}`);
            }
        }
    }, [eventDetails]);

    useEffect(() => {
        if (providerTimeStamp) {
            setIsProvider(true);
        }
    }, [])

    useEffect(() => {
        console.log("socketData", socketData);
        /* When Socket sends a message to refresh the API, Fetch the API */
        if (socketData?.refresh && eventDetails?.id) {
            dispatch(fetchCustomerServiceStatus(eventDetails?.id));
        }
    }, [socketData])

    return (
        <>
            <Box sx={{ position: "fixed", left: 0, right: 0, top: 0, zIndex: 250, backgroundColor: "#000000ba", height: '100%' }}> {/* change zIndex */}
                {isProvider ?
                    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant='overline' style={{ color: 'white' }} > Please wait for client to join </Typography>
                    </Box>
                    : (eventDetails.status == "CANCELED" || eventDetails.status == "COMPLETED") ?
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                            <Typography variant='overline'> --- </Typography>
                        </Box>
                        :
                        <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {serviceStatus && serviceStatus?.providerStart && eventDetails?.startSec ?
                                <Typography variant='body1' style={{ color: 'white' }}> Service Status :
                                    {isSameDayBooking ?
                                        <span style={{ color: '#fffbeb' }}> {serviceStatus?.providerStatus}</span>
                                        :
                                        <span style={{ color: '#ffa7a7' }}> NOT STARTED</span>
                                    }
                                </Typography>
                                :
                                null
                            }
                            {isSameDayBooking ?

                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                    {serviceStatus?.currentToken &&
                                        <Typography variant='body1' style={{ color: 'white' }}> Current Token : {serviceStatus?.currentToken} </Typography>
                                    }
                                    {serviceStatus?.nextToken &&
                                        <Typography variant='body1' style={{ color: 'white' }}> Next Token : {serviceStatus?.nextToken} </Typography>
                                    }
                                    {eventDetails?.token &&
                                        <Typography variant='body1' style={{ color: 'white' }}> Your Token : <span style={{ color: '#fffbeb' }}>{eventDetails?.token}</span> </Typography>
                                    }
                                    {eventDetails?.startSec &&
                                        <Typography variant='body1' style={{ color: 'white' }}> Your Appointment Time : <span style={{ color: '#fffbeb' }}>{moment(eventDetails?.startSec * 1000).format('hh:mm A')}</span></Typography>
                                    }
                                    <Typography variant='overline' style={{ marginTop: 50, color: 'white' }}> Please wait for provider to join</Typography>
                                </Box>
                                :
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                                    {eventDetails?.token &&
                                        <Typography variant='body1' style={{ color: 'white' }}> Your Token : {eventDetails?.token} </Typography>
                                    }
                                    <Typography variant='overline' style={{ marginTop: 20, color: 'white' }}> Your Appointment is not Today</Typography>
                                </Box>
                            }
                        </Box>
                }
            </Box>
        </>
    )
}
export default ServiceInfo;