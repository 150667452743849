import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRatingReview } from "../../redux/actions/apiActions";
import { Box, Button, Divider, IconButton, Rating, SwipeableDrawer, TextField } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Puller from "../../components/Puller/Puller";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AddReview = ({ serviceId = null, providerId = null }) => {

    const [showReviewModal, setShowReviewModal] = useState(false);
    const [customerRating, setCustomerRating] = useState(3);
    const [reviewComment, setReviewComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [isAlreadyReviewed, setIsAlreadyReviewed] = useState(false);

    const userData = useSelector(state => state.auth.userData);
    const dispatch = useDispatch();

    const { height, width } = useWindowDimensions();

    const closeReviewModal = () => {
        setShowReviewModal(false)
    }

    const submitRatingReview = async () => {
        setIsLoading(true);
        let payload = {
            customerId: userData.id,
            //bookingId: bookingId,
            rating: customerRating,
            comment: reviewComment,
            serviceId: serviceId,
            providerId: providerId,
        }
        const status = await dispatch(addRatingReview(payload));
        if (status) {
            closeReviewModal()
            setIsAlreadyReviewed(true);
        }
        setIsLoading(false);
    }

    if (!(userData?.id && (serviceId || providerId))) {
        return null;
    }


    return (
        <>
            {!isAlreadyReviewed &&
                <>
                    <Divider>
                        <Rating value={5} readOnly size={'small'} />
                    </Divider>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, mb: 2 }}>
                        <Button
                            variant="outlined"
                            onClick={() => setShowReviewModal(true)}
                            sx={{ minWidth: '50%', borderRadius: 5 }}
                        //startIcon={<Rating value={5} readOnly size={'small'} />}
                        //endIcon={<Rating value={5} readOnly size={'small'} />}
                        >
                            WRITE A REVIEW
                        </Button>
                    </Box>
                </>
            }
            <SwipeableDrawer
                anchor="bottom"
                open={showReviewModal}
                onClose={closeReviewModal}
                onOpen={() => setShowReviewModal(true)}
                swipeAreaWidth={56}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                classes={{ paperAnchorBottom: "rounded-t-2xl" }}
            >
                <Box sx={{ minHeight: height * .8, p: 1 }}>
                    <Box sx={{ position: 'relative', top: 0, left: 0, right: 0 }}>
                        <IconButton aria-label="back" size="medium" sx={{ p: 2 }} onClick={closeReviewModal} >
                            <ArrowBack fontSize="medium" />
                        </IconButton>
                        <Puller />
                    </Box>
                    <GridContainer justifyContent="center" alignItems="center" direction="row" maxWidth="md" margin="auto" sx={{ mt: 2, mb: 3 }}>
                        <GridItem xs={12}>
                            <Divider> Rate & review this service & provider </Divider>
                            <GridContainer justifyContent="center" alignItems="center" sx={{ my: 3 }}>
                                <GridItem xs={12}>
                                    <Rating
                                        name="customer-rating-contorlled"
                                        value={customerRating}
                                        precision={0.5}
                                        size="large"
                                        onChange={(event, newValue) => {
                                            setCustomerRating(newValue);
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <TextField
                                        id="customer-review"
                                        label="Provide your review"
                                        multiline
                                        rows={3}
                                        defaultValue=""
                                        variant="filled"
                                        fullWidth
                                        value={reviewComment}
                                        onChange={(e) => setReviewComment(e?.target?.value ?? "")}
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <Button variant="contained" sx={{ my: 2 }} onClick={submitRatingReview} disabled={isLoading} loading={isLoading}> Submit</Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Box>
            </SwipeableDrawer>
        </>
    )

}

export default AddReview;