import { Box, Divider, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from "@mui/material";
import { useEffect, useState } from "react";
import { Home, Inbox, Login, Logout, CalendarMonth, Person, Brightness4, Brightness7 } from '@mui/icons-material'
import { useDispatch, useSelector } from "react-redux";
import { setThemeMode } from "../../redux/actions/themeActions";
import { useNavigate } from "react-router-dom";
import ThemeModeSwitch from "../../components/CustomSwitch/ThemeModeSwitch";
import { setShowLoginForm, userLogout } from "../../redux/actions/authActions";
import GeoLocation from "./GeoLocation";

const Sidebar = ({ openDrawer, setOpenDrawer, showLocation=false }) => {

    const userData = useSelector(state => state.auth.userData)
    const orgDetails = useSelector(state => state.api.orgDetails)
    const themeMode = useSelector(state => state.theme.mode)
    const dispatch = useDispatch();
    const navigate = useNavigate();




    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' ||
                event.key === 'Shift')
        ) {
            return;
        }
        setOpenDrawer(open);
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <List onClick={toggleDrawer(false)} >
                {orgDetails?.preferences?.enableWhiteLabel ?
                    <></>
                    : <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/")}>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary={"Home"} />
                        </ListItemButton>
                    </ListItem>
                }
                {userData?.id ?
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/p/bookings")} >
                                <ListItemIcon>
                                    <CalendarMonth />
                                </ListItemIcon>
                                <ListItemText primary={"Bookings"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/p/profile")}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary={"Profile"} />
                            </ListItemButton>
                        </ListItem>
                    </>
                    : null
                }
            </List>
            <Divider />
            <List style={{ flex: 1 }} onClick={toggleDrawer(false)}>
                {userData?.id ?
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => dispatch(userLogout())}>

                            {/* () => dispatch(userLogout()) */}
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItemButton>
                    </ListItem>
                    :
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => dispatch(setShowLoginForm(true))} >
                            <ListItemIcon>
                                <Login />
                            </ListItemIcon>
                            <ListItemText primary={"Login"} />
                        </ListItemButton>
                    </ListItem>}
            </List>
            <Divider />
            {showLocation ?
                <>
                    <List style={{ flex: 1 }} onClick={toggleDrawer(false)}>
                        <ListItem disablePadding>
                            <GeoLocation onSideBar={true} />
                        </ListItem>
                    </List>

                    <Divider />
                </>
                :
                null
            }
            <Box sx={{ height: '100%' }} />
            <List className="">
                <ListItem disablePadding>
                    <FormControlLabel
                        control={
                            <ThemeModeSwitch
                                checked={themeMode == 'dark' ? true : false}
                                onChange={(e) => dispatch(setThemeMode(e?.target?.checked ? "dark" : "light"))}
                            />}
                        label="Light/Dark"
                        labelPlacement="start"
                        sx={{ px: 2 }}
                    />
                </ListItem>
            </List>
        </Box >
    );
    return (
        <SwipeableDrawer
            anchor={"right"}
            open={openDrawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >  {list("right")}
        </SwipeableDrawer>
    )
}

export default Sidebar