import { useEffect, useRef, useState } from "react";
import { useIsVisible } from "../../hooks/useIsVisible";
import { Autocomplete, Box, Card, IconButton, InputAdornment, TextField } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const SearchBar = ({ containerProps = {}, sx={}, cardProps = {}, autoComplete = false, autoCompleteProps = {}, textFieldProps = {}, sticky = false, onChange = () => { } }) => {

    const ref = useRef(null);
    const isVisible = useIsVisible(ref);
    const [position, setPosition] = useState("relative");
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(60);
    const [scrollPosition, setScrollPosition] = useState(0);

    const header = document.getElementsByTagName("header")[0];

    const onChangeSearchText = (e) => {
        setSearchText(e?.target?.value ?? "")
        onChange(e?.target?.value ?? "");
    }

    const onClearSearchText = () => {
        setSearchText("");
        onChange("");
    }

    const theme = useTheme();

    useEffect(() => {
        setPosition(isVisible ? "relative" : "fixed")
    }, [isVisible]);

    useEffect(() => {
        const headerPosition = header?.getBoundingClientRect();

        let offset_ = headerPosition?.bottom  &&  headerPosition?.bottom > 0 ? headerPosition?.bottom : 0;
        setOffset(offset_);

    }, [scrollPosition])

    useEffect(() => {
        const updatePosition = (e) => {
            setScrollPosition(window.scrollY)
        }
        window.addEventListener('scroll', updatePosition)
        updatePosition()
        return () => window.removeEventListener('scroll', updatePosition)
    }, [])

    return (
        <>
            <Box ref={ref} />
            <Box style={{minHeight:55}} {...containerProps} >
                <Box
                    sx={[
                        position !== "relative" ? { position: position, zIndex: 9999, top: 0, left: 0, right: 0 } : {},
                        {
                            transform: position !== "relative" ? `translate3d(0px, ${offset}px , 0px)` : "none",
                            transition: theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                            }),
                        },
                        sx
                    ]
                    }
                >
                    <Card {...cardProps}>
                        {autoComplete ?
                            <Autocomplete
                                autoComplete
                                // sx={{ flex: 1 }}
                                // options={options}
                                // onChange={handleSearch}
                                // getOptionLabel={(option) => option.name}
                                // noOptionsText="No matching organization"
                                {...autoCompleteProps}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <TextField
                                            id="regular"
                                            variant="standard"
                                            fullWidth
                                            sx={{ borderRadius: 5 }}
                                            InputProps={{
                                                inputProps: { ...params.inputProps },
                                                disableUnderline: true,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            color="primary"
                                                            aria-label="Search"
                                                            component="label"
                                                        >
                                                            <Search />
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            {...textFieldProps}
                                        />
                                    </div>
                                )}
                            />
                            :
                            <TextField
                                id="regular"
                                variant="standard"
                                fullWidth
                                sx={{ borderRadius: 5 }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                color="primary"
                                                aria-label="Search"
                                                component="label"
                                                onClick={onClearSearchText}
                                            >
                                                {searchText ? <Close /> : <Search />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                {...textFieldProps}
                                value={searchText}
                                onChange={onChangeSearchText}
                            />
                        }
                    </Card>
                </Box>
            </Box>
        </>
    )
}

export default SearchBar;