import { useCallback, useEffect, useState } from "react";

export function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting)
            //console.log(entry.isIntersecting)
        }, { threshold: 0 }
        );

        ref?.current && observer.observe(ref?.current);
        return () => ref?.current && observer.unobserve(ref?.current);

    }, [ref]);

    return isIntersecting;
}