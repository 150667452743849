import { Config } from "./regions";

export const themes = {
    light: {
        palette: {
            mode: 'light',
            primary: {
                light: '#f90001',
                main: '#d70001',
                dark: '#ab0203',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#ffffff',
                dark: '#ba000d',
                contrastText: '#555',
            },
            background: {
                paper: "#ffffff",
                default: Config?.value?.includes("AU") ? "#ffebf2" : "#fffbeb"
            },
            slotPicker: {
                light: '#d70001',
                main: '#d70001',
                dark: '#d70001',
                contrastText: '#fff',
            },
        }
    },
    dark: {
        palette: {
            mode: 'dark',
            primary: {
                light: '#f90001',
                main: '#ff3c3c',
                dark: '#ab0203',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
            background: {
                paper: "#111118",
                default: "#2a2a34"
            },
            slotPicker: {
                light: '#fffbeb',
                main: '#fffbeb',
                dark: '#fffbeb',
                contrastText: '#000',
            },
        },
    },
    white: {
        palette: {
            mode: 'light',
            primary: {
                light: '#f90001',
                main: '#d70001',
                dark: '#ab0203',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#ffffff',
                dark: '#ba000d',
                contrastText: '#555',
            },
            background: {
                paper: "#ffffff",
                default:"#f2f2f2"
            },
            slotPicker: {
                light: '#d70001',
                main: '#d70001',
                dark: '#d70001',
                contrastText: '#fff',
            },
        }
    },
}

export const defaultTheme = themes.light;

export default defaultTheme;