import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Skeleton } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import { useSelector } from 'react-redux';


const icon = L.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow
});


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LocationModal = ({ show, onClose, coordinates, title }) => {
  const [open, setOpen] = React.useState(false);
  const [center, setCenter] = React.useState(false);
  const themeMode = useSelector(state => state.theme.mode)

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose()
    }
  };

  const getDirections = () => {
    if (coordinates.latitude && coordinates.longitude) {
      const daddr = `${coordinates.latitude},${coordinates.longitude}`;
      const company = "google";
      window.open(`http://maps.${company}.com/maps?daddr=${daddr}`);
    }
  }

  React.useEffect(() => {
    setOpen(show);
  }, [show])

  React.useEffect(() => {
    if (coordinates?.latitude != null && coordinates?.longitude != null) {
      let center_ = [coordinates?.latitude, coordinates?.longitude]
      setCenter(center_);
    }
  }, [coordinates])

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ px: 1, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} component="div">
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={getDirections} variant="outlined" size="small">
              Get Direction
            </Button>
          </Toolbar>
        </AppBar>
        {center && open ?
          <Box sx={{ height: '100%', width: '100%' }}>
            <MapContainer center={center} zoom={15} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }} animate={true}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url={
                  themeMode === "dark" || true ?
                    "https://mt2.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                    : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                }
              />
              <Marker position={center} icon={icon}>
                <Popup>
                  {title}
                </Popup>
              </Marker>
            </MapContainer>
          </Box>
          :
          <Skeleton sx={{ height: '100%', width: '100%' }} />
        }
      </Dialog>
    </>
  );
}


export default LocationModal;