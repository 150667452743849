import { AccessTime, AccessTimeFilled, AvTimer, CalendarMonth, Clear, CloudDownload, ConfirmationNumber, Email, GpsFixed, HowToReg, LocalConvenienceStore, LocationOn, LocationOnOutlined, Mail, Person, PhoneIphone, Place, PublishedWithChanges, Repeat, ScheduleOutlined, Sell, Token, VideoCall } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, Chip, Divider, Paper, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import parseHtml from 'html-react-parser';
import { customerCheckin, fetchBookingDetails, fetchCustomerServiceStatus, fetchOrganizationById, fetchServiceById } from "../../redux/actions/apiActions";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import PrimaryLayout from "../../layout/Layout";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { formatPrice, ordinal_suffix_of } from "../../utils/misc";
import useSocket from "../../hooks/useSocket";
import { LIVE_UPDATES_URL } from "../../config/api";
import LocationModal from "../../components/LocationModal/LocationModal";
import BookingCancel from "./BookingCancel";
import BookingReschedule from "./BookingReschedule";
import ScrollDialog from "../../components/Dialog/ScrollDialog";
import { Config } from "../../config/regions";
import PaymentInfo from "./PaymentInfo";
import AddReview from "./AddReview";

const BookingDetails = () => {

    const [bookingData, setBookingData] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(true);
    const bookingDetails = useSelector(state => state.api.bookingDetails);
    const bookingDetailsLoading = useSelector(state => state.api.bookingDetailsLoading);
    const customerServiceStatus = useSelector(state => state.api.customerServiceStatus);
    const serviceDetails = useSelector(state => state.api.serviceDetails);
    const orgDetails = useSelector(state => state.api.orgDetails);
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const navigate = useNavigate();


    const [serviceStatus, setServiceStatus] = useState(null);
    const [socketChannel, setSocketChannel] = useState(null);
    const [showLocation, setShowLocation] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [locationCoords, setLocationCoords] = useState(null);

    const openLocationModal = () => {
        if (bookingData?.coordinates?.latitude && bookingData?.coordinates?.longitude) {
            setShowLocation(true);
            setLocationCoords(bookingData?.coordinates);
        }
    }
    const onCloseLocationModal = () => {
        setShowLocation(false);
        setLocationCoords(null);
    }

    const onClickDownloadPrescription = (link = "") => {
        const prescriptionLink = link ? link : `${Config.BASE_URL}/api/prescription/download?eventId=${eventId}`;
        window.open(prescriptionLink, '_blank');
        /*
        fetch(prescriptionLink)
          .then(response => {
            const filename = 'prescription.pdf'
            response.blob().then(blob => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = filename
              a.click()
            })
          })
          */
    }

    const isSameDay = unixTimestamp => moment(unixTimestamp * 1000).isSame(moment(), 'day')

    const socketData = useSocket(LIVE_UPDATES_URL, socketChannel);

    useEffect(() => {
        if (eventId /*&& bookingDetails?.id !== eventId*/) {
            dispatch(fetchBookingDetails(eventId));
        }
        setTimeout(() => setIsRefreshing(false), 1000);
    }, [])

    useEffect(() => {
        if (customerServiceStatus) {
            setServiceStatus(customerServiceStatus);
            let bookingDetails_ = customerServiceStatus?.eventById ?? {}
            setBookingData(prev => (prev?.id == bookingDetails_?.id ? { ...prev, ...bookingDetails_ } : { ...prev }))
            /* Update details on payment component if there any payment status change */
            if (bookingDetails?.id === bookingDetails_.id &&
                bookingDetails_?.paymentData?.paymentStatus && bookingDetails?.paymentData?.paymentStatus &&
                bookingDetails_?.paymentData?.paymentStatus !== bookingDetails?.paymentData?.paymentStatus
            ) {
                dispatch(fetchBookingDetails(bookingDetails?.id));
            }
        }
    }, [customerServiceStatus])

    useEffect(() => {
        console.log("socketData", socketData);
        /* When Socket sends a message to refresh the API, Fetch the API */
        if (socketData?.refresh && bookingDetails?.id) {
            dispatch(fetchCustomerServiceStatus(bookingDetails?.id));
        }
    }, [socketData])

    useEffect(() => {
        setBookingData(bookingDetails);
        if (bookingDetails?.organizationId && bookingDetails?.organizationId !== orgDetails?.id) {
            dispatch(fetchOrganizationById(bookingDetails?.organizationId));
        }
        if (bookingDetails?.startSec && isSameDay(bookingDetails?.startSec)) {
            dispatch(fetchCustomerServiceStatus(bookingDetails?.id));
        }
        if (bookingDetails?.status === "COMPLETED") {
            dispatch(fetchServiceById(bookingDetails?.serviceId));
        }
        if (bookingDetails?.queueDetails) {
            if (bookingDetails?.queueDetails?.queueType === "Provider") {
                setSocketChannel(`/liveupdates/board/queue/${bookingDetails.providerId}`)
            }
            else if (["CHECKED_IN", "STARTED"].includes(bookingDetails?.status) && bookingDetails?.queueDetails?.queueIdentificationValue) {
                setSocketChannel(`/liveupdates/board/queue/${bookingDetails?.queueDetails?.queueIdentificationValue}`)
            }
            else {
                setSocketChannel(`/liveupdates/service/${bookingDetails?.serviceId}`);
            }
        }
    }, [bookingDetails])

    const parseTokenString = (token) => {
        if (typeof token === "string") {
            return token;
        }
        if (Array.isArray(token)) {
            if (token.length <= 3) {
                return token.join(",")
            } else {
                return `${token[0]} - ${token[token.length - 1]}`
            }
        }
    }

    const onClickReCheckin = () => {
        dispatch(customerCheckin(bookingData?.id, bookingData?.customerId))
    }

    return (
        <PrimaryLayout>

            <Box sx={{ py: 3, minHeight: '100%' }}>
                {
                    !bookingData?.id || bookingDetailsLoading || isRefreshing ?
                        <GridContainer justifyContent="center" spacing={2}>
                            <GridItem xs={12} md={12}>
                                <SkeletonCard cardType="bookingDetails" />
                            </GridItem>
                        </GridContainer>
                        :
                        <>
                            <GridContainer justifyContent="center" spacing={2}>
                                <GridItem xs={12} md={12}>
                                    <Card sx={{ maxWidth: 1024, borderRadius: 5, p: 1, mx: 'auto' }} >
                                        <CardContent>
                                            <Box sx={{ mx: 'auto', textAlign: 'center', mb: 1 }}>
                                                <Typography variant="overline" sx={{ fontWeight: 'bold' }}> REFERENCE CODE</Typography>
                                                {bookingData?.bookingCode && <QRCode value={bookingData?.bookingCode} className="h-20 w-20 mx-auto" />}
                                                {bookingData?.bookingCode && <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{bookingData?.bookingCode}</Typography>}
                                            </Box>
                                            {bookingData?.status === "UNSPECIFIED" ?
                                                <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="success">
                                                    Congratulations! Your reservation request has been confirmed!
                                                </Alert>
                                                : bookingData?.status === "CHECKED_IN" ?
                                                    <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="success">
                                                        Your have checked-in for appointment!
                                                    </Alert>
                                                    : bookingData?.status === "STARTED" ?
                                                        <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="success">
                                                            Your appointment has been started!
                                                        </Alert>
                                                        : bookingData?.status === "COMPLETED" ?
                                                            <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="warning">
                                                                Your appointment has been completed!
                                                            </Alert>
                                                            : bookingData?.status === "CANCELED" ?
                                                                <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="error">
                                                                    Your appointment has been cancelled!
                                                                </Alert>
                                                                :
                                                                null
                                            }
                                            <Box sx={{ mx: 'auto', textAlign: 'center', mt: 2 }}>
                                                <Typography variant="button" sx={{ fontWeight: 'bold' }}>{bookingData?.serviceName}  {bookingData?.productName ? ` - ${bookingData?.productName}` : ''}</Typography><br />
                                                <Typography variant="overline" >{orgDetails?.name ?? ''}</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 2 }}>
                                                <Box sx={{ mx: 'auto', my: 1, textAlign: 'left' }}>
                                                    <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                        <AccessTime />{' '}
                                                        {bookingData?.startSec && bookingData?.timezoneId ?
                                                            `${moment(bookingData?.startSec * 1000).tz(bookingData?.timezoneId).format("h:mm A")} - ${moment((bookingData?.startSec + bookingData?.duration * 60) * 1000)
                                                                .tz(bookingData?.timezoneId).format("h:mm A")}`
                                                            : `N/A`
                                                        }
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <CalendarMonth /> {bookingData?.startSec && bookingData?.timezoneId ? `${moment(bookingData?.startSec * 1000).tz(bookingData?.timezoneId).format("ddd, Do MMM YYYY")}` : `N/A`}
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }} >
                                                        <GpsFixed /> {bookingData?.timezoneId ?? 'N/A'}{(moment.tz(bookingData?.timezoneId).zoneAbbr() && false ? " - " + moment.tz(bookingData?.timezoneId).zoneAbbr() : '')}
                                                    </Typography>
                                                    {bookingData?.paymentData && bookingData?.paymentData?.amount ?
                                                        <Typography sx={{ mb: 1 }} >
                                                            <Sell /> {formatPrice(bookingData?.paymentData?.amount, bookingData?.paymentData?.currency)} /-
                                                        </Typography>
                                                        : null
                                                    }
                                                </Box>
                                                <Box sx={{ mx: 'auto', my: 1, textAlign: 'left' }}>
                                                    {bookingData?.showServiceToken && bookingData?.token &&
                                                        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            <ConfirmationNumber /> Service Token:  {bookingData?.token}
                                                        </Typography>
                                                    }
                                                    {bookingData?.queueDetails?.queueType === 'Provider' &&
                                                        <Typography sx={{ mb: 1 }}>
                                                            <HowToReg /> {bookingData?.providerName ?? 'N/A'}
                                                        </Typography>
                                                    }
                                                    {bookingData?.queueDetails?.queueType === 'Counter' &&
                                                        <Typography sx={{ mb: 1 }}>
                                                            <LocalConvenienceStore /> Counter : {bookingData?.queueDetails?.queueAssignedName ? bookingData?.queueDetails?.queueAssignedName : "Unassigned"}
                                                        </Typography>
                                                    }
                                                    {bookingData?.providerTelephone && bookingData?.providerTelephone &&
                                                        <Typography sx={{ mb: 1 }} >
                                                            <PhoneIphone /> {bookingData?.providerTelephone}
                                                        </Typography>
                                                    }
                                                    {bookingData?.providerEmail && bookingData?.providerEmail &&
                                                        <Typography sx={{ mb: 1 }}>
                                                            <Email /> {bookingData?.providerEmail}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            {bookingData?.actualCustomerDetails ?
                                                <>
                                                    <Divider></Divider>
                                                    <Alert
                                                        variant="standard"
                                                        severity="info"
                                                        sx={{ borderRadius: 5, my: 1 }}
                                                    >
                                                        <AlertTitle sx={{ width: '100%' }}> Booked on behalf of </AlertTitle>
                                                        <>
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                                {bookingData?.actualCustomerDetails?.customerName &&
                                                                    <Typography sx={{ px: 2 }} >
                                                                        <Person /> {bookingData?.actualCustomerDetails?.customerName}
                                                                    </Typography>
                                                                }
                                                                {bookingData?.actualCustomerDetails?.customerPhone &&
                                                                    <Typography sx={{ px: 2 }}>
                                                                        <PhoneIphone /> {bookingData?.actualCustomerDetails?.customerPhone}
                                                                    </Typography>

                                                                }
                                                                {bookingData?.actualCustomerDetails?.customerEmail &&
                                                                    <Typography sx={{ px: 2 }}>
                                                                        <Email /> {bookingData?.actualCustomerDetails?.customerEmail}
                                                                    </Typography>
                                                                }
                                                            </Box>
                                                        </>
                                                    </Alert>
                                                </>
                                                : null

                                            }
                                            <PaymentInfo />
                                            <Divider />
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', textAlign: 'center', mt: 2 }} >
                                                {bookingData?.meetingLink && !["CANCELED", "COMPLETED"].includes(bookingData?.status) &&
                                                    <Button href={bookingData?.meetingLink} target="_blank" startIcon={<VideoCall />}>
                                                        Join Online Meeting {(/.*skype.*/.test(bookingData?.meetingLink)) ? " [ Skype ]" : (/.*teams.*/.test(bookingData?.meetingLink)) ? "[ MS Teams ]" : ""}
                                                    </Button>
                                                }
                                                <Button onClick={openLocationModal} startIcon={<Place />}>{bookingData?.fullAddress}</Button>

                                            </Box>
                                            <Box sx={{ my: 2 }}>
                                                {(/.*skype.*/.test(bookingData?.meetingLink)) && !["CANCELED", "COMPLETED"].includes(bookingData?.status) ?
                                                    <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="info">
                                                        This is an online only appointment, please ensure you have both skype application installed and a skype account
                                                    </Alert>
                                                    :
                                                    null
                                                }
                                            </Box>
                                            {
                                                isSameDay(bookingData?.startSec) && bookingData?.showServiceToken && !["CANCELED", "COMPLETED"].includes(bookingData?.status) ?
                                                    <GridItem xs={12} md={12}>
                                                        <Divider />
                                                        <Box sx={{ mb: 5 }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                                                <Typography variant="overline" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                                                    {serviceStatus?.customerFlow?.queueDetails?.queueType ?? "SERVICE"} Status :
                                                                </Typography>
                                                                <Typography
                                                                    variant="overline"
                                                                    color={serviceStatus?.serviceStatus?.providerStatus == "STARTED" ? "success.main" : "error"}
                                                                    sx={{ px: 1, fontWeight: 'bold' }}
                                                                >
                                                                    {serviceStatus?.serviceStatus?.providerStatus == "STARTED" ?
                                                                        "ONGOING"
                                                                        : serviceStatus?.serviceStatus?.providerStatus ?
                                                                            serviceStatus.serviceStatus.providerStatus
                                                                            :
                                                                            "NOT STARTED"
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                            {
                                                                /* Current Token  : Do not Show this Current token section if current token is your token or there is not current token */
                                                                (bookingData?.showServiceToken &&
                                                                    bookingData?.token &&
                                                                    serviceStatus?.serviceStatus?.currentToken?.length &&
                                                                    !serviceStatus?.serviceStatus?.currentToken?.includes(bookingData?.token)) ?
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 1, gap: 2 }}>
                                                                        <Box sx={{ minWidth: 100, textAlign: 'center' }}>
                                                                            <Typography variant="caption">CURRENT</Typography>
                                                                        </Box>
                                                                        <Box sx={{ minWidth: 200 }}>
                                                                            <Paper elevation={5} sx={{ borderRadius: 3, textAlign: 'center', p: 1 }}>
                                                                                <Box sx={{ mx: 'auto' }}>
                                                                                    <Typography variant="caption">TOKEN</Typography>
                                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{parseTokenString(serviceStatus?.serviceStatus?.currentToken)}</Typography>
                                                                                </Box>
                                                                            </Paper>
                                                                        </Box>
                                                                    </Box>
                                                                    :
                                                                    null
                                                            }
                                                            { /* Show Next Token*/
                                                                bookingData?.showServiceToken &&
                                                                    serviceStatus?.serviceStatus?.nextToken?.length &&
                                                                    (!serviceStatus?.serviceStatus?.nextToken?.includes(bookingData?.token) &&
                                                                        !serviceStatus?.serviceStatus?.currentToken?.includes(bookingData?.token)) ?
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 1, gap: 2 }}>
                                                                        <Box sx={{ minWidth: 100, textAlign: 'center' }}>
                                                                            <Typography variant="caption">NEXT</Typography>
                                                                        </Box>
                                                                        <Box sx={{ minWidth: 200 }}>
                                                                            <Paper elevation={5} sx={{ borderRadius: 3, textAlign: 'center', p: 1 }}>
                                                                                <Box sx={{ mx: 'auto' }}>
                                                                                    <Typography variant="caption">TOKEN</Typography>
                                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{parseTokenString(serviceStatus?.serviceStatus?.nextToken)}</Typography>
                                                                                </Box>
                                                                            </Paper>
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                            }
                                                            { /* Show Your Token*/
                                                                bookingData?.showServiceToken &&
                                                                    bookingData?.token && bookingData?.startSec && bookingData?.timezoneId ?
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 1, gap: 2 }}>
                                                                        <Box sx={{ minWidth: 100, textAlign: 'center' }}>
                                                                            <Typography variant="caption" >YOURS</Typography>
                                                                            {serviceStatus?.serviceStatus?.currentToken?.length && serviceStatus?.serviceStatus?.currentToken?.includes(bookingData?.token) ?
                                                                                <Typography sx={{ fontSize: 12 }}>STARTED, GET IN</Typography>
                                                                                :
                                                                                serviceStatus?.serviceStatus?.nextToken?.length && serviceStatus?.serviceStatus?.nextToken.includes(bookingData?.token) ?
                                                                                    <>
                                                                                        <Typography sx={{ fontSize: 12 }}>{serviceStatus?.serviceStatus?.providerStart ? 'Next' : 'First'}</Typography>
                                                                                        <Typography sx={{ fontSize: 12 }}>Be Ready </Typography>
                                                                                    </>
                                                                                    : serviceStatus?.customerFlow?.position && false ?
                                                                                        <Typography sx={{ fontSize: 12 }}>{ordinal_suffix_of(serviceStatus?.customerFlow?.position)} position</Typography>
                                                                                        : null
                                                                            }

                                                                        </Box>
                                                                        <Box sx={{ minWidth: 200 }}>
                                                                            <Paper elevation={5} sx={{ borderRadius: 3, textAlign: 'center', p: 1 }}>
                                                                                <Box sx={{ mx: 'auto' }}>
                                                                                    <Typography variant="caption">TOKEN</Typography>
                                                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{bookingData?.token}</Typography>
                                                                                    <Typography variant="overline" sx={{ fontWeight: 'bold' }}>
                                                                                        {bookingData?.startSec && bookingData?.timezoneId ?
                                                                                            `${moment(bookingData?.startSec * 1000).tz(bookingData?.timezoneId).format("h:mm A")} - ${moment(bookingData?.startSec * 1000 + bookingData?.duration * 60 * 1000).tz(bookingData?.timezoneId).format("h:mm A")}`
                                                                                            : `N/A`
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                                {serviceStatus?.serviceStatus?.serviceDelay ?
                                                                                    <>
                                                                                        <div>
                                                                                            <Tooltip title={`There seems to be a delay in service.` +
                                                                                                `So Estimated Time of Appointment might be ${moment((bookingData?.startSec + serviceStatus?.serviceStatus?.serviceDelay) * 1000).tz(bookingData?.timezoneId).format("h:mm A")}`}>
                                                                                                <Chip
                                                                                                    variant="outlined"
                                                                                                    color="warning"
                                                                                                    size="small"
                                                                                                    label={`ETA : ${moment((bookingData?.startSec + serviceStatus?.serviceStatus?.serviceDelay) * 1000).tz(bookingData?.timezoneId).format("h:mm A")}`}
                                                                                                    icon={<AccessTimeFilled />}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                            </Paper>
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                            }
                                                        </Box>
                                                        {
                                                            isSameDay(bookingData?.startSec) && bookingData?.showServiceToken && ["UNSPECIFIED"].includes(bookingData?.status)
                                                                && !bookingData?.meetingLink && moment().unix() > (bookingData?.startSec - 3600) ?
                                                                <Alert variant="outlined" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="warning">
                                                                    You have not checked in for the appointment. Please approach the desk for check-in.
                                                                </Alert>
                                                                :
                                                                null
                                                        }
                                                    </GridItem>
                                                    :
                                                    <></>
                                            }
                                            {bookingData?.status === "COMPLETED" && serviceDetails?.enableReviews &&
                                                <>
                                                    <AddReview serviceId={bookingData?.serviceId} providerId={bookingData.providerId} />
                                                </>
                                            }
                                            <Divider />
                                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, my: 2 }}>
                                                {bookingData?.mode !== 'QUEUE' && bookingData?.allowReschedule ?
                                                    <BookingReschedule />
                                                    : null
                                                }
                                                {bookingData?.status === "UNSPECIFIED" && bookingData?.paymentData?.paymentStatus !== "PAID" ? <BookingCancel /> : null}
                                                {bookingData?.status === "COMPLETED" && (bookingData?.prescriptionLink) ?
                                                    <Button variant="outlined" sx={{ borderRadius: 5 }} onClick={() => onClickDownloadPrescription(bookingData?.prescriptionLink)}>
                                                        <CloudDownload sx={{ px: 1, fontSize: 35 }} /> View Prescription
                                                    </Button>
                                                    : null}
                                                {bookingData?.status === "COMPLETED" && (bookingData?.reEntryOffset) && isSameDay(bookingData?.startSec) ?
                                                    <Button variant="outlined" sx={{ borderRadius: 5 }} onClick={() => onClickReCheckin()}>
                                                        <PublishedWithChanges sx={{ px: 1, fontSize: 35 }} /> Re-Entry Check-In
                                                    </Button>
                                                    : null
                                                }
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                            {orgDetails?.privacyStatement?.replace(/<[^>]*>?/gm, '')?.trim() ?
                                <Box sx={{ mt: 'auto', mx: 'auto', textAlign: 'center', pt: 2 }}>
                                    <Button variant="text" sx={{ fontSize: 12, textTransform: 'none' }} onClick={() => setShowPrivacyPolicy(true)}>
                                        Privacy Policy
                                    </Button>
                                    <ScrollDialog
                                        show={showPrivacyPolicy}
                                        onClose={() => setShowPrivacyPolicy(false)}
                                        title="Privacy Policy"
                                        content={parseHtml(orgDetails?.privacyStatement)}
                                    />
                                </Box>
                                : null
                            }
                        </>
                }
            </Box>
            <LocationModal
                show={showLocation}
                onClose={onCloseLocationModal}
                coordinates={locationCoords}
                title={<> <LocationOnOutlined /> {bookingData?.fullAddress ?? ''}</>}
            />
        </PrimaryLayout >

    )
}

export default BookingDetails;