import { Box, Card, CardActions, CardContent, CardHeader, Divider, Skeleton } from "@mui/material"
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";

export const SkeletonCard = ({ cardType = "" }) => {
    return (
        <>{
            cardType === "service" ?
                <Card sx={{ width: '100%', maxHeight: '100%', borderRadius: 5 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', position: 'relative', }}>
                        <Skeleton variant="rounded" animation="wave" sx={{ width: 150, height: 150, borderRadius: 5 }} />
                        <Box sx={{ p: 2, position: 'relative', flex: 1 }}>
                            <Skeleton variant="rounded" animation="wave" width={150} height={15} />
                            <Skeleton variant="rounded" animation="wave" width={100} height={20} sx={{ mt: 2, borderRadius: 3 }} />
                            <Skeleton variant="rounded" animation="wave" width={150} height={15} sx={{ mt: 2 }} />
                            <Skeleton variant="rounded" animation="wave" width={100} height={15} sx={{ mt: 1 }} />
                        </Box>
                        <Box sx={{ position: 'absolute', right: 0, bottom: 0, zIndex: 200, p: 1, display: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-end', }}>
                            <Skeleton variant="rounded" animation="wave" width={25} height={25} sx={{ mx: 2 }} />
                            <Skeleton variant="rounded" animation="wave" width={25} height={25} />
                        </Box>
                    </Box>
                </Card>
                : cardType === "organization" ?
                    <Card sx={{ width: { xs: 300, md: 300, xl: 400 }, borderRadius: 5 }}>
                        <Skeleton variant="rounded" animation="wave" sx={{ width: { xs: 300, md: 300, xl: 400 }, height: 150, borderRadius: 5 }} />
                        <CardActions disableSpacing sx={{ p: 2 }}>
                            <Skeleton variant="rounded" animation="wave" width={150} height={25} />
                            <Skeleton variant="rounded" animation="wave" width={25} height={25} sx={{ ml: 'auto' }} />
                            <Skeleton variant="rounded" animation="wave" width={25} height={25} sx={{ ml: 2 }} />
                        </CardActions>
                    </Card>
                    : cardType === "categories" ?
                        <Skeleton variant="rounded" animation="wave" width={120} height={30} sx={{ borderRadius: 15, marginRight: 1 }} />
                        : cardType === "schedule" ?
                            <Card sx={{ borderRadius: 5 }}>
                                <CardContent>
                                    <GridContainer alignItems='flex-start'>
                                        <GridItem xs={12} md={4} >
                                            <div className="justify-center py-5">
                                                <Skeleton variant="circular" animation="wave" sx={{ width: 100, height: 100, margin: "0 auto" }} />
                                                <Skeleton variant="text" animation="wave" sx={{ width: '80%', height: 35, margin: "0 auto" }} />
                                                <Skeleton variant="text" animation="wave" sx={{ width: '80%', height: 35, margin: "0 auto" }} />
                                                <Skeleton variant="text" animation="wave" sx={{ width: '80%', height: 35, margin: "0 auto" }} />
                                                <Skeleton variant="text" animation="wave" sx={{ width: '80%', height: 35, margin: "0 auto" }} />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={8} className="pt-10">
                                            <SkeletonCard cardType="availabilities" />
                                        </GridItem>
                                    </GridContainer>
                                </CardContent>
                            </Card>
                            : cardType === "availabilities" ?
                                <Box sx={{ borderColor: '#9e9e9e20', borderWidth: 1, borderRadius: 5, p: 2 }}>
                                    <GridContainer justifyContent="space-around" className="mb-5" spacing={1}>
                                        <GridItem>
                                            <Skeleton variant="circular" animation="wave" sx={{ width: 35, height: 35 }} />
                                        </GridItem>
                                        <GridItem>
                                            <Skeleton variant="rounded" animation="wave" sx={{ width: 150, height: 35, borderRadius: 3 }} />
                                        </GridItem>
                                        <GridItem>
                                            <Skeleton variant="circular" animation="wave" sx={{ width: 35, height: 35 }} />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={1} >
                                        {Array(12).fill().map((item, idx) =>
                                            <GridItem key={idx}>
                                                <Skeleton variant="rounded" animation="wave" width={80} height={30} sx={{ borderRadius: 15, marginRight: 1 }} />
                                            </GridItem>)
                                        }
                                    </GridContainer>
                                </Box>
                                : cardType === "bookingDetails" ?
                                    <Card sx={{ maxWidth: 1024, borderRadius: 5, p: 1, mx: 'auto' }} >
                                        <CardContent>
                                            <Box sx={{ mx: 'auto', mb: 1 }}>
                                                <Skeleton variant="rounded" animation="wave" sx={{ mx: 'auto', mt: 1, width: 120, height: 15 }} />
                                                <Skeleton variant="rounded" animation="wave" sx={{ mx: 'auto', mt: 1, width: 80, height: 80 }} />
                                                <Skeleton variant="rounded" animation="wave" sx={{ mx: 'auto', mt: 1, width: 80, height: 20 }} />
                                            </Box>
                                            <Skeleton variant="rounded" animation="wave" sx={{ mx: 'auto', my: 2, borderRadius: 5, width: '100%', height: 45 }} />
                                            <Box sx={{ mx: 'auto', textAlign: 'center', mt: 2, py: 1 }}>
                                                <Skeleton variant="rounded" animation="wave" sx={{ mx: 'auto', width: 150, height: 18 }} />
                                                <Skeleton variant="rounded" animation="wave" sx={{ mx: 'auto', mt: 1, width: 150, height: 15 }} />
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 2 }}>
                                                <Box sx={{ mx: 'auto', my: 1 }}>
                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                </Box>
                                                <Box sx={{ mx: 'auto', my: 1, textAlign: 'left' }}>
                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                </Box>
                                            </Box>
                                            <Divider />
                                        </CardContent>
                                    </Card>
                                    : cardType == "surveyForm" ?
                                        <Box sx={{ px: 2 }}>
                                            <Card sx={{ width: "100%", px: 4, py: { xs: 1, md: 2 }, mt: 2 }}>
                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 200, height: 20 }} />
                                                <Skeleton variant="rounded" animation="wave" sx={{ my: 1, width: '100%', height: 35 }} />
                                            </Card>
                                        </Box>
                                        : cardType == "bookingHistoryCardRight" ?
                                            <Card sx={{ borderRadius: 3 }} >
                                                <CardContent>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, direction: "ltr" }}>
                                                        <Box>
                                                            <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 60, height: 60 }} />
                                                            <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 60, height: 15 }} />
                                                        </Box>
                                                        <Box sx={{ flex: 1 }}>
                                                            <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 120, height: 18 }} />
                                                            <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 90, height: 12 }} />
                                                            <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 60, height: 12 }} />
                                                        </Box>
                                                    </Box>

                                                </CardContent>
                                            </Card >
                                            : cardType == "bookingHistoryCardLeft" ?
                                                <Card sx={{ borderRadius: 3 }} >
                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, direction: "rtl" }}>
                                                            <Box>
                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 60, height: 60 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 60, height: 15 }} />
                                                            </Box>
                                                            <Box sx={{ flex: 1 }}>
                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 120, height: 18 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 90, height: 12 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 60, height: 12 }} />
                                                            </Box>
                                                        </Box>

                                                    </CardContent>
                                                </Card >
                                                : cardType == "bookingTimelineHead" ?
                                                    <Box sx={{ maxWidth: "md", mx: 'auto' }}>
                                                        <Box sx={{ mt: 3 }}>
                                                            <Divider><Skeleton variant="rounded" animation="wave" sx={{ width: 120, height: 20 }} /></Divider>
                                                        </Box>
                                                    </Box>
                                                    : cardType == "bookingTimeline" ?
                                                        <Box sx={{ maxWidth: "md", mx: 'auto' }}>
                                                            <Box sx={{ mt: 0 }}>
                                                                <Timeline position="alternate">
                                                                    {Array(4).fill().map((booking, idx) =>
                                                                        <TimelineItem key={idx}>
                                                                            <TimelineSeparator>
                                                                                <TimelineDot />
                                                                                <TimelineConnector />
                                                                            </TimelineSeparator>
                                                                            <TimelineContent>
                                                                                <SkeletonCard cardType={idx % 2 == 0 ? "bookingHistoryCardRight" : "bookingHistoryCardLeft"} />
                                                                            </TimelineContent>
                                                                        </TimelineItem>
                                                                    )}
                                                                </Timeline>
                                                            </Box>
                                                        </Box>
                                                        : cardType == "rating" ?
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems:"center" }}>
                                                                <Skeleton variant="rounded" animation="wave" sx={{ width: 20, height: 20 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ width: 20, height: 20 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ width: 20, height: 20 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ width: 20, height: 20 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ width: 20, height: 20 }} />
                                                                <Skeleton variant="rounded" animation="wave" sx={{ ml: 2, width: 80, height: 14 }} />
                                                            </Box>
                                                            : cardType == "reviews" ?
                                                                <Box sx={{ maxWidth: "md", mx: 'auto' }}>
                                                                    <Box sx={{ my: 2 }}>
                                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                                            <Box sx={{ flex: 1 }}>
                                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 20, height: 20 }} />
                                                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 20, height: 20 }} />
                                                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 20, height: 20 }} />
                                                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 20, height: 20 }} />
                                                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: 20, height: 20 }} />
                                                                                    <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: "30%", minWidth: 100, height: 20 }} />
                                                                                </Box>
                                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: '100%', height: 60 }} />
                                                                                <Skeleton variant="rounded" animation="wave" sx={{ mt: 1, width: "20%", height: 15 }} />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                :
                                                                <Card sx={{ width: { xs: 300, md: 300, xl: 400 }, borderRadius: 5 }}>
                                                                    <CardHeader
                                                                        avatar={<></>}
                                                                        title={<Skeleton variant="text" animation="wave" sx={{ fontSize: '1.5rem' }} />}
                                                                    />
                                                                    <Skeleton variant="rounded" animation="wave" sx={{ width: { xs: 300, md: 300, xl: 400 }, height: 150 }} />
                                                                    <CardActions disableSpacing>
                                                                        <Skeleton variant="rectangular" animation="wave" width={30} height={30} />
                                                                    </CardActions>
                                                                </Card>

        }
        </>
    )
}

export default SkeletonCard;