import axios from "axios";
import { URL } from "../../config/urls";
import { Auth } from "aws-amplify";
import moment from "moment-timezone";
import { handleError, showAlert } from "./commonActions";
import { AWS_CONFIG } from "../../config/auth";

export const SET_USERDATA = 'SET_USERDATA';
export const SET_USERDATA_LOADING = 'SET_USERDATA_LOADING';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_SHOW_LOGIN_FORM = 'SET_SHOW_LOGIN_FORM';
export const SET_SHOW_SIGNUP_FORM = 'SET_SHOW_SIGNUP_FORM';
export const SET_SHOW_FORGOTPW_FORM = 'SET_SHOW_FORGOTPW_FORM';
export const SET_SHOW_ACCOUNT_VERIFY_FORM = 'SET_SHOW_ACCOUNT_VERIFY_FORM';
export const SET_ACCOUNT_VERIFY_DATA = 'SET_ACCOUNT_VERIFY_DATA';

const setAction = (type, payload) => ({
    type,
    payload,
});

export const createGuestUserAccount = (payload) => async (dispatch) => {
    try {
        dispatch(setAction(SET_USERDATA_LOADING, true));
        const response = await axios.post(`${URL.GUEST_USER}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_USERDATA, response.data.object || null));
            if (response?.data?.object?.token) {
                let accessToken = response?.data?.object?.token;
                dispatch(setAction(SET_AUTH_TOKEN, accessToken));
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
                localStorage.setItem('guestUserData', JSON.stringify({ token: accessToken, user: response.data.object, expiresOn: moment().add(10, 'd').unix() }));
            }
        } else {
            dispatch(setAction(SET_USERDATA, { email: payload?.email ?? '' }));
            dispatch(handleError(response?.data, "warning"));
        }
        dispatch(setAction(SET_USERDATA_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_USERDATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}
export const confirmGuestPhoneNumberOtp = (mobileNumber, otpNumber) => async (dispatch) => {
    try {
        let payload = { mobileNumber, otpNumber };
        dispatch(setAction(SET_USERDATA_LOADING, true));
        const response = await axios.post(`${URL.GUEST_VERIFY_OTP}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_USERDATA, response.data.object || null));
            const guestUserDataString = localStorage.getItem('guestUserData');
            if (guestUserDataString) {
                const guestUserData = JSON.parse(guestUserDataString);
                localStorage.setItem('guestUserData', JSON.stringify({ ...guestUserData, user: response.data.object }));
            }
        } else {
            dispatch(handleError(response?.data, "warning"));
        }
        dispatch(setAction(SET_USERDATA_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_USERDATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const setAuthToken = (token) => async (dispatch) => {
    dispatch(setAction(SET_AUTH_TOKEN, token));
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}

export const setRefreshToken = (token) => async (dispatch) => {
    dispatch(setAction(SET_REFRESH_TOKEN, token));
}

export const setSessionData = (data) => async (dispatch) => {
    dispatch(setAction(SET_SESSION_DATA, data));
    localStorage.setItem('guestUserData', "");
}

export const refreshCurrentSession = () => async (dispatch, getState) => {
    try {

        //First check Guest User data 
        const guestUserDataString = localStorage.getItem('guestUserData');
        if (guestUserDataString) {
            const guestUserData = JSON.parse(guestUserDataString);
            //console.log("guestUserData,", guestUserData)
            if (guestUserData?.token && guestUserData?.expiresOn > moment().unix()) {
                dispatch(setAction(SET_USERDATA, guestUserData.user));
                dispatch(setAuthToken(guestUserData.token));
                return;
            } else {
                dispatch(setSessionData(null));
            }
        }

        // Check Cognito session
        const cachedUserData = getState()?.auth?.userData
        if (cachedUserData && cachedUserData.userType == "GUEST") {
            dispatch(setAction(SET_USERDATA, {}));
            dispatch(setAuthToken(null));
            dispatch(setRefreshToken(null))
            return;
        };  //Bypass cognito session refresh for Guest users

        const currentSession = await Auth.currentSession();
        console.log("Refreshing Session:", currentSession);
        const { idToken: { jwtToken, payload }, refreshToken: { token } } = currentSession;
        const { exp } = payload;
        if (payload?.email_verified) {
            let userData = cachedUserData.id && cachedUserData.email == payload.email ? cachedUserData : await fetchCustomerByEmail(payload.email, jwtToken);
            dispatch(setAction(SET_USERDATA, userData));
            dispatch(setAuthToken(jwtToken));
            dispatch(setRefreshToken(token));
            dispatch(setSessionData(currentSession));
            return true;
        }
    } catch (e) {
        //Throws "No current user" if no user session 
        console.log("Error : currentSession", e)
        dispatch(setAction(SET_USERDATA, {}));
        dispatch(setAuthToken(null));
        dispatch(setRefreshToken(null));
        dispatch(setSessionData(null));

    }
}

export const userLogin = (data) => async (dispatch) => {
    try {
        dispatch(setAction(SET_USERDATA_LOADING, true));
        const { email, password } = data;
        const json = await Auth.signIn(email, password);
        console.log("User Login : json : ", json);
        const { idToken: { jwtToken, payload }, refreshToken: { token } } = json.signInUserSession;
        const { exp } = payload;
        if (payload?.email_verified) {
            let userData = await fetchCustomerByEmail(email, jwtToken);
            if (userData.id) {
                if (userData.userType === "CUSTOMER") {
                    dispatch(setAction(SET_USERDATA, userData));
                    dispatch(setAuthToken(jwtToken));
                    dispatch(setRefreshToken(token));
                    dispatch(setSessionData(json.signInUserSession));
                    dispatch(setAction(SET_SHOW_LOGIN_FORM, false));
                    dispatch(showAlert("success", "Login successful"));
                } else {
                    dispatch(showAlert("error", "This User Account is not supported. Try different one"));
                }
            } else {
                dispatch(showAlert("error", "Invalid user data"));
            }
        } else {
            dispatch(showAlert("error", "Your account is not verified"));
        }
        dispatch(setAction(SET_USERDATA_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_USERDATA_LOADING, false));
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
}

export const fetchCustomerByEmail = async (email, token) => {
    try {
        let payload = { email };
        let options = {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
                Accept: 'application/json',
            }
        };
        const response = await axios.post(`${URL.FIND_BY_MAIL}`, payload, options);
        if (response?.data?.success) {
            if (response?.data?.object) {
                return response?.data?.object;
            }
        }
    } catch (e) {
        console.log("Error: ", e);
    }
}

export const userLogout = () => async (dispatch) => {
    try {
        await Auth.signOut();
        dispatch(setAction(SET_USERDATA, {}));
        dispatch(setAuthToken(null));
        dispatch(setRefreshToken(null));
        dispatch(setSessionData(null));
        dispatch(showAlert("success", "Successfuly logged out"));
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        const status = await Auth.forgotPassword(email);
        if (status) {
            showAlert("success", "Reset Code has been sent to your email/phone number");
        }
    } catch (e) {
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const setShowLoginForm = (show) => (dispatch) => {
    if (show) {
        dispatch(setAction(SET_SHOW_SIGNUP_FORM, false));
        dispatch(setAction(SET_SHOW_FORGOTPW_FORM, false));
        dispatch(setAction(SET_SHOW_ACCOUNT_VERIFY_FORM, false));
    }
    dispatch(setAction(SET_SHOW_LOGIN_FORM, show));
}
export const setShowSignUpForm = (show) => (dispatch) => {
    if (show) {
        dispatch(setAction(SET_SHOW_LOGIN_FORM, false));
        dispatch(setAction(SET_SHOW_FORGOTPW_FORM, false));
        dispatch(setAction(SET_SHOW_ACCOUNT_VERIFY_FORM, false));
    }
    dispatch(setAction(SET_SHOW_SIGNUP_FORM, show));
}
export const setShowForgotPasswordForm = (show) => (dispatch) => {
    if (show) {
        dispatch(setAction(SET_SHOW_SIGNUP_FORM, false));
        dispatch(setAction(SET_SHOW_LOGIN_FORM, false));
        dispatch(setAction(SET_SHOW_ACCOUNT_VERIFY_FORM, false));
    }
    dispatch(setAction(SET_SHOW_FORGOTPW_FORM, show));
}

export const setShowAccountVerifyForm = (show, data = null) => (dispatch) => {
    if (show) {
        dispatch(setAction(SET_SHOW_SIGNUP_FORM, false));
        dispatch(setAction(SET_SHOW_LOGIN_FORM, false));
        dispatch(setAction(SET_SHOW_FORGOTPW_FORM, false));
        dispatch(setAction(SET_ACCOUNT_VERIFY_DATA, data));
    } else {
        dispatch(setAction(SET_ACCOUNT_VERIFY_DATA, null));
    }
    dispatch(setAction(SET_SHOW_ACCOUNT_VERIFY_FORM, show));
}

export const userSignup = (userInfo) => async dispatch => {
    try {
        dispatch(setAction(SET_USERDATA_LOADING, true));
        let payload = {
            username: userInfo.email,
            password: userInfo.password,
            attributes: {
                email: userInfo.email,
                phone_number: userInfo.telephone,
                given_name: userInfo.givenName,
                family_name: userInfo.familyName,
                preferred_username: 'CUSTOMER',
                'custom:user_type': 'CUSTOMER',
            }
        }
        const data = await Auth.signUp(payload);
        if (data) {
            dispatch(setAction(SET_SHOW_SIGNUP_FORM, false));
            if (data?.userConfirmed) {
                dispatch(userLogin({ email: userInfo.email, password: userInfo.password }));
            } else {
                let verifyData = {
                    type: "verify-signup",
                    ...userInfo
                }
                dispatch(setShowAccountVerifyForm(true, verifyData));
            }
            return true;
        } else {
            dispatch(setAction(SET_USERDATA_LOADING, false));
            dispatch(showAlert("error", "Sign up Failed"))
        }
    } catch (e) {
        dispatch(setAction(SET_USERDATA_LOADING, false));
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
}

export const resendSignUp = (email) => async dispatch => {
    try {
        const data = await Auth.resendSignUp(email);
        console.log("resendSignUp", data)
        dispatch(showAlert('success', 'Verification Code has been sent to your email'))
    } catch (e) {
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
};

export const confirmSignUp = ({ email, code, password }) => async dispatch => {
    try {
        const data = await Auth.confirmSignUp(email, code, { forceAliasCreation: true });
        console.log("confirmSignUp", data);
        if (data) {
            dispatch(setShowAccountVerifyForm(false));
            if (email && password) {
                dispatch(userLogin({ email, password }));
            }
            return true;
        } else {
            dispatch(setShowAccountVerifyForm(false));
        }
    } catch (e) {
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
};

export const requestForgotPassword = (email) => async dispatch => {
    try {
        const data = await Auth.forgotPassword(email);
        console.log("requestForgotPassword", data);
        if (data) {
            dispatch(showAlert('success', 'Verification Code has been sent to your email'))
        }
    } catch (e) {
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
}
export const forgotPasswordSubmit = (values) => async dispatch => {
    try {
        const { email, code, newPassword } = values;
        const data = await Auth.forgotPasswordSubmit(email, code, newPassword);
        console.log("forgotPasswordSubmit", data);
        if (data) {
            dispatch(setShowForgotPasswordForm(false));
            dispatch(showAlert('success', 'Password has been reset successfully!'))
            return true;
        }
    } catch (e) {
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
}

export const quickLoginRequest = (payload) => async dispatch => {
    try {
        console.log("quickLoginRequest", payload)
        //return true;
        const response = await axios.post(`${URL.OTP_LOGIN_REQUEST}`, payload);
        if (response?.data?.success) {
            dispatch(showAlert('success', 'Verification Code has been sent'));
            return true;
        } else {
            dispatch(handleError(response?.data, "warning"));
        }
        return response?.data?.success;
    } catch (e) {
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
}

export const quickLoginVerify = (payload) => async dispatch => {
    try {
        console.log("quickLoginVerify", payload)
        //return true;
        dispatch(setAction(SET_USERDATA_LOADING, true));
        const response = await axios.post(`${URL.OTP_LOGIN_VERIFY}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_USERDATA, response?.data?.object?.user || null));
            if (response?.data?.object?.token) {
                let accessToken = response?.data?.object?.token;
                dispatch(setAction(SET_AUTH_TOKEN, accessToken));
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
                dispatch(showAlert("success", "Login successful"));
                dispatch(setAction(SET_SHOW_LOGIN_FORM, false));
            }
            localStorage.setItem('guestUserData', JSON.stringify({ ...response.data.object, expiresOn: moment().add(10, 'd').unix() }));
        } else {
            dispatch(setAction(SET_USERDATA, { email: payload?.email ?? '' }));
            dispatch(handleError(response?.data, "warning"));
        }
        dispatch(setAction(SET_USERDATA_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        console.log("Error: ", e, e?.message);
        dispatch(handleError(e));
    }
}