import { useDispatch, useSelector } from "react-redux";
import PrimaryLayout from "../../layout/Layout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProductById, fetchServicesbyOrgId } from "../../redux/actions/apiActions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Alert, Box, useMediaQuery } from "@mui/material";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import ProductServiceCard from "./ProductServiceCard";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useTheme } from "@emotion/react";
import ProductCard from "./ProductCard";

const Product = () => {

    const dispatch = useDispatch();
    const productDetails = useSelector(state => state.api.productDetails);
    const serviceList = useSelector(state => state.api.serviceList);
    const productDetailsLoading = useSelector(state => state.api.productDetailsLoading);

    const { height, width } = useWindowDimensions();
    const { productId, orgRef } = useParams();

    const [combinedList, setCombinedList] = useState([]);

    const [displayList, setDisplayList] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(true);

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const handleSearch = (searchText) => {
        if (searchText) {
            let filteredServiceList = combinedList.filter(service => service?.name?.toLowerCase()?.includes(searchText?.toLowerCase()));
            setDisplayList(filteredServiceList);
        } else {
            setDisplayList([]);
        }
    }


    useEffect(() => {
        if (productDetails?.services?.length && serviceList.length) {
            let serviceDataList = productDetails.services;
            serviceDataList = serviceDataList.map(service => {
                let serviceData = serviceList.find(svc => svc?.id && svc?.id === service.serviceId)
                let output = { ...serviceData, ...service };
                output.description = service?.description ? service?.description : serviceData?.description;
                output.tags = service?.tags ? service?.tags : serviceData?.tags;
                return output
            })
            console.log(serviceDataList)
            setCombinedList(serviceDataList);
            setDisplayList(serviceDataList);
        } else {
            setDisplayList([]);
        }
    }, [productDetails, serviceList])

    useEffect(() => {
        if (productId && orgRef) {
            dispatch(fetchServicesbyOrgId(orgRef))
            dispatch(fetchProductById(productId));
            setIsRefreshing(false);
        }
    }, [productId, orgRef])

    if (!productId) {
        return (
            <>
            </>
        )
    }

    return (
        <PrimaryLayout
            title={isMdDown ? "" : productDetails?.name}
            showLocation={false}
            showOrgLogo={isMdDown ? false : true}
            showBackButton={isMdDown ? true : false}
            appBarProps={isMdDown ?
                {
                    position: 'fixed',
                    color: "transparent",
                    elevation: 0
                }
                :
                {}
            }
        >
            <GridContainer justifyContent="center" spacing={2}>
                <GridItem >
                    <Box sx={{ m: 'auto', mt: { xs: 0, md: 14.5 }, width: '100vw', maxWidth: { sm: "100%", md: '66.66%', lg: "360px" } }}>
                        <ProductCard isLoading={productDetailsLoading || isRefreshing} />
                    </Box>
                </GridItem>
                <GridItem xs={12} md={8} lg={8} xl={8} >
                    <SearchBar
                        containerProps={{ sx: { my: 2 } }}
                        sx={{ mt: '5px' }}
                        cardProps={{
                            elevation: 5,
                            sx: { borderRadius: 5, p: 1.5, px: 3, maxWidth: 'md', mx: 'auto' }
                        }}
                        textFieldProps={{
                            placeholder: "Search for service",
                            sx: { borderRadius: 5 },
                        }}

                        onChange={handleSearch}
                    />
                    {productDetailsLoading || isRefreshing ?
                        <Box className="p-5 min-h-full">
                            <GridContainer spacing={2} justifyContent="center">
                                {Array(parseInt(width / 300) * parseInt(height / 300)).fill().map((item, idx) =>
                                    <GridItem key={idx} xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <Box sx={{ p: 1 }}>
                                            <SkeletonCard cardType="service" />
                                        </Box>
                                    </GridItem>)
                                }
                            </GridContainer>
                        </Box>
                        :
                        null
                    }
                    <Box className="py-5 min-h-full">
                        <GridContainer spacing={2} justifyContent="center">
                            {displayList.length > 0 ?
                                displayList.map(serviceData =>
                                    <GridItem key={serviceData?.id} xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <Box sx={{ p: 1 }}>
                                            <ProductServiceCard serviceData={serviceData} isLoading={productDetailsLoading} />
                                        </Box>
                                    </GridItem>
                                )
                                :
                                <>
                                    <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 10 }} severity="error">No services assigned to this product</Alert>
                                </>
                            }
                        </GridContainer>
                    </Box>
                </GridItem>
            </GridContainer>
        </PrimaryLayout >
    )
}
export default Product;