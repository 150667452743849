import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../redux/actions/commonActions";

const SnackbarAlert = () => {

    const [open, setOpen] = useState(false);
    const alertData = useSelector(state => state.common.alertData);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(showAlert("error", ""));
    };

    useEffect(() => {
        if (alertData && alertData?.message) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [alertData])

    useEffect(() => {
        dispatch(showAlert("error", ""));
    }, [])

    return (
        <>
            {
                open ?
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        {...alertData?.snackProps}
                    >
                        <Alert elevation={6} variant="filled" severity={alertData?.type ?? "success"} sx={{ width: '100%' }} {...alertData?.alertProps}>
                            {alertData.message}
                        </Alert>
                    </Snackbar>
                    : null
            }
        </>

    )
}
export default SnackbarAlert;