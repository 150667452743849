import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import PrimaryLayout from '../../layout/Layout';
import TermsContent from './TermsContent';

const TermsCondition = () => {
  return (
    <PrimaryLayout showQZLogo>
      <GridContainer justifyContent="center" alignItems="center" spacing={2}>
        <GridItem md={10} lg={8}>
          <TermsContent />
        </GridItem>
      </GridContainer>
    </PrimaryLayout>
  );
};

export default TermsCondition;
