import { Box, Card, Chip, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageCarousel from "../../components/Carousel/ImageCarousel";
import { defaultProductImageUrl } from "../../config/constants";

const ProductCard = ({ isLoading = false }) => {

    const productDetails = useSelector(state => state.api.productDetails);
    const productDetailsLoading = useSelector(state => state.api.productDetailsLoading);

    const [productImages, setProductImages] = useState([]);
    const [productVideoUrls, setProductVideoUrls] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (productDetails?.id) {
            let productImages_ = [];
            productImages_ = productDetails?.productImage ? [productDetails.productImage] : [];
            productImages_ = productDetails?.displayImages?.length ?
                [...productImages_, ...productDetails?.displayImages]
                : productImages_;
            productImages_ = productImages_.filter(item => item?.fileUrl) //fiter images with valid url
            setProductImages(productImages_.length ? productImages_ : [{ fileUrl: defaultProductImageUrl }]);
            console.log("productImages_", productImages_)

            let productVideoUrls_ = productDetails?.videoUrl ? [productDetails?.videoUrl] : [];
            setProductVideoUrls(productVideoUrls_);

        }
    }, [productDetails])

    useEffect(() => {
        setShowLoader(productDetailsLoading || isLoading);
    }, [productDetailsLoading, isLoading])

    return (
        <>
            {
                showLoader ?
                    <>
                        <Card sx={{ borderRadius: { xs: 0, md: 5 }, mt: { xs: 0, md: 9.5 }, position: 'relative', width: '100%' }}>
                            <Skeleton variant="rounded" animation="wave" sx={{ maxWidth: "100%", height: 200 }} />
                            <Box sx={{ p: 2, }}>
                                <Skeleton variant="text" animation="wave" sx={{ maxWidth: 250, height: 30 }} />
                                <Box sx={{}}>
                                    <Skeleton variant="text" animation="wave" />
                                    <Skeleton variant="text" animation="wave" sx={{ maxWidth: 200 }} />
                                </Box>
                            </Box>
                        </Card>
                    </>

                    : productDetails ?
                        <>
                            <Card sx={{ borderRadius: { xs: 0, md: 5 }, position: 'relative', width: '100%' }}>
                                <ImageCarousel
                                    images={[...productImages.map((item, index) => item?.fileUrl)]}
                                    imageStyle={{ maxHeight: '200px', objectFit: 'contain' }}
                                    videos={productVideoUrls}
                                />
                                <Box sx={{ p: 2, mb: 3 }}>
                                    <Typography variant="h6">{productDetails.name}</Typography>
                                    <Box sx={{ pb: 1 }}>
                                        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>{productDetails.description}</Typography>
                                    </Box>
                                    {/*
                            <Chip
                                label={`Average Session : ${productDetails.duration} minutes`}
                                color="primary"
                                size="small"
                            />
                            */}
                                </Box>
                            </Card>
                        </>
                        : null
            }
        </>

    )
}

export default ProductCard;



