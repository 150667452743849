
import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

export const useNetworkStatus = () => {
    const [isOnline, setOnline] = useState(navigator.onLine);

    const updateNetworkStatus = async () => {
        const fetchImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url + '?' + new Date().getTime(); // Add timestamp to avoid caching

                const timeout = setTimeout(() => {
                    reject(new Error('Timeout'));
                }, 5000); // 5 seconds timeout

                img.onload = () => {
                    clearTimeout(timeout);
                    resolve(true);
                };
                img.onerror = () => {
                    clearTimeout(timeout);
                    reject(new Error('Network error'));
                };
            });
        };

        try {
            // Try fetching favicon.ico
            await fetchImage('/favicon.ico');
            setOnline(true);
        } catch (error) {
            try {
                // If favicon.ico fails, try fetching a secondary image
                await fetchImage('/logo192.png'); // Replace with the actual path to the secondary image
                setOnline(true);
            } catch (error) {
                // If both images fail, set online status to false
                setOnline(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("online", updateNetworkStatus);
        window.addEventListener("offline", updateNetworkStatus);

        // Check network status initially and periodically
        updateNetworkStatus();
        const intervalId = setInterval(updateNetworkStatus, 5000);

        //console.log("Network listeners and polling added");

        return () => {
            window.removeEventListener("online", updateNetworkStatus);
            window.removeEventListener("offline", updateNetworkStatus);
            clearInterval(intervalId);
            //console.log("Network listeners and polling removed");
        };
    }, []);

    return { isOnline };
};


const NetworkStatusMonitor = () => {
    const { isOnline } = useNetworkStatus();
    const [open, setOpen] = useState(!isOnline);

    useEffect(() => {
        console.log("isOnline changed", isOnline);
        if (isOnline) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [isOnline]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={null} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
                    No internet connection!
                </Alert>
            </Snackbar>
        </>

    );
};

export default NetworkStatusMonitor;