import { useParams } from "react-router-dom";
import SurveyModal from "../../components/SurveyModal/SurveyModal";
import { useEffect, useState } from "react";
import { fetchBookingDetails, fetchFeedbackAnswerByEventId, fetchSurveyAnswerByEventId, updateFeedbackAnswer, updateSurveyAnswer } from "../../redux/actions/apiActions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";


const AssessmentFeedback = () => {

    /* Cases : 
        1. Additional Information Survey Link after booking (whatsapp bot) 
        2. Feedback URl
    */

    const [surveyDataId, setSurveyDataId] = useState(null);
    const [surveyAnswer, setSurveyAnswer] = useState(null);
    const [surveyType, setSurveyType] = useState(null);
    const [surveyReadonly, setSurveyReadonly] = useState(false);


    const [errorMsg, setErrorMsg] = useState(null);
    const { surveyId, feedbackId, eventId } = useParams();
    const bookingDetails = useSelector(state => state.api.bookingDetails);
    const surveyAnswerData = useSelector(state => state.api.surveyAnswer);
    const feedbackAnswerData = useSelector(state => state.api.feedbackAnswer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!surveyId && !feedbackId && eventId) {
            //Survey Link on Whatsapp Booking [ /survey/event/:eventId ]
            setSurveyType("survey");
            dispatch(fetchBookingDetails(eventId));
            dispatch(fetchSurveyAnswerByEventId(eventId));
        }
        if (surveyId) {
            setSurveyType("survey");
            setSurveyDataId(surveyId);
        }
        if (feedbackId && eventId) {
            //Feedback Link  [ /feedback/:feedbackId/event/:eventId ]
            setSurveyType("feedback");
            setSurveyDataId(feedbackId);
            dispatch(fetchFeedbackAnswerByEventId(eventId));
        }
    }, [])

    useEffect(() => {
        if (bookingDetails?.id) {
            if (!surveyId && !feedbackId && bookingDetails?.surveyId) {
                setSurveyDataId(bookingDetails?.surveyId);
            } else {
                setErrorMsg("Expired or Invalid Link: No Assesment Form Available.");
            }
        }
    }, [bookingDetails])

    useEffect(() => {
        if (surveyAnswerData && surveyAnswerData.length) {
            setSurveyAnswer(surveyAnswerData[0]);
            if (surveyAnswerData[0]?.status === "COMPLETED") {
                setSurveyReadonly(true);
            }
        } else if (feedbackAnswerData) {
            setSurveyAnswer(feedbackAnswerData);
            if (feedbackAnswerData?.status === "COMPLETED") {
                setSurveyReadonly(true);
            }
        }
    }, [surveyAnswerData, feedbackAnswerData])

    const onCompleteSurvey = (assesmentData) => {
        if (surveyType === "survey") {
            let payload = {
                questionAnswers: JSON.stringify(assesmentData?.data),
                status: "COMPLETED",
                surveyId: surveyDataId,
                eventId: eventId,
                id: surveyAnswer?.id ?? null,
                participantId: surveyAnswer?.participantId ?? null
            }
            //console.log("PAYLOAD :", payload);
            dispatch(updateSurveyAnswer(payload));
        } else {
            let payload = {
                questionAnswers: JSON.stringify(assesmentData?.data),
                status: "COMPLETED",
                feedbackId: feedbackId,
                eventId: eventId
            }
            console.log("PAYLOAD :", payload);
            dispatch(updateFeedbackAnswer(payload));
        }
    }

    return (
        <>
            {surveyDataId ?
                <SurveyModal
                    show={true}
                    onComplete={onCompleteSurvey}
                    surveyId={surveyDataId}
                    surveyAnswer={surveyAnswer?.questionAnswers ? JSON.parse(surveyAnswer?.questionAnswers) : false}
                    readonly={surveyReadonly}
                    showCompletedPage={true}
                    completeText="DONE"
                />
                : null
            }
            {errorMsg ?
                <><Alert severity="error" sx={{ justifyContent: 'center', my: 10 }}>{errorMsg}</Alert></>
                : null
            }
        </>


    )
}

export default AssessmentFeedback;