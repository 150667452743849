import { Box, Link, Paper, Typography } from "@mui/material";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { useTheme } from '@emotion/react';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';

import quezoneLogoLight from '../../assets/images/quezone-logo-light.png';
import quezoneLogoDark from '../../assets/images/quezone-logo.png';
import { Config } from "../../config/regions";

const Footer = () => {
    const theme = useTheme();
    return (
        <>
            <Paper sx={{ pt: 5, px: 2 }} className='texture-shattered'>
                <GridContainer justifyContent="center" >
                    <GridItem xs={12} md={10} lg={10} xl={8}>
                        <GridContainer justifyContent="center" spacing={5}>
                            <GridItem xs={12} sm={3}>
                                <Typography variant="h6" color="text.primary" gutterBottom>
                                    About Us
                                </Typography>
                                <Box sx={{ py: 1 }}>
                                    <img src={theme?.palette?.mode === "light" ? quezoneLogoLight : quezoneLogoDark} alt="Quezone Logo" width="100" />
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    Quezone is the ultimate solution for your queuing and appointment management needs.
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <Typography variant="h6" color="text.primary" gutterBottom>
                                    Contact Us
                                </Typography>
                                <Typography variant="body2" color="text.secondary">

                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Email: info@quezone.in
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Phone: +1 234 567 8901
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <Typography variant="h6" color="text.primary" gutterBottom>
                                    Useful Links
                                </Typography>
                                <Typography variant="body2" color="text.secondary">

                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <a href="https://cdn.que.zone/tutorials" target="_blank">Tutorials </a>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <a href="/p/help-desk" target="_blank" >Help Desk </a>
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <Typography variant="h6" color="text.primary" gutterBottom>
                                    Follow Us
                                </Typography>
                                <Link href="https://www.facebook.com/" color="inherit">
                                    <Facebook />
                                </Link>
                                <Link
                                    href="https://www.instagram.com/"
                                    color="inherit"
                                    sx={{ px: 2 }}
                                >
                                    <Instagram />
                                </Link>
                                <Link href="https://www.twitter.com/" color="inherit">
                                    <Twitter />
                                </Link>
                            </GridItem>

                        </GridContainer>
                        <Box mt={5}>
                            <Typography variant="body2" color="text.secondary" align="center">
                                {"Copyright © "}
                                <Link color="inherit" href={Config.domainUrl}>
                                    Quezone
                                </Link>{" "}
                                {new Date().getFullYear()}
                                {"."}
                            </Typography>
                        </Box>
                    </GridItem>
                </GridContainer>
            </Paper >
        </>
    )
}

export default Footer;