import { Box, Button, CircularProgress, InputAdornment, Link, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput"
import { Email, PhoneAndroidOutlined } from "@mui/icons-material"
import { useState } from "react";
import { quickLoginRequest, quickLoginVerify } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
import OtpInput from "../../components/OtpInput/OtpInput";

const LoginWithOTP = () => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [otpMedium, setOtpMedium] = useState('phone');

    const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    const loginFormSchema = Yup.object({
        telephone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        otp: Yup.string().min(6, 'OTP is 6 digit number').max(6, 'OTP is 6 digit number'),
        email: Yup.string().email("Invalid Email")
    });

    const { values, handleChange, handleBlur, setFieldValue, resetForm, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            email: '',
            telephone: '',
            otp: '',
            isOtpRequested: false,
        },
        validationSchema: loginFormSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setFieldValue, resetForm, setFieldError }) => {
            setIsLoading(true);
            if (!values.isOtpRequested) {
                if (!values?.email && !values.telephone) {
                    setFieldError("email", "Email is required");
                    setFieldError("telephone", "Phone number is required");
                    setIsLoading(false);
                    return;
                }
                const status = await dispatch(quickLoginRequest(values));
                if (status) {
                    setFieldValue("isOtpRequested", true);
                }
            } else {
                const status = await dispatch(quickLoginVerify(values));
                if (status) {
                    resetForm();
                }
            }
            setIsLoading(false);
        }
    });

    return (
        <>
            {!values.isOtpRequested ?
                <>
                    <Box sx={{ mx: 'auto' }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={otpMedium}
                            exclusive
                            onChange={(e, medium) => medium ? setOtpMedium(medium) : null}
                            aria-label="Choose Email or Phone Number for sending OTP"
                            fullWidth
                        >
                            <ToggleButton value="phone" sx={{ borderRadius: 5 }}>Phone</ToggleButton>
                            <ToggleButton value="email" sx={{ borderRadius: 5 }}>Email</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75, my: 4 }}>
                        {otpMedium == 'phone' ?
                            <PhoneNumberInput
                                label="Phone Number"
                                variant="standard"
                                fullWidth
                                name="telephone"
                                onChange={telephone => setFieldValue('telephone', telephone)}
                                value={values.telephone}
                                helperText={touched?.telephone && errors?.telephone}
                                error={touched?.telephone && errors?.telephone ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneAndroidOutlined />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            :
                            <TextField
                                label="Email"
                                variant="standard"
                                fullWidth
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                helperText={touched?.email && errors?.email}
                                error={touched?.email && errors?.email ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Email />
                                        </InputAdornment>
                                    ),
                                }}
                                type="email"
                                autoComplete="email"
                            />
                        }
                    </Box>
                    <Box sx={{ mt: 1, mx: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading}
                            endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                            sx={{ borderRadius: 2, minWidth: 120 }}
                        >
                            Request OTP
                        </Button>
                    </Box>
                </>
                :
                <>
                    <Box sx={{ mx: 'auto', minHeight: 75, my: 4 }}>
                        <OtpInput
                            value={values.otp}
                            onChange={(otp) => setFieldValue("otp", otp)}
                        />
                    </Box>
                    <Box sx={{ mt: 1, mx: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading}
                            endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                            sx={{ borderRadius: 2, minWidth: 120 }}
                        >
                            Verify OTP
                        </Button>
                    </Box>
                </>
            }
        </>
    )
}

export default LoginWithOTP;
