import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_CAPTCHA_SITE_KEY } from "../../config/auth";
import { useEffect, useRef, useState } from "react";
import Recaptcha from 'react-google-invisible-recaptcha'
import { TextField, Button, Container, Typography, Paper, Card, CardContent, CircularProgress, Box } from '@mui/material';
import * as Yup from 'yup';

import quezoneLogoLight from '../../assets/images/quezone-logo-light.png';
import quezoneLogoDark from '../../assets/images/quezone-logo.png';
import { useTheme } from "@emotion/react";
import axios from "axios";
import { URL } from "../../config/urls";
import { showAlert } from "../../redux/actions/commonActions";
import SnackbarAlert from "../../components/Alert/SnackbarAlert";


const HelpDesk = () => {

    const userData = useSelector(state => state.auth.userData);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaResolved, setCaptchaResolved] = useState(false);
    const recaptchaRef = useRef(null);
    const dispatch = useDispatch();
    const theme = useTheme();

    const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required('Full name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        subject: Yup.string()
            .required('Subject is required'),
        message: Yup.string()
            .required('Message is required'),
    });

    const { values, handleChange, handleSubmit, errors, touched, handleBlur, setFieldValue, isSubmitting, resetForm } = useFormik({
        initialValues: {
            fullName: userData?.givenName ?? "",
            telephone: userData?.telephone ?? "",
            email: userData?.email ?? "",
            subject: "",
            message: ""
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            setIsLoading(true);
            try {
                const response = await axios.post(URL.SUPPORT_REQUEST, values);
                if (response?.data?.success) {
                    dispatch(showAlert("success", "Request submitted successfully"));
                    resetForm();
                } else {
                    dispatch(showAlert("error", "Something went wrong. Please try again later"));
                }
                setIsLoading(false);
            } catch (error) {
                console.log("Submit Error!!:", error);
                dispatch(showAlert("error", "Something went wrong. Please try again later"));
                setIsLoading(false);
            }
        }
    });

    useEffect(() => {
        if (!captchaResolved && recaptchaRef?.current?.execute) {
            recaptchaRef?.current?.execute();
        }
    }, [values])

    return (
        <>
            <Recaptcha
                ref={recaptchaRef}
                sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                onResolved={(e) => {
                    setCaptchaResolved(true);
                    console.log("captch resolved", e);
                }}
                style={{ position: "absolute", top: 0 }}
            />
            <Container maxWidth="sm">
                <Card sx={{ my: 5 }}>
                    <CardContent>
                        <Box sx={{ my: 5 }}>
                            <img src={theme?.palette?.mode === "light" ? quezoneLogoLight : quezoneLogoDark} alt="Quezone Logo" width="200" />
                        </Box>
                        <Typography variant="h5" component="h5" gutterBottom>
                            Submit a Request for Assistance
                        </Typography>
                        <Typography variant="caption">
                            Please complete the form below detailing your request. Our support staff will respond as soon as possible through provided email
                        </Typography>
                        <Box sx={{ my: 1 }}>
                            <TextField
                                name="fullName"
                                label="Full Name"
                                placeholder="provide your full name"
                                variant="filled"
                                error={touched.fullName && !!errors.fullName}
                                helperText={touched.fullName && errors.fullName}
                                value={values.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="provide your email address"
                                variant="filled"
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                type="subject"
                                name="subject"
                                label="Subject"
                                placeholder="please enter your request in few words"
                                variant="filled"
                                error={touched.subject && !!errors.subject}
                                helperText={touched.subject && errors.subject}
                                value={values.subject}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                            />
                            <TextField
                                name="message"
                                label="Message"
                                variant="filled"
                                placeholder="describe your request in detail"
                                multiline
                                rows={4}
                                error={touched.message && !!errors.message}
                                helperText={touched.message && errors.message}
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                inputProps={{ maxLength: 500 }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || !captchaResolved}
                                sx={{ my: 2 }}
                                endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                                onClick={handleSubmit}
                            >
                                Submit Message
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <SnackbarAlert />
        </>
    )
}
export default HelpDesk;



