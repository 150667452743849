import styled from "@emotion/styled";
import { ExpandMore, Favorite, MoreVert, Share } from "@mui/icons-material"
import { Avatar, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, Typography } from "@mui/material"
import { useState } from "react";
import { Link } from "react-router-dom"
import { Config } from "../../config/regions";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import { defaultOrgImageUrl, defaultOrgLogoUrl } from "../../config/constants";

const ExpandMoreIcon = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const OrganizationCard = ({ organizationData, isLoading }) => {

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleShareClick = async () => {
        if (navigator.share) {
            const shareDetails = { url: `${Config.domainUrl}/${organizationData.orgRef}`, text: organizationData.name };
            try {
                await navigator.share(shareDetails);
            } catch (error) {
                console.log(`Failed to Share`, error);
            }
        } else {
            console.log("Web share is currently not supported on this browser.");
        }
    };

    return (
        <>
            {isLoading ?
                <SkeletonCard cardType="organization" />
                :
                <Card sx={{ width: { xs: 300, md: 300, xl: 400 }, borderRadius: 5 }}>
                    <CardActionArea component={Link} to={`/${organizationData?.orgRef}`}>
                        <CardMedia
                            component="img"
                            height="100"
                            image={organizationData?.advPic?.fileUrl ? organizationData?.advPic?.fileUrl : defaultOrgImageUrl}
                            alt="Business Image"
                            sx={{ height: 150, objectFit: 'cover', borderRadius: 5 }}
                        />
                    </CardActionArea>
                    <CardActions disableSpacing>
                        <Link to={`/${organizationData?.orgRef}`}>
                            <Typography variant="body2" sx={{ ml: 1 }}>
                                {organizationData?.name}
                            </Typography>
                        </Link>
                        <ExpandMoreIcon
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMore />
                        </ExpandMoreIcon>
                        <IconButton aria-label="share" onClick={handleShareClick}>
                            <Share />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography variant="caption" paragraph>{organizationData.description}</Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            }
        </>
    )
}
export default OrganizationCard