import { Box, Tab, Tabs } from "@mui/material"

const ToggleTabSwitch = ({ onChange, value, tabs, TabIndicatorProps = {}, indicatorColor, sx }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Tabs
                onChange={onChange}
                value={value}
                centered
                indicatorColor={indicatorColor ?? "primary"}
                TabIndicatorProps={{ ...TabIndicatorProps, sx: { height: '100%', borderRadius: 10, zIndex: 9, ...(TabIndicatorProps?.sx ?? {}) } }}
                sx={sx ?? { minHeight: 30 }}
            >
                {tabs.map((item, index) =>
                    <Tab
                        key={index}
                        label={item?.label ?? `Tab ${index}`}
                        value={item?.value ?? `value-${index}`}
                        disableRipple
                        icon={item?.icon ?? undefined}
                        iconPosition={item?.iconPosition ?? "start"}
                        {...item}
                        sx={{ zIndex: 10, minHeight: '20px', padding: '10px', ...(item?.sx ?? {}) }}

                    />
                )}
            </Tabs>
        </Box>
    )
}

export default ToggleTabSwitch;

