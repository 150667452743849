import { Alert, AlertTitle, Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SwipeableDrawer, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import moment from "moment-timezone";
import { URL } from "../../config/urls";
import axios from "axios";
import { fetchBookingDetails } from "../../redux/actions/apiActions";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";

const PaymentInfo = () => {
    const bookingDetails = useSelector(state => state.api.bookingDetails);
    const userData = useSelector(state => state.auth.userData);
    const dispatch = useDispatch();
    const theme = useTheme();

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentData, setPaymentData] = useState({});
    const [selectedMethod, setSelectedMethod] = useState("");
    const [showGatewayModal, setShowGatewayModal] = useState(false);
    const [isPaypalScriptLoaded, setIsPaypalScriptLoaded] = useState(false);
    const closeGatewayModal = () => {
        setShowGatewayModal(false);
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const handleRazorpayCallback = async (payload) => {
        try {
            const response = await axios.post(URL.RAZORPAY_CALLBACK, payload);
            //if (response?.data?.success) {
            closeGatewayModal();
            dispatch(fetchBookingDetails(bookingDetails?.id));
            document.body.style.overflow='' // Fix for scroll freeze after payment success redirection
            //}
        } catch (e) {
            console.log("Error [handleRazorpayCallback] :", e)
        }
    }
    const handlePaypalCallback = async (payload) => {
        try {
            const response = await axios.post(URL.PAYPAL_CALLBACK, payload);
            //if (response?.data?.success) {
            closeGatewayModal();
            dispatch(fetchBookingDetails(bookingDetails?.id));
            //}
        } catch (e) {
            console.log("Error [handleRazorpayCallback] :", e)
        }
    }

    const handleOfflineMethod = async () => {
        try {
            const response = await axios.post(`${bookingDetails?.id}/${URL.PAYMENT_MODE}`, "offline");
            //if (response?.data?.success) {
            closeGatewayModal();
            dispatch(fetchBookingDetails(bookingDetails?.id));
            //}
        } catch (e) {
            console.log("Error [handleRazorpayCallback] :", e)
        }
    }

    const payWithRazorpay = () => {
        if (window?.Razorpay) {
            const options = {
                key: paymentData?.razorpay?.keyId, // Enter the Key ID generated from the Dashboard
                amount: ((paymentData?.amount ?? 0) * 100).toString(),
                currency: paymentData?.currency,
                name: bookingDetails?.organizationName ?? "Quezone",
                description: `${bookingDetails?.serviceName}`,
                image: bookingDetails?.orgLogo ?? "",
                order_id: paymentData?.razorpay?.orderId,
                //callback_url : paymentData?.razorpay?.callbackUrl,
                handler: (response) => {
                    const payload = {
                        orderId: paymentData?.razorpay?.orderId,
                        eventId: bookingDetails?.id,
                        ...response
                    }
                    handleRazorpayCallback(payload);
                },
                prefill: {
                    name: userData?.givenName ?? "",
                    email: userData?.email ?? "",
                    contact: userData?.telephone ?? "",
                },
                theme: {
                    color: theme?.palette?.primary?.main ?? "#00f",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } else {
            console.warn("Razorpay script is not loaded !!")
        }
    }
    const initializePaypalButton = () => {
        if (isPaypalScriptLoaded && window?.paypal) {
            try {
                window.paypal.Buttons({
                    style: {
                        layout: 'vertical', // horizontal or vertical
                        color: 'gold', // Specify the button color: gold, blue, silver, white, black
                        shape: 'pill', // rectangular, pill, or rect
                        label: 'paypal', // paypal or checkout
                        height: 40, // button height in pixels
                    },
                    createOrder: (data, actions) => {
                        return paymentData?.paypal?.orderId; // Use the existing order ID
                    },
                    onApprove: (data, actions) => {
                        handlePaypalCallback(data);
                        //console.log("Paypal Payment Approved", data, actions)
                    }
                }).render('#paypal-button-container');
            } catch (e) {
                console.log("Paypal Script error !!", e)
            }
        }
    }

    useEffect(() => {
        const paymentData_ = bookingDetails?.paymentData ?? {};
        let paymentMethods_ = paymentData_?.paymentMethods ?? [];
        paymentMethods_ = paymentMethods_.filter(item => ((paymentData_[item] && paymentData_[item]?.orderId) || item == "offline"));

        const paymentStatus_ = paymentData_?.paymentStatus ?? "";
        const paymentMode_ = paymentData_?.paymentMode ?? "";

        setSelectedMethod(paymentMode_ ? paymentMode_ : (paymentMethods_?.length ? paymentMethods_[0] : ""));
        setPaymentData(paymentData_);
        setPaymentMethods(paymentMethods_);
        setPaymentStatus(paymentStatus_);
        if (paymentMethods_?.filter(item => item !== "offline")?.length > 0 && paymentStatus_ !== "PAID") {
            const autoCancelled = paymentData_?.autoCancelTime && paymentData_?.autoCancelTime < moment().unix();
            const chosenToPayOffline = paymentMode_ === "offline" ? true : false;
            if (!autoCancelled && !chosenToPayOffline) {
                setShowGatewayModal(true);
            }
        }

        if (paymentMethods_.includes("paypal") && paymentStatus_ !== "PAID") {
            const currency = paymentData_?.currency ?? "INR";
            const clientId = paymentData_?.paypal?.clientId ?? "";
            if (clientId) {
                loadScript(`https://www.paypal.com/sdk/js?client-id=${clientId}`).then(() => setIsPaypalScriptLoaded(true));
            }
        }
    }, [bookingDetails])

    useEffect(() => {
        if (isPaypalScriptLoaded && selectedMethod == "paypal") {
            initializePaypalButton()
        }
    }, [selectedMethod, isPaypalScriptLoaded]);

    /* Re render paypal buttons on modal close/open */
    useEffect(() => {
        if (showGatewayModal && selectedMethod == "paypal") {
            setTimeout(() => initializePaypalButton(), 50);
        }
    }, [showGatewayModal]);

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, []);

    return (
        <>
            {bookingDetails?.status !== "CANCELED" && paymentData?.amount && paymentMethods?.filter(item => item !== "offline")?.length > 0 ?
                <>
                    <Divider />
                    <Box sx={{ my: 1 }}>
                        {paymentStatus == "PAID" ?
                            <Alert variant="standard" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="success">
                                Payment acknowledged for this service
                            </Alert>
                            :
                            <Alert
                                variant="standard"
                                sx={{ justifyContent: 'center', borderRadius: 5 }}
                                severity="warning"
                                action={
                                    <Button
                                        color="inherit"
                                        size="small"
                                        onClick={() => setShowGatewayModal(true)} sx={{ textTransform: "none" }}
                                        disabled={paymentData?.autoCancelTime && paymentData?.autoCancelTime < moment().unix()}
                                    >
                                        Payment Options
                                    </Button>
                                }
                            >
                                <AlertTitle> Payment pending</AlertTitle>
                                {paymentData?.paymentMode?.includes("offline") ?
                                    <>
                                        <b>You have choosen to pay offline.</b><br />
                                        {paymentData?.autoCancelTime > moment().unix() ?
                                            <>
                                                You can still pay online. Online payment options will be valid till {moment(paymentData?.autoCancelTime * 1000).tz(bookingDetails?.timezoneId).format("h:mm A, Do MMM")}
                                            </>
                                            : null
                                        }
                                    </>
                                    : paymentData?.autoCancelTime ?
                                        <>
                                            Your payment has not been confirmed yet. <br />
                                            {` Please complete the payment before ${moment(paymentData?.autoCancelTime * 1000).tz(bookingDetails?.timezoneId).format("h:mm A, Do MMM")}, else the booking will be cancelled automatically`}

                                        </>
                                        :
                                        <>
                                            Your payment has not been confirmed yet. <br />
                                            Please complete the payment as soon as possible
                                        </>

                                }
                            </Alert>
                        }
                    </Box>
                    <SwipeableDrawer
                        anchor="bottom"
                        open={showGatewayModal}
                        onClose={closeGatewayModal}
                        onOpen={() => setShowGatewayModal(true)}
                        swipeAreaWidth={56}
                        disableSwipeToOpen={true}
                        ModalProps={{
                            keepMounted: false,
                        }}
                        classes={{ paperAnchorBottom: "rounded-t-2xl" }}
                    >
                        <Box sx={{ p: 2, my: 2, mx: 'auto', width: "100%", minHeight: "70vh" }} maxWidth="md">
                            <Puller />
                            <Typography variant="standard" >Payment Options</Typography>
                            <GridContainer justifyContent="space-between" alignItems='stretch' spacing={2} sx={{ mt: 0 }}>
                                <GridItem xs={12} md={6}>
                                    <Card
                                        sx={{ background: theme?.palette?.background?.default ?? "#fff", height: '100%', borderRadius: 3 }}
                                    >
                                        <CardContent>
                                            <FormControl>
                                                <FormLabel id="payment-methods-label">Choose Payment Method</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="payment-methods-label"
                                                    name="payment-methods-group"
                                                    sx={{ my: 3 }}
                                                    value={selectedMethod}
                                                    onChange={(e) => setSelectedMethod(e.target.value)}

                                                >
                                                    {paymentMethods.map((gateway, index) =>
                                                        <FormControlLabel value={gateway} control={<Radio />} label={gateway.replace(/\b\w/g, char => char.toUpperCase())} key={index} />
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        </CardContent>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    <Card
                                        sx={{ background: theme?.palette?.background?.default ?? "#fff", height: '100%', borderRadius: 3 }}
                                    >
                                        <CardContent>
                                            <Typography variant="h6">
                                                Summary
                                            </Typography>
                                            <Box sx={{ my: 2 }}>
                                                <GridContainer justifyContent="space-between" sx={{ mt: 1 }}>
                                                    <GridItem xs={6}>
                                                        <Typography variant="body2">Service </Typography>
                                                    </GridItem>
                                                    <GridItem xs={6}>
                                                        <Typography variant="body2">{bookingDetails.serviceName}</Typography>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer justifyContent="space-between" sx={{ mt: 1 }}>
                                                    <GridItem xs={6}>
                                                        <Typography variant="body2">Slot </Typography>
                                                    </GridItem>
                                                    <GridItem xs={6}>
                                                        <Typography variant="body2">
                                                            {`${moment(bookingDetails?.startSec * 1000).tz(bookingDetails?.timezoneId).format("h:mm A")} ${moment.tz(bookingDetails?.timezoneId).zoneAbbr() ?? ''}`}
                                                        </Typography>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer justifyContent="space-between" sx={{ mt: 1 }}>
                                                    <GridItem xs={6}>
                                                        <Typography variant="body2">Date </Typography>
                                                    </GridItem>
                                                    <GridItem xs={6}>
                                                        <Typography variant="body2">
                                                            {`${moment(bookingDetails?.startSec * 1000).tz(bookingDetails?.timezoneId).format("ddd, Do MMM YYYY")}`}
                                                        </Typography>
                                                    </GridItem>
                                                </GridContainer>
                                                {paymentData[selectedMethod] && paymentData[selectedMethod]?.orderId ?
                                                    <GridContainer justifyContent="space-between" sx={{ mt: 1 }}>
                                                        <GridItem xs={6}>
                                                            <Typography variant="body2">Order ID </Typography>
                                                        </GridItem>
                                                        <GridItem xs={6}>
                                                            <Typography variant="body2" style={{ wordBreak: "break-all" }}>{paymentData[selectedMethod]?.orderId}</Typography>
                                                        </GridItem>
                                                    </GridContainer>
                                                    :
                                                    <GridContainer justifyContent="space-between" sx={{ mt: 1 }}>
                                                        <GridItem xs={6}>
                                                            <Typography variant="body2">Booking Code </Typography>
                                                        </GridItem>
                                                        <GridItem xs={6}>
                                                            <Typography variant="body2">{bookingDetails?.bookingCode}</Typography>
                                                        </GridItem>
                                                    </GridContainer>
                                                }
                                            </Box>
                                            <Divider />
                                            <Box sx={{ my: 2 }}>
                                                <GridContainer justifyContent="space-between" >
                                                    <GridItem xs={6}>
                                                        <Typography variant="body1" >Amount </Typography>
                                                    </GridItem>
                                                    <GridItem xs={6}>
                                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{paymentData?.amount} {paymentData?.currency} </Typography>
                                                    </GridItem>
                                                </GridContainer>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ my: 2, mx: "auto" }}>
                                                <GridContainer justifyContent="center">
                                                    <GridItem>
                                                        {selectedMethod == "razorpay" ?
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={payWithRazorpay}
                                                                    sx={{ borderRadius: 5, height: 40, minWidth: 150 }}
                                                                >
                                                                    Pay Now
                                                                </Button>
                                                            </>
                                                            : selectedMethod == "paypal" ?
                                                                <>
                                                                    <div id="paypal-button-container"></div>
                                                                </>
                                                                : selectedMethod == "offline" ?
                                                                    <>
                                                                        <Alert variant="standard" sx={{ justifyContent: 'center', borderRadius: 5 }} severity="info">
                                                                            You can pay for the service at the premise
                                                                        </Alert>
                                                                        {paymentData?.paymentMode !== "offline" ?
                                                                            <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    onClick={handleOfflineMethod}
                                                                                    sx={{ borderRadius: 5, height: 40, minWidth: 150 }}
                                                                                >
                                                                                    Choose to Pay Offline
                                                                                </Button>
                                                                            </Box>
                                                                            : null
                                                                        }

                                                                    </>
                                                                    : null
                                                        }
                                                    </GridItem>
                                                </GridContainer>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </Box>
                    </SwipeableDrawer>
                </>
                :
                null
            }
        </>
    )
}

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

export default PaymentInfo;