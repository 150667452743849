import { Clear, Repeat } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { useState } from "react";
import { cancelBooking } from "../../redux/actions/apiActions";
import { showAlert } from "../../redux/actions/commonActions";
import { setShowLoginForm } from "../../redux/actions/authActions";

const BookingCancel = () => {

    const [showDialog, setShowDialog] = useState(false);
    const userData = useSelector(state => state.auth.userData);
    const bookingDetails = useSelector(state => state.api.bookingDetails);
    const dispatch = useDispatch();

    const onCloseDialog = () => {
        setShowDialog(false);
    }
    const onConfirmDialog = () => {
        setShowDialog(false);
        if (bookingDetails?.id) {
            dispatch(cancelBooking(bookingDetails?.id));
        }
    }

    const handleButton = () => {
        const isAuthorisedUser = userData?.id &&
            (
                userData?.id === bookingDetails?.customerId ||
                userData?.email === bookingDetails?.customerEmail ||
                userData?.telephone === bookingDetails?.customerPhoneNumber
            );

        if (isAuthorisedUser) {
            setShowDialog(true)
        } else {
            if (userData?.id) {
                dispatch(showAlert("error", "You are not allowed to cancel this booking. Please login using Email/Phone number that used to book this appointment."))
            } else {
                dispatch(setShowLoginForm(true));
            }
        }
    }

    return (
        <>
            <Button variant="outlined" sx={{ borderRadius: 5 }} onClick={handleButton}>
                <Clear />  Cancel
            </Button>
            <ConfirmationDialog
                show={showDialog}
                title={<Typography variant="overline">Booking Cancellation</Typography>}
                content="Do you want to cancel the booking ?"
                onClose={onCloseDialog}
                onConfirm={onConfirmDialog}
                onCancel={onCloseDialog}
            />
        </>
    )
}

export default BookingCancel;