import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const GridContainer = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={className} >
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default GridContainer;