import axios from "axios";
import { URL } from "../../config/urls";
import { handleError, setShowUserForm, showAlert } from "./commonActions";
import { STRINGS } from "../../config/constants";
import { SET_USERDATA } from "./authActions";

export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_ORGANIZATIONS_LOADING = 'SET_ORGANIZATIONS_LOADING';

export const SET_BUSINESS_CATEGORIES = 'SET_BUSINESS_CATEGORIES';
export const SET_BUSINESS_CATEGORIES_LOADING = 'SET_BUSINESS_CATEGORIES_LOADING';

export const SET_SERVICES = 'SET_SERVICES';
export const SET_SERVICES_LOADING = 'SET_SERVICES_LOADING';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';

export const SET_SCHEDULES = 'SET_SCHEDULES';
export const SET_SCHEDULES_LOADING = 'SET_SCHEDULES_LOADING';

export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';
export const SET_AVAILABILITIES_LOADING = 'SET_AVAILABILITIES_LOADING';

export const SET_SERVICE_CATEGORIES = 'SET_SERVICE_CATEGORIES';
export const SET_SERVICE_CATEGORIES_LOADING = 'SET_SERVICE_CATEGORIES_LOADING';

export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_PRODUCT_CATEGORIES_LOADING = 'SET_PRODUCT_CATEGORIES_LOADING';

export const SET_ORG_DETAILS = 'SET_ORG_DETAILS';
export const SET_ORG_DETAILS_LOADING = 'SET_ORG_DETAILS_LOADING';

export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const SET_PRODUCT_DETAILS_LOADING = 'SET_PRODUCT_DETAILS_LOADING';

export const SET_SERVICE_DETAILS = 'SET_SERVICE_DETAILS';
export const SET_SERVICE_DETAILS_LOADING = 'SET_SERVICE_DETAILS_LOADING';

export const SET_SCHEDULE_DETAILS = 'SET_SCHEDULE_DETAILS';
export const SET_SCHEDULE_DETAILS_LOADING = 'SET_SCHEDULE_DETAILS_LOADING';

export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST';
export const SET_PROVIDER_LIST_LOADING = 'SET_PROVIDER_LIST_LOADING';

export const SET_BOOKING_DETAILS = 'SET_BOOKING_DETAILS';
export const SET_BOOKING_DETAILS_LOADING = 'SET_BOOKING_DETAILS_LOADING';

export const SET_SURVEY_DATA = 'SET_SURVEY_DATA';
export const SET_SURVEY_DATA_LOADING = 'SET_SURVEY_DATA_LOADING';

export const SET_SURVEY_ANSWER = 'SET_SURVEY_ANSWER';
export const SET_FEEDBACK_ANSWER = 'SET_FEEDBACK_ANSWER';

export const SET_CUSTOMER_SERVICE_STATUS = 'SET_CUSTOMER_SERVICE_STATUS';

export const SET_BOOKING_LIST = 'SET_BOOKING_LIST';
export const SET_BOOKING_LIST_LAST_PAGE = 'SET_BOOKING_LIST_LAST_PAGE';
export const SET_BOOKING_LIST_TOTAL_COUNT = 'SET_BOOKING_LIST_TOTAL_COUNT';
export const SET_BOOKING_LIST_LOADING = 'SET_BOOKING_LIST_LOADING';

export const SET_TWILIO_ACCESS_DATA = 'SET_TWILIO_ACCESS_DATA';
export const SET_TWILIO_ACCESS_DATA_LOADING = 'SET_TWILIO_ACCESS_DATA_LOADING';

export const SET_RATING_REVIEWS_LOADING = "SET_RATING_REVIEWS_LOADING";
export const SET_RATING_REVIEWS = "SET_RATING_REVIEWS";




const setAction = (type, payload) => ({
    type,
    payload,
});
const setActionAvailabilityLoading = (scheduleId, payload) => {

    let action = {}
    action[scheduleId] = payload;
    return setAction(SET_AVAILABILITIES_LOADING, action);
};

export const resetBookingList = () => dispatch => {
    dispatch(setAction(SET_BOOKING_LIST, []));
    dispatch(setAction(SET_BOOKING_LIST_LAST_PAGE, -1));
    dispatch(setAction(SET_BOOKING_LIST_TOTAL_COUNT, 0));
}

export const fetchOrganizations = () => async (dispatch) => {
    try {
        dispatch(setAction(SET_ORGANIZATIONS_LOADING, true));
        const response = await axios.get(`${URL.ORGANIZATIONS}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_ORGANIZATIONS, response.data.objects || []));
        }
        dispatch(setAction(SET_ORGANIZATIONS_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_ORGANIZATIONS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchBusinessCategories = () => async (dispatch) => {
    try {
        dispatch(setAction(SET_BUSINESS_CATEGORIES_LOADING, true));
        const response = await axios.get(`${URL.BUSINESS_CATEGORIES}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_BUSINESS_CATEGORIES, response.data.objects || []));
        }
        dispatch(setAction(SET_BUSINESS_CATEGORIES_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_BUSINESS_CATEGORIES_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchServicesbyOrgId = (orgId, queryParams=false ) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SERVICES_LOADING, true));
        const response = await axios.get(`${URL.SERVICES_BY_ORG_ID}/${orgId}${queryParams?queryParams:''}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SERVICES, response.data.objects || []));
        } else {
            dispatch(setAction(SET_SERVICES, []));
        }
        dispatch(setAction(SET_SERVICES_LOADING, false));

    } catch (e) {
        dispatch(setAction(SET_SERVICES_LOADING, false));
        dispatch(setAction(SET_SERVICES, []));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchServiceCategoriesByOrgId = (orgId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SERVICE_CATEGORIES_LOADING, true));
        const response = await axios.get(`${URL.SERVICE_CATEGORIES}/org/${orgId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SERVICE_CATEGORIES, response.data.objects || []));
        }
        dispatch(setAction(SET_SERVICE_CATEGORIES_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SERVICE_CATEGORIES_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchProductsByOrgId = (orgId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_PRODUCTS_LOADING, true));
        const response = await axios.get(`${URL.PRODUCTS}-by-org/${orgId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_PRODUCTS, response.data.objects || []));
        }
        dispatch(setAction(SET_PRODUCTS_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_PRODUCTS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchProductCategoriesByOrgId = (orgId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_PRODUCT_CATEGORIES_LOADING, true));
        const response = await axios.get(`${URL.PRODUCT_CATEGORIES}-by-org/${orgId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_PRODUCT_CATEGORIES, response.data.objects || []));
        }
        dispatch(setAction(SET_PRODUCT_CATEGORIES_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_PRODUCT_CATEGORIES_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchSchedulesByServiceId = (serviceId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SCHEDULES_LOADING, true));
        const response = await axios.get(`${URL.SCHEDULE_BY_SERVICE_ID}/${serviceId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SCHEDULES, response.data.objects || []));
        }
        dispatch(setAction(SET_SCHEDULES_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SCHEDULES_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchAvailabilitiesByScheduleId = (scheduleId) => async (dispatch) => {
    try {
        dispatch(setActionAvailabilityLoading(scheduleId, true));
        const response = await axios.get(`${URL.AVAILABILITIES_BY_SCHEDULE_ID}/${scheduleId}`);
        if (response?.data?.success) {
            let action = {};
            action[scheduleId] = response.data.objects || [];
            dispatch(setAction(SET_AVAILABILITIES, action));
        }
        dispatch(setActionAvailabilityLoading(scheduleId, false));
    } catch (e) {
        dispatch(setActionAvailabilityLoading(scheduleId, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchOrganizationById = orgId => async (dispatch) => {
    try {
        dispatch(setAction(SET_ORG_DETAILS_LOADING, true));
        const response = await axios.get(`${URL.ORGANIZATIONS}/${orgId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_ORG_DETAILS, response.data.object || {}));
        } else {
            dispatch(setAction(SET_ORG_DETAILS, null));
            dispatch(handleError({ message: STRINGS.ORG_NOT_FOUND }));
        }
        dispatch(setAction(SET_ORG_DETAILS_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_ORG_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchProductById = (id) => async (dispatch) => {
    try {
        dispatch(setAction(SET_PRODUCT_DETAILS_LOADING, true));
        const response = await axios.get(`${URL.PRODUCTS}/${id}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_PRODUCT_DETAILS, response.data.object || {}));
        }
        dispatch(setAction(SET_PRODUCT_DETAILS_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_PRODUCT_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchServiceById = (id) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SERVICE_DETAILS_LOADING, true));
        const response = await axios.get(`${URL.SERVICES}/${id}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SERVICE_DETAILS, response.data.object || {}));
        }
        dispatch(setAction(SET_SERVICE_DETAILS_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SERVICE_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchScheduleById = (id) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SCHEDULE_DETAILS_LOADING, true));
        const response = await axios.get(`${URL.SCHEDULES}/${id}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SCHEDULE_DETAILS, response.data.object || {}));
        }
        dispatch(setAction(SET_SCHEDULE_DETAILS_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SCHEDULE_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchProvidersByServiceId = (id) => async (dispatch) => {
    try {
        dispatch(setAction(SET_PROVIDER_LIST_LOADING, true));
        const response = await axios.get(`${URL.PROVIDERS_BY_SERVICE_ID}/${id}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_PROVIDER_LIST, response.data.objects || []));
        }
        dispatch(setAction(SET_PROVIDER_LIST_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_PROVIDER_LIST_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const createBooking = (payload) => async (dispatch, getState) => {
    try {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, true));
        const response = await axios.post(`${URL.EVENTS}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_BOOKING_DETAILS, response.data.object));
        } else {
            dispatch(setAction(SET_BOOKING_DETAILS, null));
            if (
                response?.data?.message.includes("You already have another appointment") ||
                response?.data?.message.includes("You already made appointment")
            ) {
                const state = getState()
                const data = { ...state.common.showUserFormData, showAlertForSlotTimeConflict: true, alertConflictMessage: response?.data?.message };
                dispatch(setShowUserForm(data));
            } else {
                dispatch(handleError(response?.data, "warning"));
            }
        }
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}
export const prepareWaitlistBooking = (payload) => async (dispatch) => {
    try {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, true));
        const response = await axios.post(`${URL.WAITLIST}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_BOOKING_DETAILS, response.data.object));
        } else {
            dispatch(setAction(SET_BOOKING_DETAILS, null));
            dispatch(handleError(response?.data, "warning"));
        }
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}
export const createWaitlistBooking = (payload) => async (dispatch) => {
    try {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, true));
        const response = await axios.post(`${URL.WAITLIST_CONFIRM}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_BOOKING_DETAILS, response.data.object));
        } else {
            dispatch(setAction(SET_BOOKING_DETAILS, null));
            dispatch(handleError(response?.data, "warning"));
        }
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const resheduleBooking = (payload) => async (dispatch) => {
    try {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, true));
        let response = await axios.put(`${URL.EVENTS}/reschedule`, payload);
        if (response?.data?.success) {
            response = await axios.get(`${URL.EVENTS}/${payload.eventId}`);
            dispatch(setAction(SET_BOOKING_DETAILS, response?.data?.object));
        } else {
            dispatch(handleError(response?.data));
            //dispatch(setAction(SET_BOOKING_DETAILS, null));
        }
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const cancelBooking = (bookingId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, true));
        let response = await axios.delete(`${URL.EVENTS}/cancel/${bookingId}`);
        if (response?.data?.success) {
            response = await axios.get(`${URL.EVENTS}/${bookingId}`);
            dispatch(setAction(SET_BOOKING_DETAILS, response?.data?.object));
        } else {
            dispatch(handleError(response?.data));
            //dispatch(setAction(SET_BOOKING_DETAILS, null));
        }
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchBookingDetails = (bookingId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, true));
        const response = await axios.get(`${URL.EVENTS}/${bookingId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_BOOKING_DETAILS, response.data.object));
        } else {
            dispatch(setAction(SET_BOOKING_DETAILS, null));
        }
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        return response?.data?.object;
    } catch (e) {
        dispatch(setAction(SET_BOOKING_DETAILS_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchCustomerServiceStatus = (bookingId) => async (dispatch) => {
    try {
        const response = await axios.get(`${URL.CUSTOMER_SERVICE_STATUS}/${bookingId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_CUSTOMER_SERVICE_STATUS, response.data.object));
        } else {
            dispatch(setAction(SET_CUSTOMER_SERVICE_STATUS, null));
        }
        return response?.data?.object;
    } catch (e) {
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchSurveyById = (id) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SURVEY_DATA_LOADING, true));
        const response = await axios.get(`${URL.SURVEYS}/${id}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SURVEY_DATA, response?.data?.object));
        }
        dispatch(setAction(SET_SURVEY_DATA_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SURVEY_DATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchSurveyAnswerByEventId = (eventId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SURVEY_DATA_LOADING, true));
        const response = await axios.get(`${URL.SURVEY_ANSWERS}/event/${eventId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_SURVEY_ANSWER, response?.data?.objects || []));
        }
        dispatch(setAction(SET_SURVEY_DATA_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SURVEY_DATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchFeedbackAnswerByEventId = (eventId) => async (dispatch) => {
    try {
        dispatch(setAction(SET_SURVEY_DATA_LOADING, true));
        const response = await axios.get(`${URL.FEEDBACK_ANSWERS}-by-eventId/${eventId}`);
        if (response?.data?.success) {
            dispatch(setAction(SET_FEEDBACK_ANSWER, response?.data?.object || {}));
        }
        dispatch(setAction(SET_SURVEY_DATA_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_SURVEY_DATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const updateSurveyAnswer = (payload) => async (dispatch) => {
    try {
        const method = payload?.id ? "put" : "post";
        const response = await axios[method](`${URL.SURVEY_ANSWERS}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_SURVEY_ANSWER, [response?.data?.object] || []));
            return response?.data?.object
        } else {
            dispatch(handleError(response?.data, "warning"));
        }
    } catch (e) {
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const updateFeedbackAnswer = (payload) => async (dispatch) => {
    try {
        const method = payload?.id ? "put" : "post";
        const response = await axios[method](`${URL.FEEDBACK_ANSWERS}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_FEEDBACK_ANSWER, response?.data?.object || null));
            return response?.data?.object
        } else {
            dispatch(handleError(response?.data, "warning"));
        }
    } catch (e) {
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchBookingsByCustomerId = (customerId, paginationSize = false, filter = false) => async (dispatch, getState) => {
    try {
        dispatch(setAction(SET_BOOKING_LIST_LOADING, true));
        let state = getState();
        let loadedCount = state.api?.bookingList?.length;
        let totalCount = state.api?.bookingListTotalCount;
        let lastLoadedPage = state.api?.bookingListLastLoadedPage;
        let url = `${URL.FIND_EVENTS_BY_CUSTOMER_ID}/${customerId}` + (paginationSize && loadedCount <= totalCount ? `?pageNumber=${lastLoadedPage + 1}&pageSize=${paginationSize}` : '');
        url = filter ? `${url}&filter=${filter}` : url;
        const response = await axios.get(url);
        if (response?.data?.success) {
            dispatch(setAction(SET_BOOKING_LIST_TOTAL_COUNT, response?.data?.totalNumberOfItems ?? 0));
            if (paginationSize) {
                let bookings = response?.data?.objects
                let pageNumber = response?.data?.pageable?.pageNumber;
                let bookingList = state.api.bookingList;
                dispatch(setAction(SET_BOOKING_LIST_LAST_PAGE, pageNumber));
                if (pageNumber > lastLoadedPage) {
                    let updatedBookingList = [...bookingList, ...bookings];
                    updatedBookingList = updatedBookingList.reduce((updatedList, item) => updatedList.find(i => i.id == item.id) ? updatedList : [...updatedList, item], []);
                    dispatch(setAction(SET_BOOKING_LIST, updatedBookingList));
                } else {
                    dispatch(setAction(SET_BOOKING_LIST_TOTAL_COUNT, 0));
                }
            } else {
                dispatch(setAction(SET_BOOKING_LIST, response?.data?.objects || []));
            }
        }
        dispatch(setAction(SET_BOOKING_LIST_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_BOOKING_LIST_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const fetchTwilioAccessData = (payload) => async (dispatch) => {
    try {
        dispatch(setAction(SET_TWILIO_ACCESS_DATA_LOADING, true));
        const response = await axios.post(`${URL.TWILIO_TOKEN}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_TWILIO_ACCESS_DATA, response?.data?.object || {}));
        }
        dispatch(setAction(SET_TWILIO_ACCESS_DATA_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_TWILIO_ACCESS_DATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}
export const fetchZoomAccessData = (payload) => async (dispatch) => {
    try {
        dispatch(setAction(SET_TWILIO_ACCESS_DATA_LOADING, true));
        const response = await axios.post(`${URL.ZOOM_TOKEN}`, payload);
        if (response?.data?.success) {
            dispatch(setAction(SET_TWILIO_ACCESS_DATA, response?.data?.object || {}));
        } else {
            dispatch(handleError(response?.data));
        }
        dispatch(setAction(SET_TWILIO_ACCESS_DATA_LOADING, false));
    } catch (e) {
        dispatch(setAction(SET_TWILIO_ACCESS_DATA_LOADING, false));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const updateBookingStatus = (status, bookingDetails) => async (dispatch) => {
    try {
        let payload = {
            eventId: bookingDetails?.id,
            status: status,
            timezoneId: bookingDetails?.timezoneId
        }
        const response = await axios.put(`${URL.CUSTOMERFLOW_STATUS}`, payload);
        if (response?.data?.success) {
            return response?.data?.object;
        } else {
            dispatch(handleError(response?.data, "warning"));
        }
    } catch (e) {
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}
export const customerCheckin = (eventId, customerId) => async (dispatch) => {
    try {
        let payload = { eventId, customerId };
        const response = await axios.post(`${URL.CUSTOMER_CHECKIN}`, payload);
        if (response?.data?.success) {
            return response?.data?.object;
        } else {
            //dispatch(handleError(response?.data, "warning"));
        }
    } catch (e) {
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}
export const updateProfile = (payload) => async (dispatch) => {

    try {
        const response = await axios.put(`${URL.PROFILE}`, payload);

        if (response?.data?.success) {
            dispatch(setAction(SET_USERDATA, response?.data?.object || null));
            dispatch(showAlert("success", "Profile update successful"));
            return response?.data?.object;
        }
        else {
            dispatch(handleError(response.data));


        }
    } catch (error) {
        console.log('Error: ', error);
        dispatch(handleError(error));
    }
};

export const fetchRatingReviewsDataByTypeId = (type, id, paginationSize = false, resetAndLoad = false) => async (dispatch, getState) => {
    try {
        dispatch(setAction(SET_RATING_REVIEWS_LOADING, { id: id, value: true }));
        if(resetAndLoad){
            dispatch(setAction(SET_RATING_REVIEWS, {serviceId:id,reviews:[]}));
        }
        let state = getState();
        let loadedCount = state.api?.ratingReviewsData[id]?.reviews?.length ?? 0;
        let totalCount = state.api?.ratingReviewsData[id]?.totalNumberOfItems ?? 0;
        let lastLoadedPage = state.api?.ratingReviewsData[id]?.lastLoadedPage ?? -1;
        let lastPageNo = state.api?.ratingReviewsData[id]?.lastPageNumber ?? 0;
        let url = `${URL.RATING_REVIEW}/${type}/${id}` + ((paginationSize) ? `?pageNumber=${lastLoadedPage < lastPageNo ? lastLoadedPage + 1 : lastLoadedPage}&pageSize=${paginationSize}` : '');
        const response = await axios.get(url);
        if (response?.data?.success) {
            let payload = response?.data?.object ?? {};
            if (paginationSize) {
                let reviewList = response?.data?.object?.reviews ?? [];
                let storedReviewsList = state.api?.ratingReviewsData[id]?.reviews ?? [];
                let pageNumber = response?.data?.pageable?.pageNumber;
                let pageSize = response?.data?.pageable?.pageSize;
                let totalNumberOfItems = response?.data?.totalNumberOfItems;
                let lastPageNumber = parseInt((totalNumberOfItems - 1) / pageSize);
                let lastLoadedPage = pageNumber <= lastPageNumber ? pageNumber : lastPageNumber;
                if (pageNumber <= lastPageNumber) {
                    let updatedReviewsList = [...storedReviewsList, ...reviewList];
                    updatedReviewsList = updatedReviewsList.reduce((updatedList, item) => updatedList.find(i => i.id == item.id) ? updatedList : [...updatedList, item], []);
                    payload.reviews = updatedReviewsList;
                }
                payload.totalNumberOfItems = totalNumberOfItems;
                payload.lastPageNumber = lastPageNumber;
                payload.lastLoadedPage = lastLoadedPage;
            }
            dispatch(setAction(SET_RATING_REVIEWS, payload || {}));
        } else {
            dispatch(setAction(SET_RATING_REVIEWS, {}));
            dispatch(handleError(response?.data));
        }
        dispatch(setAction(SET_RATING_REVIEWS_LOADING, { id: id, value: false }));
    } catch (e) {
        dispatch(setAction(SET_RATING_REVIEWS, {}));
        dispatch(setAction(SET_RATING_REVIEWS_LOADING, { id: id, value: false }));
        console.log("Error: ", e);
        dispatch(handleError(e));
    }
}

export const addRatingReview = (payload) => async (dispatch) => {
    try {
        const response = await axios.post(`${URL.RATING_REVIEW}`, payload);
        if (response?.data?.success) {
            dispatch(showAlert("success", "Review submitted! It will be visible in a few days."));
            if (payload?.serviceId)
                dispatch(fetchRatingReviewsDataByTypeId("service", payload?.serviceId));
            if (payload?.providerId)
                dispatch(fetchRatingReviewsDataByTypeId("provider", payload?.providerId));
            return response?.data?.object;
        }
        else {
            dispatch(handleError(response.data));
        }
    } catch (error) {
        console.log('Error: ', error);
        dispatch(handleError(error));
    }
};
export const deleteReview = (reviewData) => async (dispatch) => {
    try {
        const id = reviewData?.id;
        const response = await axios.delete(`${URL.RATING_REVIEW}/${id}`);
        if (response?.data?.success) {
            dispatch(showAlert("success", "Review has been deleted successfuly"));
            if (reviewData?.serviceId)
                dispatch(fetchRatingReviewsDataByTypeId("service", reviewData?.serviceId));
            if (reviewData?.providerId)
                dispatch(fetchRatingReviewsDataByTypeId("provider", reviewData?.providerId));
            return true;
        }
        else {
            dispatch(handleError(response.data));
        }
    } catch (error) {
        console.log('Error: ', error);
        dispatch(handleError(error));
    }
};
