import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Config } from '../../config/regions';
import { Box, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSurveyById } from '../../redux/actions/apiActions';
import SkeletonCard from '../Loaders/SkeletonCard';
import { Survey } from 'survey-react-ui';
import { StylesManager, Model } from "survey-core";
import { Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme } from '@mui/material/styles';
import "survey-core/defaultV2.min.css";
import "./surveyModal.css";
import { themes } from '../../config/themes';


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SurveyModal = ({
  show, onClose, title, eventId,
  surveyId = null,
  completeText = "Submit",
  onComplete = () => { },
  showCompletedPage = false,
  surveyAnswer = false,
  readonly = false
}) => {

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const themeMode = useSelector(state => state.theme.mode)
  const surveyData = useSelector(state => state.api.surveyData)
  const surveyDataLoading = useSelector(state => state.api.surveyDataLoading)
  const dispatch = useDispatch();

  const [surveyModel, setSurveyModel] = React.useState(false);


  const handleClose = () => {
    //setOpen(false);
    if (onClose) {
      onClose()
    }
  };

  React.useEffect(() => {
    setOpen(show);
    if (surveyId) {
      dispatch(fetchSurveyById(surveyId));
    }
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 500);
  }, [show])


  const onFinishSurvey = React.useCallback((sender) => {
    onComplete(sender)
  }, [onComplete]);


  React.useEffect(() => {
    //console.log("surveyData", surveyData)
    if (surveyData) {
      let surveyJson = surveyData.survey;
      let surveyModel_ = new Model(surveyJson);
      surveyModel_.showCompletedPage = showCompletedPage;
      surveyModel_.onComplete.add(onFinishSurvey);
      surveyModel_.completeText = completeText ?? "COMPLETE";
      if (surveyAnswer) {
        surveyModel_.data = surveyAnswer
      }
      if (readonly) {
        surveyModel_.mode = "display";
      }
      setSurveyModel(surveyModel_)
    }
  }, [surveyData, surveyAnswer ])

  const theme = React.useMemo(
    () => {
      let themeConfig = themes[themeMode];
      return extendTheme(themeConfig);
    },
    [themeMode],
  );

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{ sx: { backgroundColor: "background.default" } }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              {title ?? surveyData?.title}
            </Typography>
            {onClose ?
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              : null}
          </Toolbar>
        </AppBar>
        <CssVarsProvider theme={theme}>
          {isLoading || surveyDataLoading ?
            <Box
              bgcolor="background.default"
              sx={{ height: '100%', width: '100%', maxWidth: 'lg', mx: 'auto', py: 2 }}
            >
              {
                Array(3).fill().map(
                  (item, idx) => <SkeletonCard cardType="surveyForm" key={idx} />
                )
              }
            </Box>
            :
            <Box sx={{ height: '100%', width: '100%', maxWidth: 'lg', mx: 'auto' }}>
              {
                surveyModel ?
                  <Survey model={surveyModel} id={`surveyElement-${themeMode}`} />
                  : null
              }
            </Box>
          }
        </CssVarsProvider>
      </Dialog>
    </>
  );
}


export default SurveyModal;