import { useParams } from "react-router-dom";
import '../style.css';
import { Box, Button, Paper, Typography } from "@mui/material";
import PrimaryLayout from "../../../layout/Layout";
import NotFound from "../../CommonPage/NotFound";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

import { Config } from "../../../config/regions";
import Footer from "../Footer";
import content from "./SegmentPageContent";

const SegmentPage = ({ }) => {
    const params = useParams();
    const segment = params?.segment;


    const onClickGetStarted = () => {
        let onboardingUrl = Config.onboardingUrl + '/register';
        window.open(onboardingUrl);
    }

    return (
        <>
            <PrimaryLayout showQZLogo>
                {content[segment] ?
                    <>
                        <Paper elevation={0} className="texture-shattered"  >
                            <GridContainer justifyContent="center" style={{ minHeight: '95vh' }}>
                                <GridItem md={6} lg={5} xl={4}>
                                    <GridContainer justifyContent="center" alignItems="center" style={{ height: '100%', padding: '10px 5px' }}>
                                        <GridItem>
                                            <Box
                                                sx={{
                                                    p: 2,
                                                    animation: 'fadeIn 1s ease-in-out forwards', // Animation name, duration, and easing
                                                    '@keyframes fadeIn': {
                                                        from: { opacity: 0 },
                                                        to: { opacity: 1 },
                                                    },
                                                }}>
                                                <Typography variant="h5" sx={{ fontWeight: '400', mb:2 }}>{content[segment]?.title}</Typography>
                                                <Typography variant="body1">{content[segment]?.subTitle}</Typography>
                                                <Box>
                                                    <Button variant="contained" size="large" sx={{ mt: 5, borderRadius: 10 }} onClick={onClickGetStarted}>GET STARTED NOW</Button>
                                                </Box>
                                                <Typography variant="caption" sx={{ color: "text.secondary" }}>No credit card Required! Sign up for free</Typography>
                                            </Box>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem md={6} lg={5} xl={4}>
                                    <GridContainer justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                        <Box sx={{
                                            animation: 'fadeInFromRight 1s ease-out', // Adjust the duration and easing function as needed
                                            '@keyframes fadeInFromRight': {
                                                from: { opacity: 0, transform: 'translateX(100%)' },
                                                to: { opacity: 1, transform: 'translateX(0%)' },
                                            },
                                        }}>
                                            <img src={content[segment]?.titleImage} alt={`${segment} image`} className={""} />
                                        </Box>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </Paper >
                        {/* Footer */}
                        <Footer />
                    </>
                    :
                    <NotFound />
                }
            </PrimaryLayout>
        </>
    )
}

export default SegmentPage;
