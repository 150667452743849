import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { setShowAccountVerifyForm, setShowLoginForm, setShowSignUpForm, userSignup } from "../../redux/actions/authActions";
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, InputAdornment, Link, TextField, Typography } from "@mui/material";
import { Email, Lock, Person, Phone } from "@mui/icons-material";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";

const SignUpModal = () => {

    const showSignUpForm = useSelector(state => state.auth.showSignUpForm);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    const signUpFormSchema = Yup.object({
        givenName: Yup.string().required("First Name is required").min(3, "Atleast 3 characters required"),
        familyName: Yup.string(),
        telephone: Yup.string().required("Phone number is required").matches(phoneRegExp, 'Phone number is not valid'),
        email: Yup.string().email().required("Email is required"),
        password: Yup.string().required("Password is required")
            .matches(/^.*[a-z].*$/, 'Must contain atleast one lowercase alphabet')
            .matches(/^.*[A-Z].*$/, 'Must contain atleast one uppercase alphabet')
            .matches(/^.*[0-9].*$/, 'Must contain atleast one number')
            .matches(/^.*[!@#$%^&*_\-+=`~].*$/, 'Must contain a special character !@#$`...')
            .matches(/^.{8,}$/, 'Must have atleast 8 characters'),
        confirmPassword: Yup.string()
            .required('Please re-enter your password')
            .oneOf([Yup.ref('password'), null], "The passwords entered doesn't match"),
    });

    const { values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched } = useFormik({
        initialValues: {
            givenName: '',
            familyName: '',
            email: '',
            telephone: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false
        },
        validationSchema: signUpFormSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            const success = await dispatch(userSignup(values));
            if (success) {
                resetForm();
            }
            setIsLoading(false);
        }
    });

    const handleClose = () => {
        dispatch(setShowSignUpForm(false));
    }

    const onClickLogin = () => {
        dispatch(setShowLoginForm(true));
    }
    const onClickVerify = () => {
        let verifyData = {
            type: "verify-account",
            ...values
        }
        dispatch(setShowAccountVerifyForm(true, verifyData));
    }

    return (
        <>
            <Dialog onClose={handleClose} open={showSignUpForm} fullWidth maxWidth="xs" PaperProps={{ sx: { width: '100%', m: 0, borderRadius: 5, alignSelf: { xs: 'flex-end', md: 'center' } } }}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 24 }}>Sign Up</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mx: 'auto', minHeight: 75, display: 'flex' }}>
                        <TextField
                            label="First Name"
                            variant="standard"
                            fullWidth
                            name="givenName"
                            onChange={handleChange}
                            value={values.givenName}
                            helperText={touched?.givenName && errors?.givenName}
                            error={touched?.givenName && errors?.givenName ? true : false}
                        />
                        <TextField
                            label="Last Name"
                            variant="standard"
                            fullWidth
                            name="familyName"
                            onChange={handleChange}
                            value={values.familyName}
                            helperText={touched?.familyName && errors?.familyName}
                            error={touched?.familyName && errors?.familyName ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Person />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <PhoneNumberInput
                            label="Phone Number"
                            variant="standard"
                            fullWidth
                            name="telephone"
                            onChange={telephone => setFieldValue('telephone', telephone)}
                            value={values.telephone}
                            helperText={touched?.telephone && errors?.telephone}
                            error={touched?.telephone && errors?.telephone ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Phone />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <TextField
                            label="Email"
                            variant="standard"
                            fullWidth
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched?.email && errors?.email}
                            error={touched?.email && errors?.email ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete='off'
                        />
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <TextField
                            label="Password"
                            variant="standard"
                            fullWidth
                            name="password"
                            type="password"
                            onChange={handleChange}
                            value={values.password}
                            helperText={touched?.password && errors?.password}
                            error={touched?.password && errors?.password ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete='new-password'
                        />
                    </Box>
                    <Box sx={{ mx: 'auto', minHeight: 75 }}>
                        <TextField
                            label="Confirm Password"
                            variant="standard"
                            fullWidth
                            name="confirmPassword"
                            type="password"
                            onChange={handleChange}
                            value={values.confirmPassword}
                            helperText={touched?.confirmPassword && errors?.confirmPassword}
                            error={touched?.confirmPassword && errors?.confirmPassword ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            autoComplete='new-password'
                        />
                    </Box>
                    <Box sx={{ mx: 'auto' }}>
                        <FormControlLabel
                            control={<Checkbox checked={values.acceptTerms} />}
                            name="acceptTerms"
                            label={<>I agree to the terms and conditions</>}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ mt: 2, mx: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading || !values.acceptTerms}
                            endIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                            sx={{ borderRadius: 2, minWidth: 120 }}
                        >
                            Sign up
                        </Button>
                    </Box>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link href="#" variant="body2" onClick={onClickVerify}>
                                Verify your account!
                            </Link>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link href="#" variant="body2" onClick={onClickLogin}>
                                Already have an account? Log in
                            </Link>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SignUpModal;