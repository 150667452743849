import { useDispatch, useSelector } from "react-redux";
import PrimaryLayout from "../../layout/Layout";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrganizationById } from "../../redux/actions/apiActions";
import ServiceList from "../ServiceList/ServiceList";
import ProductList from "../ProductList/ProductList";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Box, Card, CardActions, CardHeader, Skeleton } from "@mui/material";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import OrganizationCard from "../OrganizationList/OrganizationCard";
import SkeletonCard from "../../components/Loaders/SkeletonCard";

const Organization = () => {

    const dispatch = useDispatch();
    const orgDetails = useSelector(state => state.api.orgDetails);
    const orgDetailsLoading = useSelector(state => state.api.orgDetailsLoading);

    const { height, width } = useWindowDimensions();
    const { orgRef } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (orgRef && orgDetails?.orgRef !== orgRef) {
            dispatch(fetchOrganizationById(orgRef));
        }
    }, [orgRef])

    useEffect(() => {
        if (orgDetails === null ) {
            navigate('/p/404')
        }
    }, [orgDetails])

    return (
        <PrimaryLayout title={orgDetails?.name}>
            {orgDetailsLoading ?
                <Box className="p-2 min-h-full">
                    <Box spacing={1} className="py-3 w-100 flex flex-row justify-center">
                        <Box spacing={1} className="py-2 flex flex-row overflow-x-auto overflow-y-hidden scrollbar-none mx-auto w-fit">
                            {Array(parseInt(width / 150)).fill()
                                .map((item, idx) => <SkeletonCard key={idx} cardType="categories" />)
                            }
                        </Box>
                    </Box>
                    <GridContainer spacing={2} justifyContent="center">
                        {Array(parseInt(width / 300) * parseInt(height / 130)).fill().map((item, idx) =>
                            <GridItem key={idx}>
                                <SkeletonCard cardType="service" />
                            </GridItem>)
                        }
                    </GridContainer>
                </Box>
                :
                orgDetails && orgDetails?.preferences?.enableProducts ?
                    <ProductList />
                    :
                    <ServiceList />
            }
        </PrimaryLayout >
    )
}
export default Organization;